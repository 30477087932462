<script setup>
import { useRouter } from 'vue-router';

import AppButton from '@/components/Button/Button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';
import { watch } from 'vue';

const props = defineProps({
    resource: Object,
});

const toast = useToast();
const form = useAjaxForm({
    recipient_id: props.resource.data?.id,
});

form.onSuccess((response) => {
    props.resource.data.connection_received_from_me = response.data.data;
    toast.success('Connection request sent');
    router.back();
});

const router = useRouter();

watch(() => props.resource.filled(), (filled) => {
    if (filled) {
        form.recipient_id = props.resource.data.id;
    }
});
</script>

<template>
    <div>
        <Modal :open="resource.filled()" @update:open="router.back()">
            <ModalTitle show>
                <h1 class="text-xl font-semibold">Request to Connect with {{ resource.data.name }}</h1>
            </ModalTitle>

            <div class="mt-2">
                Connecting with {{ resource.data.name }} will allow you to exchange direct messages with them.
                They'll need to approve this request before you can connect.
            </div>

            <div class="flex gap-2 mt-4">
                <div class="w-1/2">
                    <AppButton color="gray-border" @button:click="router.back()" full>
                        Cancel
                    </AppButton>
                </div>
                <div class="w-1/2">
                    <AppButton color="success" full :loading="form.processing" @button:click="form.post('/api/user-connections')">
                        Connect
                    </AppButton>
                </div>
            </div>
        </Modal>
    </div>
</template>
