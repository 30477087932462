<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { collection } from '@/composables/resource';

import PostList from '@/components/Post/PostList.vue';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import EmptyList from '@/components/Renderer/EmptyList.vue';

const props = defineProps({
    pageData: {
        type: Object,
        required: false,
    }
});

const route = useRoute();

const informationHubPost = collection({
    url: '/api/search',
    filter: {
        query: route.query.query,
        type: 'information_hub',
    }
});

onMounted(() => {
    if (!informationHubPost.fetched) {
        informationHubPost.fetch();
    }
});
</script>

<template>
    <div class="px-4">
        <EmptyList :collection="informationHubPost" />
        <app-list v-if="informationHubPost.data.length > 0">
            <app-list-item align-center bordered v-for="post in informationHubPost.data" :key="post.id"
                target-as="router-link" :target-bind="{
                    to: {
                        name: 'information-hub.article',
                        params: {
                            category: post.category.slug, // or post.category_id
                            id: post.id
                        }
                    }
                }">
                <app-list-content :headline="post.title" headline-max-lines="3"  />
                <app-list-trailing>
                    <icon-chevron-right class="size-5" />
                </app-list-trailing>
            </app-list-item>
        </app-list>
    </div>
</template>
