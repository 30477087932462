
<script setup>
import { computed, inject } from 'vue';
import { inputProps, inputEmits } from './utils';

import AttachmentMedia from './Views/AttachmentMedia.vue';
import AttachmentDocument from './Views/AttachmentDocument.vue';
import AttachmentAudio from './Views/AttachmentAudio.vue';

const emit = defineEmits(inputEmits());

const props = defineProps(inputProps());

const form = inject('form');
form.set(props.name, props.value);

const attachments = computed(() => {
    return form.get(props.name);
});

const deleteAttachment = (attachment) => {
    const index = form.get(props.name).indexOf(attachment);

    form.clear(`${props.name}.${index}`);
};

const mediaAttachments = computed(() => {
    if (!attachments.value) {
        return [];
    }

    return attachments.value.filter((attachment) => [1, 2].includes(attachment.type));
});

const otherAttachments = computed(() => {
    if (!attachments.value) {
        return [];
    }

    return attachments.value.filter((attachment) => ![1, 2].includes(attachment.type));
});
</script>

<template>
    <div class="flex flex-col gap-2 w-full" v-if="attachments?.length > 0">
        <div class="flex gap-2 max-w-full overflow-x-auto">
            <div class="relative size-20 shrink-0" v-for="attachment in mediaAttachments" :key="attachment.url">
                <AttachmentMedia :key="attachment.url" :attachment="attachment" />

                <div class="absolute top-0 right-0">
                    <app-icon-button
                        @click="deleteAttachment(attachment)"
                        icon="cancel"
                        xs
                        color="black"
                        button-style="filled"
                        aria-label="Delete" />
                </div>
            </div>
        </div>

        <div class="flex gap-2 max-w-full overflow-x-auto" v-if="otherAttachments.length > 0">
            <div class="shrink-0 relative" v-for="attachment in otherAttachments" :key="attachment.url">
                <AttachmentDocument v-if="attachment.type == 3" :attachment="attachment" />
                <AttachmentAudio v-if="attachment.type == 4" :attachment="attachment" />

                <div class="absolute top-0 right-0">
                    <app-icon-button
                        @click="deleteAttachment(attachment)"
                        icon="cancel"
                        xs
                        color="black"
                        button-style="filled"
                        aria-label="Delete" />
                </div>
            </div>
        </div>
    </div>
</template>
