<script setup>
const emit = defineEmits(['update:open']);
const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
});


</script>

<template>
    <app-dialog
        dark-mode
        :open="open"
        @update:open="emit('update:open', $event)"
        full-screen
        max-width
        max-height
        non-dismissible>
        <slot />
    </app-dialog>
</template>
