<script setup>
import { computed } from 'vue';
import { useAjaxForm } from '@/utils/form';
import { useEmitter } from '@/plugins/emitter';

const props = defineProps({
    conversation: {
        type: Object,
        required: false,
    },
});
const emit = defineEmits(['declined', 'accepted', 'unblocked']);
const emitter = useEmitter();
const acceptUserForm = useAjaxForm();
const declineUserForm = useAjaxForm();
const unblockUserForm = useAjaxForm();

const canChat = computed(() => {
    if (props.conversation.with_me) {
        return true;
    }

    if (props.conversation.other_members[0].user.block_received_from_me) {
        return false;
    }

    if (props.conversation.other_members[0].user.block_given_to_me) {
        return false;
    }

    return props.conversation.current_member.status === 'accepted';
});

const acceptUser = () => {
    acceptUserForm.post(`/api/conversations/${props.conversation.id}/accept`, {
        onSuccess: () => {
            props.conversation.current_member.status = 'accepted';
            emitter.emit('.conversation:updated', props.conversation);
            emit('accepted');
        },
    });
};

const declineUser = () => {
    declineUserForm.post(`/api/conversations/${props.conversation.id}/decline`, {
        onSuccess: () => {
            props.conversation.current_member.status = 'declined';
            emitter.emit('.conversation:updated', props.conversation);
            emit('declined');
        },
    });
};

const unblockUser = () => {
    unblockUserForm.delete(`/api/blocked-users/${props.conversation.other_members[0].user.id}`, {
        onSuccess: () => {
            emitter.emit('.conversation:updated', props.conversation);
            emit('unblocked');
        },
    });
};
</script>
<template>
    <div>
        <slot v-if="canChat"></slot>
        <div v-else class="max-w-[500px] mx-auto px-6">
            <template v-if="conversation.other_members[0].user.block_received_from_me">
                <app-button :loading="unblockUserForm.processing" @click="unblockUser" color="danger-border" type="button" full>
                    <span>Unblock</span>
                </app-button>
            </template>
            <template v-else-if="conversation.other_members[0].user.block_given_to_me">
                <div class="text-center text-sm select-none md:max-w-[90%] mx-auto">
                    You don't have permission to chat with this person.
                </div>
            </template>
            <template v-else-if="conversation.current_member.status !== 'accepted'">
                <div class="text-center text-sm select-none md:max-w-[90%] mx-auto">
                    This person is outside your network. Accept their chat request to start messaging.
                </div>

                <div class="flex justify-between gap-4 mt-6">
                    <div class="w-full">
                        <app-button :loading="declineUserForm.processing" @click="declineUser" color="danger-border" type="button" full>
                            <span>Decline</span>
                        </app-button>
                    </div>
                    <div class="w-full">
                        <app-button :loading="acceptUserForm.processing" @click="acceptUser" color="primary-border" type="button" full>
                            <span>Accept</span>
                        </app-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
