import { createRouter as createVueRouter, useRouter } from 'vue-router';

import routes from '@/routes/index';
import { useStore } from '@/store';

import { useNotificationPermission } from '@/composables/permissions/notification';
import { reactive } from 'vue';
import { useToast } from '@/plugins/toast';
import { useEmitter } from '@/plugins/emitter';

export function createRouter(history) {
    const router = createVueRouter({
        history,
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { top: 0 };
            }
        },
    });

    const callMiddlewareStack = async (context, middlewares, index = 0) => {
        const middleware = middlewares[index];

        const result = await middleware(context);

        if ((result === true || typeof result === 'undefined') && middlewares[index + 1]) {
            return callMiddlewareStack(context, middlewares, index + 1);
        }

        return result;
    }

    let prev = null;
    let current = null;
    let direction = null;

    const navigation = reactive({
        get direction() {
            return direction;
        }
    })

    router.beforeEach((to, from) => {
        prev = current;
        if (current !== null) {
            if (prev > router.options.history.state.position) {
                current -= 1;
                direction = 'back';
            } else {
                current += 1;
                direction = 'forward';
            }
        } else {
            current = router.options.history.state.position;
            prev = Math.max(current - 1, 0);
        }
    });

    router.beforeEach(async (to, from) => {
        const context = {
            to,
            from,
            router: useRouter(),
            store: useStore(),
            toast: useToast(),
            notification: useNotificationPermission(),
            emitter: useEmitter(),
        };

        if (to.meta.middlewares && to.meta.middlewares.length > 0) {
            const result = await callMiddlewareStack(context, to.meta.middlewares);

            return result;
        }
    });

    return { router, navigation };
}
