<script setup>
import { ref } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

import { useJoinedChannels } from '@/composables/channel';
import { useToast } from '@/plugins/toast';

import Form from '@/components/Channel/Form.vue';

const router = useRouter();
const formComponent = ref();
const joinedChannels = useJoinedChannels();
const open = ref(true);
const toast = useToast();

const submit = () => {
    formComponent.value.form.post('/api/channels', { onSuccess: () => {
        joinedChannels.fetch();
        router.back();
        toast.success('Your channel has been created and is now pending review by the Corperland team.');
    } });
}

onBeforeRouteLeave(() => {
    open.value = false;

    // @TODO: implement transition
    return true;
});
</script>

<template>
    <app-dialog
        :open="open"
        @update:open="$router.back()"
        :close-on-back-navigation="false"
        full-screen
        force-full-screen
        basic-width
    >
        <head-title value="Create Channel" />
        <app-dialog-header>
            <app-icon-button icon="arrow-back" @click="$router.back()" />
            <app-dialog-title>
                Create Channel
            </app-dialog-title>
            <div class="grow"></div>
            <app-text-button v-if="formComponent" @click="submit" :disabled="formComponent.canSubmit">Submit</app-text-button>
        </app-dialog-header>

        <app-dialog-body>
            <Form ref="formComponent" @submit.prevent="submit" />
        </app-dialog-body>
    </app-dialog>
</template>
