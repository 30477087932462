import Hammer from 'hammerjs';

export const hammer = {
    mounted(el, binding) {
        const value = binding.value;
        let event = binding.arg;

        const hammer = new Hammer(el);

        if (event === 'pan-v') {
            hammer.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL });
            event = 'pan';
        }

        hammer.on(event, value);
    },
};
