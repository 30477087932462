<script setup>
import { computed } from "vue";
import axios from "@/axios";
import { useNotificationPermission } from "@/composables/permissions/notification";
import { useToast } from "@/plugins/toast";
import { usePwaInstall } from "@/plugins/pwa-install";

import AppButton from "@/components/Button/Button.vue";
import IconBell from "@/components/Icons/BellOffOutline.vue";
import CancelLight from "@/components/Icons/CancelLight.vue";
import Content from "@/views/_partials/Content.vue";
import Header from "@/views/_partials/header/Header.vue";
import HeaderTitle from "@/views/_partials/header/HeaderTitle.vue";
import HeaderMenu from "@/views/_partials/header/HeaderMenu.vue";
import Switch from "@/components/Form/v1/Switch.vue";

const pwa = usePwaInstall();
const notification = useNotificationPermission();
const toast = useToast();
const helpText = computed(() => {
    if (pwa.isUnderStandaloneMode) {
        return "Please enable it from your browser permission settings.";
    }

    return "Please enable it from your device's app settings then close corperland app and re-open it.";
});
const update = (key, value) => {
    axios
        .post("/api/settings/notification", {
            key,
            value,
        })
        .catch((err) => {
            toast.error("Error updating notification settings");
        });
};

const actions = computed(() => {
    return [
        {
            label: "Reset Device Notifications",
            icon: CancelLight,
            onSelect: reset,
            show: notification.permission === "granted",
        },
    ];
});

const reset = () => {
    notification.refreshToken(true, true).then(() => {
        toast.success("Device notifications have been reset.");
    });
};

</script>

<template>
    <Content class="bg-white text-white-foreground">
        <Header with-back-button>
            <HeaderTitle> Notification Settings </HeaderTitle>

            <HeaderMenu :items="actions" />
        </Header>

        <div
            class="bg-danger text-danger-foreground p-4 text-sm"
            v-if="notification.permission == 'denied'"
        >
            Push Notifications have been disabled for Corperland on this device.
            {{ helpText }}
        </div>

        <div
            class="p-4 flex flex-col items-center justify-center size-80 w-full h-full md:h-[600px]"
            v-if="
                notification.supported && notification.permission == 'default'
            "
        >
            <div class="opacity-70">
                <IconBell class="size-24" />
            </div>
            <div class="text-center mt-4">
                <p>Notifications not enabled on this Device</p>
                <p>
                    Please enable notifications to receive notifications from
                    your friends and channels
                </p>
            </div>
            <div class="mt-6">
                <AppButton
                    color="primary"
                    rounded-full
                    @click="notification.enable()"
                >
                    Enable
                </AppButton>
            </div>
        </div>
        <div class="p-4" v-else-if="$store.state.notification_settings">
            <div class="font-bold opacity-70">Push Notifications</div>
            <Switch
                @update:checked="(e) => update('paused', e)"
                v-model="$store.state.notification_settings.paused"
                class="mt-5"
                id="notification.paused"
            >
                <div class="flex flex-col gap-2">
                    <div>Pause All</div>
                    <div class="text-sm opacity-70 leading-4">
                        You won't receive push notifications but you will be
                        able to see them in corperland.
                    </div>
                </div>
            </Switch>

            <Switch
                @update:checked="(e) => update('chats_enabled', e)"
                v-model="$store.state.notification_settings.chats_enabled"
                class="mt-8"
                id="notification.chats_enabled"
            >
                Chats
            </Switch>

            <button
                class="mt-8 w-full text-left"
                @click="
                    $router.push({name: 'settings.notifications.channels'})
                "
            >
                Channels
            </button>
        </div>
    </Content>
</template>
