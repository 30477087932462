import AppLayout from './Layout.vue';
import AppLayoutBody from './Body.vue';
import AppLayoutFooter from './Footer.vue';
import AppLayoutHeader from './Header.vue';
import AppTopNav from './TopNav.vue';
import AppTopNavBack from './TopNavBack.vue';
import AppTopNavTitle from './TopNavTitle.vue';
import AppTopNavSkeleton from './TopNavSkeleton.vue';
import AppLayoutSkeleton from './Skeleton.vue';

export default {
    AppLayout,
    AppLayoutBody,
    AppLayoutFooter,
    AppLayoutHeader,
    AppTopNav,
    AppTopNavBack,
    AppTopNavTitle,
    AppTopNavSkeleton,
    AppLayoutSkeleton,
};
