
export const inputProps = (merge = {}) => {
    return {
        id: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
        value: {
            required: false,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        ...merge,
    }
}

export const inputEmits = (merge = []) => {
    return ['update:value', ...merge];
}
