<script setup>
import { useRouter } from 'vue-router';

import AppButton from '@/components/Button/Button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';

const props = defineProps({
    resource: Object,
});

const toast = useToast();
const form = useAjaxForm();

form.onSuccess((response) => {
    props.resource.data.connection_received_from_me = null;
    props.resource.data.connection_sent_to_me = null;
    router.back();
    toast.success(`You are no longer connected with ${props.resource.data.name}`);
});

const router = useRouter();
</script>

<template>
    <div>
        <Modal :open="resource.filled()" @update:open="router.back()">
            <ModalTitle show>
                <h1 class="text-xl font-semibold">Remove {{ resource.data.name }} as a Connection</h1>
            </ModalTitle>

            <div class="mt-2">
                You will no longer be able to exchange direct messages with {{ resource.data.name }}.
            </div>

            <div class="flex gap-2 mt-4">
                <div class="w-1/2">
                    <AppButton color="gray-border" @button:click="router.back()" full>
                        Cancel
                    </AppButton>
                </div>
                <div class="w-1/2">
                    <AppButton color="danger" full :loading="form.processing" @button:click="form.delete(`/api/user-connections/${resource.data.connection_received_from_me.id || resource.data.connection_sent_to_me.id}`)">
                        Disconnect
                    </AppButton>
                </div>
            </div>
        </Modal>
    </div>
</template>
