import '../css/font.css';
import '../css/app.css';

import components from '@/components.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import weekday from 'dayjs/plugin/weekday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

import.meta.glob([
    '../images/static/**',
  ]);

import { createSSRApp, createApp as vueCreateApp } from 'vue';

import { eventTracker } from '@/directives/te';
import { delayedClick } from '@/directives/click';
import { vFixed } from '@/directives/fixed';
import Config from '@/plugins/config';
import { createEmitter } from '@/plugins/emitter';
import { createToast } from '@/plugins/toast';
import { createStore } from '@/store';
import { createRouter } from '@/router';
import { createSettings } from '@/settings';
import { createCustomAlert } from '@/plugins/alert';
import Notifications from '@/plugins/notifications';
import PwaInstall from '@/plugins/pwa-install';

import Base from '@/views/_base/Base.vue';

export function createApp({ context, history } = {}, client = false) {
    const pageData = {};
    pageData[context.url] = context.props ?? null;

    let app;

    if (client) {
        app = vueCreateApp(Base, {
            pageData,
        });
    } else {
        app = createSSRApp(Base, {
            pageData,
        });
    }

    const { router, navigation } = createRouter(history);
    app.use(router);

    app.provide('navigation', navigation);
    app.config.globalProperties.$navigation = navigation;

    const store = createStore();
    app.provide('store', store);
    app.config.globalProperties.$store = store;

    const emitter = createEmitter();
    app.use(emitter);

    const toast = createToast();
    app.use(toast);

    app.use(createCustomAlert());

    const settings = createSettings();
    app.use(settings);

    app.use(Notifications)
        .use(Config);

    app.use(PwaInstall);

    app.directive('track-event', eventTracker);
    app.directive('delayed-click', delayedClick);
    app.directive('fixed', vFixed);

    Object.keys(components).forEach(component => {
        app.component(component, components[component]);
    });

    if (typeof window !== 'undefined' && import.meta.env.DEV) {
        window.app = app;
    }

    store.commit('app', context.app);

    return {
        app,
        store,
        router,
    }
}
