<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import Gallery from '@/views/files/Gallery.vue';
import ThumbGalleryImage from '@/components/File/ThumbGalleryImage.vue';
import ThumbGalleryVideo from '@/components/File/ThumbGalleryVideo.vue';
import ThumbGalleryPdf from "./ThumbGalleryPdf.vue";

const props = defineProps({
    files: {
        required: true,
        type: Array,
    },
    item: {
        type: Object,
        required: false,
    },
    galleryId: {
        type: String,
        required: true,
    }
});

const route = useRoute();

const gallery = ref(null);

const galleryWidth = ref(null);
const customObservableElement = ref(null);
const customObservableElementContainer = ref(null);

let galleryObserver = null;

onMounted(() => {
    galleryObserver = new ResizeObserver(() => {
        galleryWidth.value = gallery.value.getBoundingClientRect()['width'];
    });

    galleryWidth.value = gallery.value.getBoundingClientRect()['width'];
    galleryObserver.observe(gallery.value);
});

const thumbnailHeight = computed(() => {
    return props.files.length > 2 ? 150 : 300;
});

const getMinHeight = computed(() => {
    if (props.files.length == 0) {
        return 0;
    }

    if (props.files.length >= 2) {
        return 300;
    }

    if (!galleryWidth.value) {
        return 0;
    }

    const thumbnail = props.files[0].thumbnails ? props.files[0].thumbnails.find((t) => t.height == 900) : null;

    const image =  thumbnail ? thumbnail : props.files[0];

    if (!image.height || !image.width) {
        return 150;
    }

    return Math.min((galleryWidth.value / image.width) * image.height, galleryWidth.value);
});

const goToFile = (file) => {
    customObservableElement.value.querySelectorAll('video').forEach((video) => {
        if (!video.paused) {
            video.pause();
        }
    });

    customObservableElementContainer.value = customObservableElement.value.closest('observable-elements');

    if (customObservableElementContainer.value) {
        customObservableElementContainer.value.setAttribute('disabled', '');
    }

    if (fileGallery.value) {
        fileGallery.value.open(file.id);
    }
}

const fileGallery = ref(null);

const handleIntersected = (e) => {
    if (!customObservableElement.value) {
        return;
    }

    if (e.target.getAttribute('intersected') == 'true') {
        customObservableElement.value.querySelectorAll('video')[0]?.play();
    } else {
        customObservableElement.value.querySelectorAll('video').forEach((video) => {
            if (!video.paused) {
                video.pause();
            }
        });
    }
}

onUnmounted(() => {
    galleryObserver?.disconnect();
});

watch(() => route.query.modal, (value) => {
    if (!value && customObservableElement.value?.getAttribute('intersected') == 'true') {
        customObservableElement.value.querySelectorAll('video')[0]?.play();
    }

    if (!value && customObservableElementContainer.value && customObservableElementContainer.value.hasAttribute('disabled')) {
        customObservableElementContainer.value.removeAttribute('disabled');
    }
});
</script>

<template>
    <div ref="gallery">
        <Gallery
            v-if="files.length > 0"
            ref="fileGallery"
            :id="galleryId"
            :files="files"
        />
        <observable-element ref="customObservableElement" @intersected-updated="handleIntersected" v-if="files.length > 0" class="flex flex-wrap gap-1 border border-white-foreground border-white-foreground/10 rounded-3xl"
            :style="{
                minHeight: getMinHeight ? getMinHeight + 'px' : undefined,
            }">
            <template v-if="files.length === 1">
                <button v-for="file, i in files" :key="i" class="block w-full rounded-3xl" @click="goToFile(file)"
                    :style="{
                        maxHeight: galleryWidth ? galleryWidth + 'px' : undefined,
                    }">
                    <ThumbGalleryImage v-if="file.type == 1" :image="file" />
                    <ThumbGalleryVideo v-if="file.type == 2" :video="file" />
                    <ThumbGalleryPdf v-if="file.type == 3" :pdf="file" />
                </button>
            </template>
            <template v-else-if="files.length === 2">
                <button v-for="file, i in files" :key="i" :style="{
                    width: 'calc(50% - 2px)',
                    height: '300px',
                }" class="block" :class="{
                    'rounded-l-3xl': i === 0,
                    'rounded-r-3xl': i === 1
                }" @click="goToFile(file)">
                    <ThumbGalleryImage v-if="file.type == 1" :image="file" />
                    <ThumbGalleryVideo v-if="file.type == 2" :video="file" />
                    <ThumbGalleryPdf v-if="file.type == 3" :pdf="file" />
                </button>
            </template>
            <template v-else-if="files.length === 3">
                <button class="block rounded-l-3xl" style="width: calc(50% - 2px); height: 300px;" @click="goToFile(files[0])">
                    <ThumbGalleryImage v-if="files[0].type == 1" :image="files[0]" />
                    <ThumbGalleryVideo v-if="files[0].type == 2" :video="files[0]" />
                    <ThumbGalleryPdf v-if="files[0].type == 3" :pdf="files[0]" />
                </button>
                <div class="flex flex-col gap-1 border-r-3xl" style="width: calc(50% - 2px); height: 300px;">
                    <button v-for="file, i in files.slice(1)" :key="i" :style="{
                        height: 'calc(50% - 2px)',
                    }" class="block" :class="{
                        'rounded-tr-3xl': i === 0,
                        'rounded-br-3xl': i === 1,
                    }" @click="goToFile(file)">
                        <ThumbGalleryImage v-if="file.type == 1" :image="file" />
                        <ThumbGalleryVideo v-if="file.type == 2" :video="file" />
                        <ThumbGalleryPdf v-if="file.type == 3" :pdf="file" />
                    </button>
                </div>
            </template>
            <template v-else-if="files.length >= 4">
                <button v-for="file, i in files" :key="i" :style="{
                    width: 'calc(50% - 2px)',
                    height: '150px',
                }" class="block relative" :class="{
                    'hidden': i > 3,
                    'rounded-tl-3xl': i === 0,
                    'rounded-tr-3xl': i === 1,
                    'rounded-bl-3xl': i === 2,
                    'rounded-br-3xl': i === 3,
                }" @click="goToFile(file)">
                    <ThumbGalleryImage v-if="file.type == 1" :image="file" />
                    <ThumbGalleryVideo v-if="file.type == 2" :video="file" />
                    <ThumbGalleryPdf v-if="file.type == 3" :pdf="file" />
                    <div v-if="i == 3 && files.length > 4" class="absolute h-full w-full bg-[#000000]/20 top-0 flex items-center justify-center text-center rounded-br-3xl">
                        <span class="text-[#ffffff] text-5xl">{{ files.length - 4 }}+</span>
                    </div>
                </button>
            </template>
        </observable-element>
    </div>
</template>
