<script setup>
import anon from '@images/anonymous-user.jpg';

import { computed, getCurrentInstance, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { PostType } from '@/enums';
import { useAjaxForm } from '@/utils/form';
import { elPositionFix } from '@/utils/fixed';
import { useEmitter } from '@/plugins/emitter';

import AppButton from '@/components/Button/Button.vue';
import Avatar from '@/components/Avatar.vue';
import ChannelIcon from '@/components/Channel/ChannelIcon.vue';
import IconCancel from '@/components/Icons/CancelBold.vue';
import IconImages from '@/components/Icons/Images.vue';
import FilePicker from '@/components/Form/v1/FilePicker.vue';
import FilePickerDisplay from '@/components/Form/v1/FilePickerDisplay.vue';
import FormButton from '@/components/Form/v1/FormButton.vue';
import IconCaretRightFill from '@/components/Icons/CaretRightFill.vue';
import PostTextFields from '@/components/Post/PostTextFields.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import PostPollOption from './PostPollOption.vue';

const emit = defineEmits(['back', 'postPublished']);
const emitter = useEmitter();

const props = defineProps({
    question: {
        default: false,
        type: Boolean,
    },
    channel: {
        required: true,
    },
    poll: {
        default: false,
        type: Boolean,
    },
});

const type = props.question ? 'question' : (props.poll ? 'poll' : 'post');
const router = useRouter();
const bottombar = ref();
const picker = ref();
let bottombarFix = null;

const form = useAjaxForm({
    title: null,
    content: null,
    channel_id: props.channel.id,
    location_id: null,
    type: props.question ? PostType.QUESTION : (props.poll ? PostType.POLL : PostType.POST),
    files: [],
    anonymously: false,
    poll_options: [],
    days: null,
    hours: null,
    minutes: null,
});

const titleRequired = computed(() => {
    return props.question;
});

const contentRequired = computed(() => {
    if (props.question) {
        return false;
    }

    return true;
});

const maxContentLength = computed(() => {
    if (props.poll) {
        return 800;
    }

    return 4000;
});

const contentPlaceholder = computed(() => {
    if (props.question) {
        return 'Explain your question in detail';
    }

    if (props.poll) {
        return 'Ask your question...';
    }

    return 'Engage with the community';
});

const titleIsValid = () => {
    if (!props.question) {
        return true;
    }

    return form.title
        && form.title.length >= 10
        && form.title.length <= 200;
}

const contentIsValid = () => {
    if (contentRequired.value && (!form.content || form.content.length == 0)) {
        return false;
    }

    return (form.content || '').length <= maxContentLength.value;
}

const optionsValid = () => {
    if (!props.poll) {
        return true;
    }

    // Check if there are at least two options
    if (form.poll_options.length < 2) {
        return false;
    }

    // Validate days (0 to 7)
    if (form.days === "" || form.days < 0 || form.days > 7) {
        return false;
    }

    // Validate hours (0 to 23)
    if (form.hours === "" || form.hours < 0 || form.hours > 23) {
        return false;
    }

    // Validate minutes (0 to 59)
    if (form.minutes === "" || form.minutes < 0 || form.minutes > 59) {
        return false;
    }

    // Ensure all poll options are non-empty
    const nonEmptyOptions = form.poll_options.filter(opt => opt !== "");
    if (nonEmptyOptions.length !== form.poll_options.length) {
        return false;
    }

    // Ensure all poll options are unique
    const uniqueOptions = new Set(nonEmptyOptions);

    return uniqueOptions.size === nonEmptyOptions.length;
};


const formIsValid = () => {
    return titleIsValid() && contentIsValid() && optionsValid();
}

const canSubmitForm = computed(() => {
    return formIsValid() && form.channel_id;
});

const postPublished = (post) => {
    router.replace({
        name: 'posts.show',
        params: {
            id: post.id,
        }
    });

    emit('postPublished', post);
    emitter.emit('.post:published', post);
}

onMounted(() => {
    nextTick(() => {
        bottombarFix = elPositionFix({
            el: bottombar.value,
            style: {
                position: 'absolute',
                zIndex: 50,
                bottom: 0,
            }
        });
    });
});

</script>

<template>
    <form class="w-full"
        @submit.prevent="form.post('/api/posts', { onSuccess: (response) => postPublished(response.data.data) })">

        <div class="flex items-center w-full">
            <Avatar class="shrink-0" v-if="form.anonymously" :src="anon" />
            <UserAvatar v-else class="shrink-0" :user="$store.state.user" />

            <IconCaretRightFill class="size-5 opacity-70 ml-1 shrink-0" />

            <button type="button" aria-label="change channel" @click="emit('back')"
                class="min-w-0 flex items-center gap-0.5 text-white-foreground/80 ml-1 rounded-full border border-white-foreground/20 py-1 px-2">
                <IconCancel class="size-4" />
                <ChannelIcon :channel="channel" class="size-4" />
                <div class="text-sm font-semibold leading-none flex items-center mt-[1px] min-w-0 truncate">{{
                    channel.name }}</div>
            </button>
        </div>

        <div class="mt-4">
            <PostTextFields
                :content-placeholder="contentPlaceholder"
                :max-content-length="maxContentLength"
                :show-title-field="question"
                :title-required="titleRequired"
                title-placeholder="Lead your question with 'What', 'When', 'How', etc."
                :content-required="contentRequired"
                :question="question"
                :poll="poll"
                :form="form" />
        </div>

        <div class="mt-3" v-if="poll">
            <PostPollOption :form="form" />
        </div>
        <div v-if="question" class="mt-4 flex items-center gap-2">
            <input v-model="form.anonymously" name="anonymously" type="checkbox" :value="false" id="anonymously" />
            <label class="text-sm" for="anonymously">Ask Anonymously</label>
        </div>

        <FilePickerDisplay class="pb-4" :picker="picker" />

        <div ref="bottombar">
            <div class="w-full mt-4 flex items-center gap-2 py-3 border-t border-white-foreground/20 px-4 md:px-6">
                <FilePicker v-if="!poll" ref="picker" v-model="form.files" aria-label="add images or videos" type="button" multiple
                    class="text-white-foreground/90">
                    <IconImages class="size-6" />
                </FilePicker>
                <div class="grow"></div>
                <FormButton :disabled="!canSubmitForm" :form="form" capitalize compact color="primary">{{
                    question ? 'Ask' : 'Post' }}</FormButton>
            </div>
        </div>
    </form>
</template>
