<script setup>
import { ref } from 'vue';

import ImageEditor from './ImageEditor.vue';
import { onBeforeRouteLeave } from 'vue-router';

const emit = defineEmits(['close', 'delete']);
const files = defineModel('files');

const props = defineProps({
    hideAddButton: {
        type: Boolean,
        default: false,
    },
    disableAddButton: {
        type: Boolean,
        default: false,
    },
    hideDeleteButton: {
        type: Boolean,
        default: false,
    },
    hideThumbs: {
        type: Boolean,
        default: false,
    },
    editMode: {
        type: Boolean,
        default: false,
    },
    addCallback: {
        type: Function,
    },
    saveCallback: {
        type: Function,
    },
});

const activeIndex = ref(0);
const swiper = ref(null);
const editing = ref(false);

const onDoneEditing = (data) => {
    editing.value = false;
}

const confirmClose = () => {
    confirm({
        title: 'Discard Upload?',
        darkMode: true,
    }).then(() => emit('close'));
}

const editFile = () => {
    editing.value = true;
}

const deleteFile = () => {
    files.value.splice(activeIndex.value, 1);
    emit('delete');
    setTimeout(() => {
        swiper.value.swiper.slideTo(activeIndex.value);
    }, 100);
}

const onSave = () => {
    if (props.saveCallback) {
        props.saveCallback().then(() => {
            emit('close');
        });
    }
}

const addFile = () => {
    const newSlide = files.value.length;

    if (props.addCallback) {
        props.addCallback().then(() => {
            setTimeout(() => {
                swiper.value.swiper.slideTo(newSlide);
            }, 100);
        });
    }
}

const onSwiperActiveIndexChange = (event) => {
    const index = event.target.swiper?.activeIndex;

    if (typeof index !== 'undefined') {
        activeIndex.value = index;
    }
}

onBeforeRouteLeave(() => {
    if (editing.value) {
        editing.value = false;
        return false;
    }

    return true;
});

onBeforeRouteLeave(() => {
    if (!props.editMode) {
        emit('close');
    } else {
        confirmClose();
    }

    return false;
});
</script>

<template>
    <app-layout class="bg-white text-white-foreground">
        <app-layout-header v-if="!editing">
            <div class="w-full flex items-center">
                <div class="rounded-full -ml-2">
                    <app-icon-button icon="cancel" @click="confirmClose" aria-label="Close" />
                </div>

                <div v-if="files.length > 1" class="text-sm">{{ activeIndex + 1 }} of {{ files.length }}</div>

                <div class="grow"></div>
                <template v-if="editMode">
                    <!-- disabled for now -->
                    <!-- <app-icon-button icon="pencil" @click="editFile" aria-label="Edit" /> -->
                    <app-icon-button v-if="!hideDeleteButton" icon="trash-can" @click="deleteFile" aria-label="Remove" />
                    <app-icon-button v-if="!hideAddButton" :disabled="disableAddButton" icon="plus" @click="addFile" aria-label="Add More" />
                    <app-icon-button :disabled="files.length === 0" icon="check" button-style="filled" @click="onSave" aria-label="Save" />
                </template>
            </div>
        </app-layout-header>
        <app-layout-body class="bg-gray">
            <swiper-container
                v-if="!editing"
                ref="swiper"
                class="h-full"
                :slides-per-view="1"
                :thumbs-swiper="!hideThumbs ? '.thumbs-swiper' : undefined"
                @swiperactiveindexchange="onSwiperActiveIndexChange"
                :initial-slide="activeIndex"
                >
                <swiper-slide v-for="file in files" :key="file.url">
                    <div class="flex items-center justify-center h-full">
                        <img :src="file.url" class="block h-full w-auto object-contain select-none pointer-events-none" />
                    </div>
                </swiper-slide>
            </swiper-container>
            <ImageEditor v-else :file="files[activeIndex]" @done="onDoneEditing" />
        </app-layout-body>

        <app-layout-footer v-if="!hideThumbs && !editing">
            <swiper-container
                class="w-full thumbs-swiper shrink-0"
                space-between="10"
                slides-per-view="auto"
                :free-mode="true"
            >
                <swiper-slide class="size-14 bg-white-foreground/10 cursor-pointer border border-white-foreground/20 flex items-center justify-center" v-for="file in files" :key="file.url">
                    <img :src="file.url" class="block h-full w-auto max-w-full object-contain pointer-events-none select-none" />
                </swiper-slide>
            </swiper-container>
        </app-layout-footer>
    </app-layout>
</template>
