<script setup>
import { useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';
import { useStore } from '@/store';

import FormTwoFa from '@/components/Form/v1/FormTwoFa.vue';
import FormButton from '@/components/Form/v1/FormButton.vue';
import View from '@/components/Auth/View.vue';

const store = useStore();
const router = useRouter();
const toast = useToast();
const form = useAjaxForm();

form.onSuccess((response) => {
    store.commit('user', response.data.data);
    toast.success(`Welcome ${store.state.user.name}`);

    if (store.state.user.profile) {
        router.replace({ name: 'feed' });
    } else {
        router.replace({ name: 'create-profile' });
    }
});
</script>

<template>
    <View>
        <div class="mt-6 w-full">
            <form @submit.prevent="form.post('/api/verify-email')">
                <p class="text-center">Enter the OTP sent to your email to verify your account</p>
                <FormTwoFa :form="form" show hide-cancel @resend="(e) => {
                    e.stop();
                    form.post('/api/verify-email', {}, true);
                }" />

                <FormButton :disabled-on-clean="false" color="primary" full class="mt-8" :form="form">Verify Email</FormButton>
            </form>

            <p class="mt-8 text-gray-foreground/60 text-center">
                Not you? <RouterLink class="text-primary font-semibold" :to="{ name: 'logout' }">Logout</RouterLink>
            </p>
        </div>
    </View>
</template>
