<script setup>
import { useStore } from '@/store';
import { computed } from 'vue';

import { getFriendlyTime } from '@/utils';
import { parseContent } from '@/utils/str';
import { useUtils } from '@/composables/chat';


const props = defineProps({
    conversation: {
        required: true,
        type: Object,
    },
    hideMuteIcon: {
        type: Boolean,
        default: false,
    },
});

const store = useStore();
const chatUtils = useUtils();
const getUser = computed(() => {
    return props.conversation.with_me ? store.state.user : props.conversation.other_members[0].user;
});
const getUserDisplayName = computed(() => {
    return props.conversation.with_me ? getUser.value.name + ' (You)' : getUser.value.name;
});
const getIsRead = computed(() => {
    if (props.conversation.current_member.muted) {
        return true;
    }

    return props.conversation.total_unseen <= 0;
});
const getLastMessageDisplayTime = computed(() => {
    return getFriendlyTime(props.conversation.last_message.created_at, {
        withTime: false,
        oldDateShort: true,
    });
});
const getMessageContent = computed(() => {
    if (!props.conversation.last_message) {
        return;
    }

    if (props.conversation.last_message.type == 2) {
        return chatUtils.getEventMessageContent(props.conversation.last_message).trim();
    }

    if (props.conversation.last_message.files_exists) {
        const { name } = chatUtils.getMessagePronouns(props.conversation.last_message);

        return `${name} sent an attachment`;
    }

    return parseContent({
        content: props.conversation.last_message.from_me && !props.conversation.with_me
            ? 'You: ' + props.conversation.last_message.content
            : props.conversation.last_message.content,
        onlyParsers: ['bold', 'italic'],
    })
});
</script>

<template>
    <app-list-item
        @click="$router.push({
            name: 'conversation',
            params: { id: conversation.id },
        })"
        target-as="button"
        :selected="$route.name === 'conversation' && $route.params.id === conversation.id"
    >
        <div class="flex flex-row gap-2 w-full py-2">
            <app-avatar size="lg" :src="getUser.profile.avatar?.url" :fallback-from="getUser.first_name" />
            <div class="grow min-w-0" :class="{
                    'text-muted': getIsRead,
                }">
                <div class="flex items-center gap-2" :class="{
                    'font-semibold': !getIsRead,
                }">
                    <div class="truncate">
                        {{ getUserDisplayName }}
                    </div>
                    <icon-bell-off-outline v-if="!hideMuteIcon && conversation.current_member.muted" class="size-4" />
                    <div class="grow"></div>
                    <div v-if="conversation.last_message?.created_at"
                        class="shrink-0 text-sm capitalize"
                    >
                        {{ getLastMessageDisplayTime }}
                    </div>
                    <div v-if="!getIsRead" class="shrink-0">
                        <app-badge color="primary" />
                    </div>
                </div>

                <div class="text-sm truncate">
                    <template v-if="Array.isArray(getMessageContent)">
                        <component v-for="(token, index) in getMessageContent" :key="index" :is="token" />
                    </template>
                    <template v-else>
                        {{ getMessageContent }}
                    </template>
                </div>
            </div>
        </div>
    </app-list-item>
</template>
