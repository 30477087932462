<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { collectionV2 } from '@/composables/resource';
import { renderer } from '@/composables/renderer';
import { useJoinedChannels } from '@/composables/channel';

import ChannelList from '@/components/Channel/ChannelList.vue';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import Pill from '@/components/Pills/Pill.vue';
import PillGroup from '@/components/Pills/PillGroup.vue';
import Search from '@/components/Search.vue';

const myChannels = useJoinedChannels();
const router = useRouter();

const topChannels = collectionV2({
    url: '/api/channels',
    params: {
        view: 'topChannels',
        exclude_owner: true,
    }
}, 'topChannels');

const dataRenderer = renderer([
    {
        key: '1',
        label: 'My Channels',
        collection: myChannels,
        onActive() {
            this.collection.fetchOnce();
        }
    },
    {
        key: '2',
        label: 'Recommended Channels',
        collection: topChannels,
        onActive() {
            this.collection.fetchOnce();
        }
    },
]);

const confirmCreate = ref(false);

const createChannel = () => {
    confirmCreate.value = false;
    router.push({ name: 'channels.create' });
}
</script>

<template>
    <Content>
        <head-title :value="`Channels`" />
        <Header>
            <HeaderTitle>
                Channels
            </HeaderTitle>
            <div class="grow"></div>
            <app-text-button type="button" @click="confirmCreate = true">Create</app-text-button>
            <Search class="ml-4" :placeholder="`Search for Channels on Corperland`" type="channel" />
        </Header>

        <div class="px-4 pt-4 pb-20">
            <PillGroup>
                <Pill as="button" type="button" @click="dataRenderer.setActiveView(view.key)" v-for="view in dataRenderer.views" :key="view.key" :data-active="dataRenderer.activeView?.key === view.key">
                    {{ view.label }}
                </Pill>
            </PillGroup>

            <template v-for="view in dataRenderer.views" :key="view.key">
                <ChannelList v-if="dataRenderer.activeView?.key === view.key" :collection="view.collection" class="mt-6" />
            </template>
        </div>
        <router-view />

        <app-dialog :open="confirmCreate" full-screen @update:open="confirmCreate = false">
            <app-dialog-header>
                <app-icon-button icon="cancel" @click="confirmCreate = false" />
                <app-dialog-title>
                    Channel Review
                </app-dialog-title>
            </app-dialog-header>
            <app-dialog-body>
                <div>
                    Your channel will need to be approved by the Corperland team before it will be usable.<br><br>
                    Below is a list of possible reasons why your channel may be rejected or banned so make sure you meet the requirements.
                </div>
                <ul class="mt-4">
                    <li>
                        <div class="font-medium">1. Unclear Channel Purpose</div>
                        <div class="mt-2">The channel's purpose and intended use was not clearly defined.</div>
                    </li>
                    <li class="mt-4">
                        <div class="font-medium">2. Inappropriate Content</div>
                        <div class="mt-2">Includes inappropriate words, images, or language.</div>
                    </li>
                    <li class="mt-4">
                        <div class="font-medium">3. Harmful Activities</div>
                        <div class="mt-2">Promotes harmful, discriminatory, or illegal activities.</div>
                    </li>
                </ul>

                <app-button class="my-4" color="primary" @click="createChannel" full>Continue</app-button>
            </app-dialog-body>
        </app-dialog>
    </Content>
</template>
