export const PostType = {
    POST: 1,
    QUESTION: 2,
    POLL: 3
};

export const PostView = {
    TRENDING: 1,
    LATEST: 2,
    SUBSCRIBED: 3,
}

export const MediaNetworkState = {
    EMPTY: 0,
    IDLE: 1,
    LOADING: 2,
    NO_SOURCE: 3,
}

export const FileStatus = {
    PENDING: 0,
    UPLOADING: 1,
    UPLOADED: 2,
    FAILED: 3,
}

export const UploadState = {
    FAILED: 0,
    PENDING: 1,
    IN_PROGRESS: 2,
    PROCESSING: 3,
    COMPLETED: 4,
    ABORTED: 5,
}

export const FileType = {
    IMAGE: 1,
    VIDEO: 2,
    DOCUMENT: 3,
    AUDIO: 4,
    fromMimeType(mime) {
        const mimeName = mime.split('/')[0];

        if (mimeName === 'image') {
            return this.IMAGE;
        } else if (mimeName === 'video') {
            return this.VIDEO;
        } else if (mimeName === 'audio') {
            return this.AUDIO;
        } else if (mimeName === 'application') {
            return this.DOCUMENT;
        }

        return null;
    }
}
