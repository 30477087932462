<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

defineProps({
    hideBorder: {
        type: Boolean,
        default: true,
    },
    hideBorderOnScroll: {
        type: Boolean,
        default: false,
    },
});

const topBar = ref(null);
const nextSibling = ref(null);
const nextSiblingScrollTop = ref(0);

const onScroll = () => {
    nextSiblingScrollTop.value = nextSibling.value.scrollTop;
};

onMounted(() => {
    nextSibling.value = topBar.value.nextSibling;
    onScroll();
    nextSibling.value.addEventListener('scroll', onScroll);
});

onUnmounted(() => {
    nextSibling.value?.removeEventListener('scroll', onScroll);
});
</script>

<template>
    <div ref="topBar"
        class="min-h-[70px] w-full px-4 py-2 shrink-0 rounded-t-[inherit]"
        :class="{
            'border-b border-white-foreground/10 shadow-sm': !hideBorder || (!hideBorderOnScroll && nextSiblingScrollTop > 10),
        }">
        <slot></slot>
    </div>
</template>
