<script setup>
import { computed } from 'vue';

import { useUtils } from '@/composables/chat';

const props = defineProps({
    conversation: {
        type: Object,
        required: false,
    },
    user: {
        type: Object,
        required: false,
    },
});

const utils = useUtils();

const getUser = computed(() => {
    if (props.conversation) {
        return utils.getOtherUser(props.conversation);
    }

    return props.user;
});
</script>
<template>
    <div>
        <div class="flex flex-col items-center gap-2 min-h-[400px] px-4 pt-10">
            <app-avatar :src="getUser.profile.avatar?.url" :fallback-from="getUser.first_name" size="3xl" />
            <div class="text-center">
                <h2 class="text-lg font-semibold">{{ getUser.name }}</h2>
                <p class="text-muted">
                    @{{ getUser.profile.username }}
                </p>
                <div>
                    <app-button @click="$router.push(`/profiles/${getUser.profile.username}`)" color="gray" class="mt-4" capitalize full>
                        View Profile
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>
