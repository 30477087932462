<script setup>
// Usage Guidelines: https://m3.material.io/components/icon-buttons/guidelines

import { getCurrentInstance, computed  } from "vue";

import { toCamelCase, toSentenceCase } from '@/utils/str';

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    selected: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    buttonStyle: {
        type: String,
        default: 'standard',
    },
    color: {
        type: String,
        default: 'primary',
    },
    type: {
        type: String,
        default: 'button',
    },
    lg: {
        type: Boolean,
        default: false,
    },
    sm: {
        type: Boolean,
        default: false,
    },
    xs: {
        type: Boolean,
        default: false,
    },
    text: {
        type: String,
        default: null,
    },
});

const standardStateLayerStyles = () => {
    const styles = [];

    let foregroundColor = 'text-white-foreground';

    if (props.color === 'raw-white') {
        foregroundColor = 'text-[#ffffff]';
    }

    if (!props.selected) {
        styles.push(
            foregroundColor,
            props.disabled ? '' : 'hover:bg-white-foreground/10 focus:bg-white-foreground/20 active:bg-white-foreground/20',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    } else {
        styles.push(
            props.color === 'primary' ? 'text-primary' : '',
            props.color === 'raw-white' ? 'text-[#ffffff]' : '',
            !props.disabled && props.color === 'primary' ? 'hover:bg-primary/10 focus:bg-primary/20 active:bg-primary/20' : '',
            !props.disabled && props.color === 'raw-white' ? 'hover:bg-[#ffffff]/10 focus:bg-[#ffffff]/20 active:bg-[#ffffff]/20' : '',
            props.disabled ? 'text-white-foreground opacity-[0.38]' : '',
        );
    }

    return styles.join(' ');
}

const filledStateLayerStyles = () => {
    const styles = [];

    styles.push(
        !props.disabled && props.color === 'primary' ? 'bg-primary text-primary-foreground' : '',
        !props.disabled && props.color === 'danger' ? 'bg-danger text-danger-foreground' : '',
        !props.disabled && props.color === 'black' ? 'bg-black text-black-foreground' : '',
        !props.disabled && props.color === 'gray' ? 'bg-gray text-gray-foreground' : '',
        !props.disabled && props.color === 'white' ? 'bg-white text-white-foreground' : '',
        props.disabled ? 'bg-white-foreground/10 text-white-foreground opacity-[0.38]' : '',
    );

    return styles.filter(Boolean).join(' ');
}

const filledTonalStateLayerStyles = () => {
    const styles = [];

    if (props.selected && !props.disabled) {
        styles.push('bg-primary/10 text-primary');
    } else {
        styles.push(
            'bg-white-foreground/10 text-white-foreground',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    }

    return styles.filter(Boolean).join(' ');
}

const outlinedStateLayerStyles = () => {
    const styles = [];

    if (!props.selected) {
        styles.push(
            'border border-white-foreground/50',
            props.disabled ? '' : 'hover:bg-white-foreground/10 focus:bg-white-foreground/20 active:bg-white-foreground/20',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    } else {
        styles.push(
            props.disabled ?
                'bg-white-foreground/10 text-white-foreground' :
                'bg-white-foreground text-white',
            props.disabled ? 'opacity-[0.38]' : '',
        );
    }

    return styles.join(' ');
}

const stateLayerStyles = computed(() => {
    if (props.buttonStyle === 'standard') {
        return standardStateLayerStyles();
    }

    if (props.buttonStyle === 'filled') {
        return filledStateLayerStyles();
    }

    if (props.buttonStyle === 'filled-tonal') {
        return filledTonalStateLayerStyles();
    }

    if (props.buttonStyle === 'outlined') {
        return outlinedStateLayerStyles();
    }

    return '';
});

const buttonSizeStyles = computed(() => {
    const styles = [];

    if (props.lg) {
        styles.push('size-14');
    } else if (props.sm) {
        styles.push('size-10');
    } else {
        styles.push('size-12');
    }

    return styles.join(' ');
});

const stateLayerSizeStyles = computed(() => {
    const styles = [];

    if (props.lg) {
        styles.push('w-12 min-h-12');
    } else if (props.sm) {
        styles.push('w-8 min-h-8');
    } else if (props.xs) {
        styles.push('w-5 min-h-5');
    } else {
        styles.push('w-12 min-h-12');
    }

    return styles.join(' ');
});

const iconSizeStyles = computed(() => {
    const styles = [];

    if (props.lg) {
        styles.push('size-7');
    } else if (props.sm) {
        styles.push('size-5');
    } else if (props.xs) {
        styles.push('size-3');
    } else {
        styles.push('size-6');
    }

    return styles.join(' ');
});

const getIcon = (icon, defaultIcon) => {
    const instance = getCurrentInstance();

    const componentName = toCamelCase(toSentenceCase(icon));

    if (instance && instance.appContext.components[componentName]) {
        return icon;
    }

    return defaultIcon;
};
</script>

<template>
    <button class="p-1 flex items-center justify-center" :disabled="disabled" :type="type" :class="[]">
        <div class="rounded-full" :class="[stateLayerStyles, text ? 'p-2' : '']">
            <div class="flex flex-col items-center justify-center gap-1" :class="[stateLayerSizeStyles]">
                <component
                    :is="selected ? 'icon-' + icon : getIcon('icon-' + icon + '-outline', 'icon-' + icon)"
                    :class="[iconSizeStyles]"
                />
                <div v-if="text" class="text-xs font-medium text-center shrink-0">{{ text }}</div>
                <slot v-else />
            </div>
        </div>
    </button>
</template>
