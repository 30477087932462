export const scrollToBottom = (el, smooth = false) => {
    const scroll = () => {
        el.scrollTo({ top: el.scrollHeight, behavior: smooth ? 'smooth' : 'instant' });
    };

    const images = el.querySelectorAll('img');
    const totalImages = images.length;
    let loadedImages = 0;

    if (totalImages === 0) {
        // If there are no images, scroll immediately
        scroll();
    } else {
        scroll();
        // Wait for all images to load
        images.forEach(image => {
            image.onload = () => {
                loadedImages++;
                if (loadedImages === totalImages) {
                    scroll();
                }
            };
            // If the image is already loaded (from cache), manually trigger onload
            if (image.complete) {
                image.onload();
            }
        });
    }
};
