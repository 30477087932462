<script setup>
import { inject } from "vue";

const props = defineProps({
    headline: {
        type: String,
    },
    supportingText: {
        type: String,
    },
    supportingTextMaxLines: {
        default: 3,
    },
    overline: {
        type: String,
    },
    headlineMaxLines: {
        default: 1,
    },
});

const listProps = inject('app-list-props');
</script>

<template>
    <div class="flex flex-col min-w-0 grow">
        <div v-if="overline" class="text-xs">
            {{ overline }}
        </div>
        <div v-if="headline" class="text-base" :class="{
            'line-clamp-1': headlineMaxLines == 1,
            'line-clamp-2': headlineMaxLines == 2,
            'line-clamp-3': headlineMaxLines == 3,
        }"  v-html="headline">
            
        </div>

        <div
            v-if="supportingText && !listProps.dense && !listProps.compact"
            class="text-sm text-white-foreground/70"
            :class="{
                'line-clamp-1': supportingTextMaxLines == 1,
                'line-clamp-2': supportingTextMaxLines == 2,
                'line-clamp-3': supportingTextMaxLines == 3,
            }"
        >
            {{ supportingText }}
        </div>
    </div>
</template>
