
import BaseModel from './base';

/**
 * @typedef {Object} PendingMessage
 * @property {String} conversationId
 * @property {String|null} userId
 * @property {Object} data
 * @property {String} status
 */
class PendingMessage extends BaseModel {
    static getTableName() {
        return 'pending_messages';
    }

    static _columns() {
        return {
            conversationId: { type: 'string', notNull: true },
            userId: { type: 'string' },
            data: { type: 'object', notNull: true },
            status: { type: 'string', notNull: true, default: 'pending' },
        }
    }
}

export default PendingMessage;
