<script setup>
import { computed, ref } from 'vue';

import { FileType } from '@/enums';
import { useAttachmentProgress } from '@/composables/attachments';

import MediaGallery from '@/components/Secondary/File/MediaGallery.vue';

const props = defineProps({
    media: {
        type: Array,
    }
});

const upload = useAttachmentProgress(props.media);

const galleryOpen = ref(false);

const imgSrc = computed(() => {
    if (props.media[0].type == FileType.VIDEO) {
        return props.media[0].poster?.url;
    }

    return props.media[0].url;
});
</script>

<template>
    <button type="button" @click="galleryOpen = true" v-if="media.length > 0" class="block max-w-[300px] max-h-[300px] relative">
        <img v-if="imgSrc" :src="imgSrc"
            loading="lazy"
            class="block rounded-[inherit] w-auto h-auto min-w-[100px] min-h-[100px] max-w-[300px] max-h-[300px] object-fit border border-white-foreground/20"
            />
        <div class="bg-[#000] rounded-[inherit] w-[150px] h-[150px]" v-else>
        </div>
        <div v-if="media.length > 1" class="absolute bottom-2 right-2 bg-[#000]/60 text-[#fff] p-2 rounded-xl">
            <component :is="media.length > 9 ? 'icon-numeric-9-plus-box-multiple' : `icon-numeric-${media.length}-box-multiple`" class="size-6" />
        </div>

        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <template v-if="media.filter(m => m.id).length >= media.length">
                <div v-if="media[0].type == FileType.VIDEO" class=" bg-[#000]/60 text-[#fff] rounded-full">
                    <icon-play class="size-10" />
                </div>
            </template>
            <app-attachment-progress :attachments="media" />
        </div>

        <app-dialog v-if="media.length > 0" :open="galleryOpen" @update:open="galleryOpen = $event" dark-mode full-screen max-width max-height>
            <MediaGallery :files="media" @close="galleryOpen = false" />
        </app-dialog>
    </button>
</template>
