<script setup>
import { computed } from 'vue';
import { parseContent } from '@/utils/str';

const props = defineProps({
    content: {
        type: String,
        required: true,
    },
    validMentions: {
        type: Array,
        default: () => [],
    },
    excludeParsers: {
        type: Array,
        default: () => [],
    },
    onlyParsers: {
        type: Array,
        default: () => [],
    },
});

const tokens = computed(() => parseContent({
    content: props.content,
    validMentions: props.validMentions,
    excludeParsers: props.excludeParsers,
    onlyParsers: props.onlyParsers,
}));
</script>

<template>
    <component v-for="(token, index) in tokens" :key="index" :is="token" />
</template>
