<script setup>
import dayjs from 'dayjs';
import { useStore } from '@/store';
import { computed, ref, inject } from 'vue';

import { FileType } from '@/enums';
import { getFriendlyTime, downloadFile } from '@/utils';
import { useToast } from '@/plugins/toast';
import { useEmitter } from '@/plugins/emitter';
import { uploads } from '@/services/upload';

import axios from '@/axios';
import PendingMessage from '@/db/models/pending_message';

import Document from '@/components/Secondary/File/Document.vue';
import Parser from '@/components/Secondary/Content/Parser.vue';
import ChatMessageMedia from '@/components/Secondary/Messaging/ChatMessageMedia.vue';

const emitter = useEmitter();

const props = defineProps({
    message: {
        type: Object,
        required: true,
    },
    firstOfMinute: {
        type: Boolean,
        default: false,
    },
    firstOfDay: {
        type: Boolean,
        default: false,
    },
    lastOfMinute: {
        type: Boolean,
        default: false,
    },
    lastOfDay: {
        type: Boolean,
        default: false,
    },
    first: {
        type: Boolean,
        default: false,
    },
    last: {
        type: Boolean,
        default: false,
    },
});

const store = useStore();
const toast = useToast();
const deltaX = ref(null);
const openOptions = ref(false);
const chatMessageResend = inject('chatMessageResend', null);
const chatMessageReply = inject('chatMessageReply', null);

const files = computed(() => {
    return props.message.files ? props.message.files : [];
});

const media = computed(() => {
    return files.value.filter(file => file.type == FileType.IMAGE || file.type == FileType.VIDEO);
});

const documents = computed(() => {
    return files.value.filter(file => file.type == FileType.DOCUMENT);
});

const loading = ref(false);

const onPress = (e) => {
    if (store.state.deviceType !== 'mobile') {
        return;
    }

    if (loading.value) {
        return;
    }

    e.preventDefault();
    e.srcEvent.stopPropagation();
    openOptions.value = true;
};

const replyThreshold = 100;

const onPan = (e) => {
    if (store.state.deviceType !== 'mobile') {
        return;
    }

    deltaX.value = Math.max(Math.min(e.deltaX, 200), 0);

    if (e.isFinal) {
        if (deltaX.value >= replyThreshold) {
            // @todo: implement swipe to reply
        }

        deltaX.value = null;
    }
};

const actions = computed(() => {
    return [
        {
            label: 'Reply',
            icon: 'reply',
            onSelect: () => {
                chatMessageReply(props.message);
            },
            hide: () => {
                if (!props.message.from_me || !chatMessageReply) {
                    return true;
                }

                if (props.message.status && props.message.status != 'sent') {
                    return true;
                }

                return false;
            }
        },
        {
            label: 'Copy Message',
            icon: 'copy',
            hide: () => typeof window === 'undefined' || !navigator?.clipboard || !props.message.content,
            onSelect: () => {
                navigator.clipboard.writeText(props.message.content).then(() => {
                    toast.success('Message copied', 1000);
                }).catch(() => {
                    toast.error('Failed to copy message');
                });
            },
        },
        {
            label: 'Download Attachments',
            icon: 'download',
            onSelect: () => {
                files.value.forEach(file => {
                    downloadFile(file.id);
                });
            },
            hide: () => {
                if (props.message.status && props.message.status != 'sent') {
                    return true;
                }

                return files.value.length === 0;
            }
        },
        {
            label: 'Resend',
            icon: 'send',
            onSelect: () => {
                if (chatMessageResend) {
                    chatMessageResend(props.message.id);
                }
            },
            hide: () => {
                return !props.message.from_me || props.message.status !== 'failed';
            },
        },
        {
            label: 'Delete',
            icon: 'trash-can',
            onSelect: () => {
                if (props.message.status === 'pending' || props.message.status === 'failed') {
                    PendingMessage.query().where('id', props.message.id).delete();
                    emitter.emit('.message-deletion:created', {
                        id: props.message.id,
                    });
                } else {

                    confirm({
                        title: 'Delete Message for You?',
                        description: 'This will delete the message for only you. Other people will still see it.',
                    }).then(() => {
                        loading.value = true;
                        axios.post(`/api/conversation-messages/${props.message.id}/deletions`).then(() => {
                            try {
                                emitter.emit('.conversation:updated', {
                                    id: props.message.conversation_id,
                                });
                                emitter.emit('.message-deletion:created', {
                                    id: props.message.id,
                                });
                            } catch (e) {
                                console.log('error', e);
                            }
                        }).catch(() => {
                            toast.error('An error occurred while deleting the message.');
                        }).finally(() => {
                            loading.value = false;
                        });
                    });
                }
            },
            hide: () => {
                if (props.message?.status == 'sending') {
                    return true;
                }

                return false;
            },
        },
        {
            label: 'Unsend',
            icon: 'undo',
            color: 'danger',
            onSelect: () => {
                confirm({
                    title: 'Unsend Message?',
                    description: 'This will delete the message for everyone in the conversation although people may have already seen it.',
                }).then(() => {
                    axios.delete(`/api/conversation-messages/${props.message.id}`).then(() => {
                        try {
                            emitter.emit('.conversation:updated', {
                                id: props.message.conversation_id,
                            });
                            emitter.emit(`conversations.${props.message.conversation_id}.message:deleted`, {
                                id: props.message.id,
                            });
                        } catch (e) {
                            console.log('error', e);
                        }
                    }).catch(() => {
                        toast.error('An error occurred while unsending the message.');
                    });
                });
            },
            hide: () => {
                if (!props.message.from_me) {
                    return true;
                }

                if (props.message.status && props.message.status !== 'sent') {
                    return true;
                }

                return false;
            },
        }
    ];
});

const progress = computed(() => {
    if (!media.value?.length) {
        return null;
    }

    if (!media.value[0].__client?.upload_id) {
        return null;
    }

    return uploads[media.value[0].__client.upload_id]?.progress;
});

const scrollToParentMessage = () => {
    const messageElement = document.querySelector(`[data-message-id="${props.message.parent_message.id}"]`);

    if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        messageElement.classList.add('highlight');

        messageElement.addEventListener('animationend', () => {
            messageElement.classList.remove('highlight');
        });
    }
};
</script>

<template>
    <div class="w-full" :class="{
        'mt-0.5': !firstOfMinute,
        'pt-2': firstOfMinute,
        'pb-2': lastOfMinute,
    }">
        <div class="w-full flex items-center group">
            <!-- Checkbox todo -->
            <div class="flex items-center min-w-0 gap-2 transition-transform duration-300 relative"
                :style="{ transform: deltaX ? `translateX(${deltaX}px)` : undefined }"
                v-hammer:press="onPress"
                v-hammer:pan="onPan"
                :class="{
                'ml-auto': message.from_me,
                'mr-auto': !message.from_me,
            }">
                <div :data-active="deltaX > 0" class="absolute top-1/2 -translate-y-1/2 -left-14 z-10 opacity-0 data-[active=true]:opacity-100 transition-opacity duration-500">
                    <app-icon-button button-style="filled-tonal" sm aria-hidden="true" icon="reply" />
                </div>
                <div v-if="$store.state.deviceType !== 'mobile'" :data-active="openOptions" class="opacity-0 group-hover:opacity-100 data-[active=true]:opacity-100 transition-opacity duration-200">
                    <app-dropdown-menu v-model:open="openOptions" :items="actions">
                        <app-icon-button button-style="filled-tonal" sm aria-label="More" icon="more-horiz" />
                    </app-dropdown-menu>
                </div>
                <div class="flex flex-col min-w-0 gap-1" :class="{
                    'items-start': !message.from_me,
                    'items-end': message.from_me,
                }">
                    <button type="button"
                        v-if="message.from_me && message.parent_message"
                        class="flex gap-1 items-center min-w-0 w-auto max-w-[300px] rounded-3xl py-1.5 px-5 bg-gray text-gray-foreground/70"
                        @click="scrollToParentMessage"
                    >
                        <div v-if="message.parent_message.content" class="text-sm truncate italic">
                            <Parser :content="message.parent_message.content" :only-parsers="['bold', 'italic']" />
                        </div>
                        <template v-else-if="message.parent_message.files_exists || message.parent_message.files?.length > 0">
                            <icon-attach-file class="size-4 shrink-0" />
                            <div class="text-sm italic">Attachment</div>
                        </template>
                    </button>
                    <ChatMessageMedia :media="media" class="rounded-3xl"
                        :class="{
                            'rounded-br': message.from_me && lastOfMinute && !message.content,
                            'rounded-bl': !message.from_me && lastOfMinute && !message.content,
                        }" />

                    <div class="flex flex-col gap-1" v-if="documents.length > 0 && firstOfMinute">
                        <div
                            v-for="document in documents"
                            :key="document.id"
                            class="w-[300px]">
                            <Document :document="document" />
                        </div>
                    </div>
                    <div v-if="message.content" class="w-auto max-w-[300px] rounded-3xl py-1.5 px-5 break-all" :class="{
                        'bg-primary text-primary-foreground': message.from_me,
                        'bg-gray': !message.from_me,
                        'rounded-br': message.from_me && lastOfMinute,
                        'rounded-bl': !message.from_me && lastOfMinute,
                        'animate-pulse': message.status === 'sending',
                    }" :style="{
                        '--mention-color': message.from_me ? 'var(--color-primary-foreground)' : undefined,
                        '--mention-alt-color': message.from_me ? 'var(--color-primary-foreground)' : undefined,
                    }">
                        <app-line-clamp :lines="10" :color="message.from_me ? 'primary-foreground' : 'gray-foreground'">
                            <Parser :content="message.content" :valid-mentions="message.mentions?.map(mention => mention.username)" />
                        </app-line-clamp>
                    </div>

                    <div class="text-2xs text-danger" v-if="message.status === 'failed'">
                        Failed
                    </div>
                    <div v-else-if="lastOfMinute && message.status !== 'sending' && message.status !== 'pending'">
                        <span class="text-2xs text-muted">{{ dayjs(message.created_at).format('h:mm A') }}</span>
                        <template v-if="message.from_me && last">
                            <span class="text-2xs text-muted"> • {{ message.total_seen > 0 ? 'Seen' : 'Sent' }}</span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <app-modal-bottom-sheet v-if="$store.state.deviceType === 'mobile'" v-model:open="openOptions">
            <app-list compact>
                <template
                    v-for="option in actions"
                    :key="option.label">
                    <app-list-item
                        align-center
                        :divider="option.showDivider"
                        :class="{
                            'text-danger': option.color === 'danger',
                            'text-primary': option.color === 'primary',
                        }"
                        v-if="!option.hide || !option.hide()"
                        @click="() => {
                            openOptions = false;
                            option.onSelect();
                        }"
                    >
                        <app-list-leading>
                            <component :is="`icon-${option.icon}`" class="size-5" />
                        </app-list-leading>
                        <app-list-content :headline="option.label" />
                    </app-list-item>
                </template>
            </app-list>
        </app-modal-bottom-sheet>
    </div>
</template>
