<script setup>
import { DialogRoot, DialogPortal, DialogContent, DialogOverlay } from 'radix-vue';
import { computed, provide } from 'vue';
import { useStore } from '@/store';
import { onBeforeRouteLeave } from 'vue-router';

const emit = defineEmits(['update:open']);

const props = defineProps({
    open: {
        type: Boolean,
        default: false
    },
    fullScreen: {
        type: Boolean,
        default: false
    },
    forceFullScreen: {
        type: Boolean,
        default: false
    },
    teleportTo: {
        type: String,
        required: false,
    },
    closeOnBackNavigation: {
        type: Boolean,
        default: false
    },
    basicWidth: {
        type: Boolean,
        default: false,
    },
    maxWidth: {
        type: Boolean,
        default: false,
    },
    maxHeight: {
        type: Boolean,
        default: false,
    },
    nonDismissible: {
        type: Boolean,
        default: false,
    },
    disableAutoFocus: {
        type: Boolean,
        default: false,
    },
    zIndex: {
        type: [Number, String],
        default: 100,
    },
    darkMode: {
        type: Boolean,
        default: false,
    },
    noBg: {
        type: Boolean,
        default: false,
    },
});

const store = useStore();

const onEscapeKeyDown = (e) => {
    if (props.nonDismissible) {
        e.preventDefault();
    }
}

const onInteractOutside = (e) => {
    if (props.nonDismissible) {
        e.preventDefault();
    }
}

const onOpenAutoFocus = (e) => {
    if (props.disableAutoFocus) {
        e.preventDefault();
    }
}

const isFullScreenMode = computed(() => {
    return props.fullScreen && (store.state.deviceType === 'mobile' || props.forceFullScreen);
});

const contentClass = computed(() => {
    const classes = [];

    if (!props.noBg) {
        classes.push('bg-white text-white-foreground');
    }

    if (isFullScreenMode.value) {
        classes.push(
            'top-0 right-0 h-screen max-md:w-screen md:min-w-[350px] lg:min-w-[450px]',
            'animate-in duration-300 data-[state=closed]:animate-out',
            'data-[state=open]:slide-in-from-right data-[state=closed]:slide-out-to-right',
        );

        if (props.basicWidth) {
            classes.push('md:max-w-[450px] lg:max-w-[500px]');
        }
    } else {
        classes.push(
            'top-1/2 left-1/2 max-h-[85vh] min-w-[280px] -translate-x-1/2 -translate-y-1/2 rounded-2xl',
        );

        if (props.basicWidth) {
            classes.push('w-[312px]');
        } else if (props.maxWidth) {
            classes.push('md:w-[700px]');
        } else {
            classes.push('max-w-[300px] md:max-w-[560px] md:min-w-[350px] lg:min-w-[450px]');
        }

        if (props.maxHeight) {
            classes.push('md:h-[85vh]');
        }
    }

    return classes.join(' ');
});

provide('isFullScreenMode', isFullScreenMode);

onBeforeRouteLeave(() => {
    if (props.open) {
        emit('update:open', false);

        return false;
    }
});
</script>

<template>
    <div>
        <DialogRoot :open="open" @update:open="emit('update:open', $event)">
            <DialogPortal :to="teleportTo ? teleportTo : 'body'">
                <DialogOverlay :data-dark-mode="darkMode || undefined" class="
                    bg-black/20 animate-in duration-300 data-[state=closed]:animate-out
                    data-[state=open]:fade-in data-[state=closed]:fade-out
                    fixed inset-0
                " :style="{
                    zIndex,
                }" />
                <DialogContent
                    @escapeKeyDown="onEscapeKeyDown"
                    @interactOutside="onInteractOutside"
                    @openAutoFocus="onOpenAutoFocus"
                    class="fixed focus:outline-none flex flex-col"
                    :data-dark-mode="darkMode || undefined"
                    :class="contentClass"
                    :style="{
                        zIndex,
                    }"
                >
                    <slot />
                </DialogContent>
            </DialogPortal>
        </DialogRoot>
    </div>
</template>
