<script setup>
import { computed, inject } from 'vue';

import { inputProps } from './utils';

const props = defineProps(inputProps());

const form = inject('form');
form.set(props.name, props.value);

const attachment = computed(() => {
    return form.get(props.name);
});
</script>
<template>
    <div v-if="attachment">
        <div class="size-20 bg-primary rounded-md">
            <img :src="value.url" class="w-full h-auto object-contain" />
        </div>
    </div>
</template>
