import AppForm from '@/components/Form/Form.vue';
import AppFormError from '@/components/Form/Error.vue';
import AppFormGroup from '@/components/Form/Group.vue';
import AppFormControl from '@/components/Form/Control.vue';
import AppFormInput from '@/components/Form/Input.vue';
import AppFormLabel from '@/components/Form/Label.vue';
import AppFormHelp from '@/components/Form/Help.vue';
import AppFormTextarea from '@/components/Form/Textarea.vue';
import AppFormFilePicker from '@/components/Form/FilePicker.vue';
import AppFormAttachments from '@/components/Form/Attachments.vue';
import AppFormAttachment from '@/components/Form/Attachment.vue';
import AppAttachmentProgress from '@/components/Form/Views/AttachmentProgress.vue';

export default {
    AppForm,
    AppFormError,
    AppFormGroup,
    AppFormLabel,
    AppFormHelp,
    AppFormInput,
    AppFormControl,
    AppFormTextarea,
    AppFormFilePicker,
    AppFormAttachments,
    AppFormAttachment,
    AppAttachmentProgress,
}
