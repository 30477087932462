<script setup>
import { inject, onBeforeMount, ref } from 'vue';
import EmoPicker from '@/components/Secondary/EmoPicker.vue';

const form = inject('form');
const textarea = ref(null);
const ready = ref(true);

let emojiCursorPosition = null;
let draft = null;

const props = defineProps({
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    sending: {
        type: Boolean,
        default: false,
    },
});

const onEmojiClick = (e) => {
    emojiCursorPosition = textarea.value.insert(e.value, emojiCursorPosition);
}

const onEmojiClose = () => {
    emojiCursorPosition = null;
}

const actions = ref([
    // {
    //     label: 'Record Voice',
    //     icon: 'keyboard-voice',
    //     action: () => {},
    // },
]);

onBeforeMount(() => {
    ready.value = false;

    form.getDraft().then((result) => {
        draft = result;
    }).finally(() => {
        ready.value = true;
    });
});
</script>

<template>
    <div v-if="ready" class="w-full flex flex-col gap-2">
        <div class="flex items-end gap-2 px-2 py-2 rounded-3xl bg-gray relative">
            <EmoPicker @emoji-click="onEmojiClick" @emoji-close="onEmojiClose">
                <button :disabled="disabled" type="button" class="shrink-0 flex items-center justify-center size-7">
                    <icon-emoji-outline class="size-6" />
                </button>
            </EmoPicker>
            <div class="grow min-w-0">
                <app-form-attachments class="py-4" name="files" :value="draft?.content.files" />

                <div class="min-h-7">
                    <app-form-textarea
                        :disabled="disabled"
                        ref="textarea"
                        :value="draft?.content.content"
                        submit-on-enter
                        name="content"
                        placeholder="Message..."
                        rows="1" />
                </div>
            </div>

            <template v-if="!disabled">
                <div v-show="form.model.content || form.model.files?.length">
                    <button
                        aria-label="Send Message"
                        class="bg-primary text-primary-foreground rounded-3xl h-7 w-10 flex items-center justify-center shrink-0">
                        <component :is="sending ? 'icon-loader' : 'icon-telegram'" class="size-5" />
                    </button>
                </div>
                <div v-show="!form.model.content && !form.model.files?.length" class="flex items-center gap-2">
                    <button
                        v-for="action in actions"
                        :key="action.label"
                        type="button"
                        class="shrink-0 flex items-center justify-center size-7 rounded-full"
                        @click.prevent.stop="action.action">
                        <component :is="`icon-${action.icon}`" class="size-6" />
                    </button>

                    <app-form-file-picker is-private name="files" class="shrink-0 flex items-center justify-center size-7 rounded-full">
                        <icon-attach-file class="size-6" />
                    </app-form-file-picker>
                </div>
            </template>

            <!-- <div class="absolute top-0 left-0 w-full h-full rounded-[inherit]">
            </div> -->
        </div>
    </div>
</template>
