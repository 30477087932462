<script setup>
const model = defineModel('selectedUser');

defineProps({
    user: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <app-list-item :selected="selectedUser?.id === user.id" @click="model = user" align-center>
        <div class="flex gap-2">
            <app-avatar :src="user.profile.avatar?.url" :fallback-from="user.first_name" />
            <div class="truncate">
                <div>{{ user.name }}</div>
                <div class="text-muted text-sm">@{{ user.profile.username }}</div>
            </div>
        </div>
        <app-list-trailing radio />
    </app-list-item>
</template>
