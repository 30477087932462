import axios from '@/axios';
import { useToast } from '@/plugins/toast';
import { useEmitter } from '@/plugins/emitter';
import { reactive } from 'vue';

export const useSavePost = (post) => {
    const toast = useToast();
    const emitter = useEmitter();

    const state = reactive({
        processing: false,
        get saved() {
            return Boolean(post.preference?.saved);
        },
    });

    const savePost = (saved) => {
        state.processing = true;

        axios.post(`/api/saved-posts/${post.id}`, {
            saved,
        }).then((response) => {
            post.preference = response.data.data;
            toast.success(saved ? 'Post saved' : 'Post unsaved');
            emitter.emit('.post-preference:saved', { ...response.data.data, post_id: post.id });
        }).catch((error) => {
            toast.handleAxiosError(error);
        }).finally(() => {
            state.processing = false;
        });
    };

    return {
        save() {
            savePost(!state.saved);
        },
        state,
    };
}

export const useSharePost = (post) => {
    const baseUrl = import.meta.env.VITE_APP_URL;
    const state = reactive({
        supported: typeof window !== 'undefined' && navigator.share,
    });

    return {
        state,
        share() {
            navigator.share({
                url: `${baseUrl}/posts/${post.id}`,
            }).catch((error) => {
                // share may be cancelled
                console.error(error);
            });
        }
    };
}
