<script setup>
import 'photoswipe/style.css';

import { AvatarFallback, AvatarImage, AvatarRoot } from 'radix-vue';
import Photoswipe from 'photoswipe';

const props = defineProps({
    src: {
        required: false,
        type: String,
    },
    image: {
        required: false,
        type: Object,
    },
    fallback: {
        required: false,
        type: String,
    },
    alt: {
        required: false,
        type: String,
    },
    size: {
        required: false,
        type: String,
        default: 'md'
    },
    fallbackFrom: {
        required: false,
        type: String
    },
    roundedFull: {
        required: false,
        type: Boolean,
        default: true,
    },
    asLink: {
        required: false,
        default: false,
        type: Boolean,
    }
});

const makeFallback = () => {
    if (!props.fallbackFrom) return null;

    const names = props.fallbackFrom.split(' ');

    return names[0][0];
}

const openLink = (e) => {
    if (props.image && props.asLink) {
        e.preventDefault();
        const pswp = new Photoswipe({
            index: 0,
            dataSource: [
                {
                    src: props.image.url,
                    width: props.image.width,
                    height: props.image.height,
                }
            ],
        });
        pswp.init();
    }
}
</script>

<template>
    <div class="shrink-0">
        <AvatarRoot
            :as="asLink && src ? 'a' : undefined"
            :href="asLink && src ? src : undefined"
            :target="asLink && src ? '__blank' : undefined"
            @click="openLink"
            class="bg-gray inline-flex select-none items-center justify-center overflow-hidden align-middle shrink-0"
            :class="{
                'h-[18px] w-[18px]': size === 'xs',
                'h-[28px] w-[28px]': size === 'sm',
                'h-[36px] w-[36px]': size === 'md',
                'h-[48px] w-[48px]': size === 'lg',
                'h-[52px] w-[52px]': size === 'xl',
                'h-[72px] w-[72px]': size === '2xl',
                'h-[96px] w-[96px]': size === '3xl',
                'h-[128px] w-[128px]': size === '4xl',
                'rounded-full': roundedFull,
                'rounded-md': !roundedFull,
            }"
            >
            <AvatarImage
                class="h-full w-full rounded-[inherit] object-cover"
                :src="src"
                :alt="alt"
                loading="lazy"
            />
            <AvatarFallback
                v-if="fallback || fallbackFrom"
                class="text-gray-foreground leading-1 flex h-full w-full items-center justify-center bg-gray font-medium"
                :class="{
                    'text-xs': size === 'sm',
                    'text-lg': size === 'xl',
                    'text-2xl': size === '2xl',
                    'text-3xl': size === '3xl',
                    'text-4xl': size === '4xl',
                }"
            >
            {{ fallback ? fallback : makeFallback() }}
            </AvatarFallback>
            <slot />
        </AvatarRoot>
    </div>
</template>
