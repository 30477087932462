<script setup>
import { computed, ref } from 'vue';

import { useAttachmentProgress } from '@/composables/attachments';

import FilePicker from '@/components/Form/v1/FilePicker.vue';

const model = defineModel();
const picker = ref();
const props = defineProps({
    roundedFull: {
        required: false,
        default: false,
    },
    ariaLabel: {
        required: false,
        type: String
    },
    size: {
        required: false,
        type: String,
    },
    isPrivate: {
        required: false,
        default: false,
        type: Boolean,
    }
});

const progress = computed(() => {
    if (!model.value) {
        return null;
    }

    return useAttachmentProgress([model.value]);
});
</script>

<template>
    <div class="size-20 relative" :class="{
        'rounded-full': roundedFull,
    }">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
            <FilePicker
                v-show="progress?.state == 4 || progress?.state == 0"
                :is-private="isPrivate"
                v-model="model"
                ref="picker"
                type="button"
                :accept-video="false"
                class="bg-[#1f1f1f] text-[#ffffff] rounded-full size-8 flex items-center justify-center"
            >
                <component :is="progress?.state == 0 ? 'icon-error' : 'icon-camera-add'" class="size-5" />
            </FilePicker>
            <app-attachment-progress v-if="model" :attachments="model" hide-error :key="model" />
        </div>
        <img v-if="model" class="object-cover h-full w-full rounded-[inherit] transition-all duration-300 pointer-events-none select-none" :src="model.url">
    </div>
</template>
