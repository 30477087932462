<script setup>
import { onMounted, provide, ref } from 'vue';

import axios from '@/axios';
import echo from '@/plugins/echo';
import { useToast } from '@/plugins/toast';
import { unflatten, deepMerge, deepCopy, isDirty, deepGet, deepUnset } from '@/utils';

import Draft from '@/db/models/draft';
import { useStore } from '@/store';

const emit = defineEmits(['error', 'success', 'update:submitting']);

const props = defineProps({
    submitCallback: {
        type: Function,
        required: false,
    },
    url: {
        type: String,
        required: false,
    },
    method: {
        type: String,
        required: false,
        default: 'post',
    },
    transformRequestUsing: {
        type: [Function, Array],
        required: false,
    },
    headers: {
        type: Object,
        required: false,
    },
    params: {
        type: Object,
        required: false,
    },
    draftItemType: {
        type: String,
        required: false,
    },
    draftItemId: {
        type: [String, Number],
        required: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const toast = useToast();
const store = useStore();
const model = ref({});
const defaultModel = ref({});
const el = ref();
const submitting = ref(false);
const errors = ref({});
let lastFormData = null;
let draftTimeout = null;
const watchCallbacks = [];

const onDataChange = (key, value) => {
    clearTimeout(draftTimeout);

    draftTimeout = setTimeout(() => {
        saveDraft();
    }, 1000);

    watchCallbacks.forEach((callback) => {
        callback(key, value);
    });
}

const saveDraft = () => {
    if (!props.draftItemType) {
        return;
    }

    Draft.updateOrCreate({
        userId: store.state.user?.id,
        itemType: props.draftItemType,
        itemId: props.draftItemId,
    }, {
        content: deepCopy(model.value),
    });
}

const updateSubmitting = (value) => {
    submitting.value = value;
    emit('update:submitting', value);
};

const form = {
    get disabled() {
        return props.disabled;
    },
    get submitting() {
        return submitting.value;
    },
    get model() {
        return model.value;
    },
    get original() {
        return defaultModel.value;
    },
    get errors() {
        return errors.value;
    },
    get isDirty() {
        return isDirty(this.original, this.model);
    },
    getData() {
        return deepCopy(model.value);
    },
    async getDraft() {
        return new Promise((resolve) => {
            if (!props.draftItemType) {
                resolve(null);

                return;
            }

            Draft.query().where({
                userId: store.state.user?.id,
                itemType: props.draftItemType,
                itemId: props.draftItemId,
            }).first().then((result) => {
                resolve(result);
            });
        });
    },
    async deleteDraft() {
        const draft = await this.getDraft();

        if (draft) {
            return await draft.delete();
        }

        return true;
    },
    get(key) {
        return deepGet(model.value, key);
    },
    getOriginal(key) {
        return deepGet(defaultModel.value, key);
    },
    reset(key) {
        deepMerge(model.value, unflatten({ [key]: this.getOriginal(key) }));
    },
    resetAll() {
        model.value = deepCopy(defaultModel.value);
    },
    set(key, value) {
        deepMerge(defaultModel.value, unflatten({ [key]: value }));
        deepMerge(model.value, unflatten({ [key]: value }));

        return this.get(key);
    },
    clear(key) {
        deepUnset(model.value, key);

        onDataChange(key);

        return this.get(key);
    },
    clearAll() {
        model.value = {};

        this.deleteDraft();
    },
    update(key, value) {
        deepMerge(model.value, unflatten({ [key]: value }));

        const ret = this.get(key);

        onDataChange(key, ret);

        return value;
    },
    push(key, ...values) {
        const existing = this.get(key);

        return this.update(key, existing ? [...existing, ...values] : values);
    },
    watch(callback) {
        watchCallbacks.push(callback);
    },

}

const submit = () => {
    const data = deepCopy(model.value);

    if (lastFormData && JSON.stringify(lastFormData) === JSON.stringify(data) && submitting.value) {
        // this prevents double submissions
        return;
    }

    lastFormData = data;

    updateSubmitting(true);
    errors.value = {};

    return new Promise((resolve, reject) => {
        const data = new FormData(el.value);
        const headers = props.headers ?? {};
        headers['X-Socket-ID'] = echo.socketId();

        axios({
            method: props.method,
            url: props.url,
            transformRequest: props.transformRequestUsing,
            params: props.params,
            data,
            headers,
        })
            .then((response) => {
                emit('success', response);
                resolve(response);
            })
            .catch((error) => {
                if (!error.response) {
                    toast.error('Request failed. Please check your internet connection or try again later.');
                } else if (error.response.status === 429) {
                    toast.error('Too many requests. Please try again later.');
                } else if (error.response.status === 422) {
                    for (let obj in error.response.data.errors) {
                        errors.value[obj] = error.response.data.errors[obj][0];
                    }
                } else if (error.response.status === 400) {
                    toast.error(error.response.data.message || 'Invalid request.');
                } else if (error.response.status === 401) {
                    toast.error('Login required.');
                } else if (error.response.status === 403) {
                    toast.error('You are not authorized to perform this action.');
                } else {
                    toast.error('An error occured. Please try again later.');
                }

                emit('error', error);

                reject(error);
            }).finally(() => {
                updateSubmitting(false);
            });
    });
    }

const onSubmit = async () => {
    if (!props.submitCallback) {
        submit();

        return;
    }

    const res = await props.submitCallback(form);

    if (res === false) {
        return;
    }

    submit();
}

provide('form', form);

defineExpose(form);
</script>

<template>
    <form class="w-full" ref="el" @submit.prevent="onSubmit" :action="url" :method="method" :disabled="disabled">
        <slot></slot>
    </form>
</template>
