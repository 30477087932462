<script setup>
import { resourceV2 } from "@/composables/resource";
import { onBeforeMount } from "vue";
import useClient from '@/plugins/client.js';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
});

const resource = resourceV2({
    url: `/api/information-hub-posts/${props.id}`,
});

const client = useClient();

const sharePost = () => {
    client.navigator.share({
        url: resource.data.id  
    })
}
onBeforeMount(() => {
    resource.fetch({
        swCache: true,
    });
});
</script>

<template>
    <app-dialog full-screen force-full-screen basic-width :open="true" @update:open="$router.back()"
        :close-on-back-navigation="false">
        <head-title v-if="resource.data" :value="resource.data.title" />
        <app-dialog-header>
            <app-icon-button icon="arrow-back" @click="$router.back()" />
            <app-dialog-title hide>Information Hub Article</app-dialog-title>
            <div v-if="resource.data">
                <div class="text-lg leading-snug font-medium">{{ resource.data.category.name }}</div>
                <div class="text-xs leading-none text-white-foreground/70 font-meium">Information Hub</div>
            </div>
            <div class="grow"></div>
            <app-icon-button icon="share" @click="sharePost" />
        </app-dialog-header>

        <app-dialog-body>
            <div v-if="resource.data">
                <h1 class="text-lg font-medium mb-3" v-if="resource.data">{{ resource.data.title }}</h1>
                <div class="editor-content" v-if="resource.data" v-html="resource.data.content" />
            </div>
        </app-dialog-body>
    </app-dialog>
</template>
