<script setup>
import { watch } from 'vue';
import { inject } from 'vue';

const emit = defineEmits(['update:value']);

const props = defineProps({
    id: {
        type: String,
        required: false,
    },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
    },
    type: {
        type: String,
        required: false,
    },
    value: {
        type: String,
        required: false,
    },
});

const form = inject('form');
form?.set(props.name, props.value);

const onInput = (event) => {
    form?.update(props.name, event.target.value);
    emit('update:value', event.target.value);
}

watch(() => props.value, (newValue) => {
    form?.update(props.name, newValue);
    emit('update:value', newValue);
});
</script>

<template>
    <input
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        :value="value"
        @input="onInput"
        class="
            grow block focus:outline-none disabled:opacity-70 disabled:bg-gray
            border-0 focus:ring-0 bg-transparent
        " />
</template>
