<script setup>
import { ProgressIndicator, ProgressRoot } from 'radix-vue';
import { useAttachmentProgress } from '@/composables/attachments';

const props = defineProps({
    attachments: {
        required: true,
    },
    hideError: {
        type: Boolean,
        default: false,
    },
});

const upload = useAttachmentProgress(props.attachments);

function circleStyle(progress) {
    const radius = 50;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;
    return `stroke-dasharray: ${circumference} ${circumference}; stroke-dashoffset: ${offset};`;
}
</script>

<style scoped>
.progress-circle {
    width: 60px;
    height: 60px;
}

.circle-background {
    fill: none;
    stroke: var(--progress-bg-stroke, #333);
    stroke-width: var(--progress-stroke-size, 12px);
    stroke-opacity: var(--progress-bg-stroke-opacity, 1);
}

.circle-indicator {
    fill: none;
    stroke: var(--progress-indicator-stroke, #fff);
    stroke-width: var(--progress-stroke-size, 12px);
    stroke-opacity: var(--progress-indicator-stroke-opacity, 1);
}
</style>

<template>
    <div class="bg-[#000]/60 rounded-full" v-if="upload.state != 4">
        <icon-error v-if="upload.state == 0 && !hideError" class="size-10" />
        <div v-else-if="upload.state == 1 || upload.state == 2" class="progress-circle progress-indicator-[#fff] progress-bg-[#fff] progress-bg-opacity-20 relative p-1">
            <ProgressRoot as="svg" viewBox="0 0 120 120" v-model="upload.progress">
                <circle
                    class="circle-background"
                    cx="60" cy="60" r="50"
                />
                <ProgressIndicator as="circle"
                    class="circle-indicator"
                    cx="60" cy="60" r="50"
                    :style="circleStyle(upload.progress)"
                />
            </ProgressRoot>
            <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-xs font-semibold">
                {{ upload.progress }}%
            </div>
        </div>
        <icon-loader spin v-else-if="upload.state == 3" class="size-10" />
    </div>
</template>
