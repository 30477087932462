<script setup>
import { DropdownMenuRoot, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuPortal } from 'radix-vue';
import { ref } from 'vue';

const emit = defineEmits(['emoji-click', 'emoji-close']);

const open = ref(false);
</script>

<template>
    <DropdownMenuRoot v-model:open="open" @update:open="emit('emoji-close')" :modal="false">
        <DropdownMenuTrigger>
            <slot />
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
            <DropdownMenuContent align="start">
                <emoji-picker @emoji-click="emit('emoji-click', {value: $event.detail.unicode })" />
            </DropdownMenuContent>
        </DropdownMenuPortal>
    </DropdownMenuRoot>
</template>
