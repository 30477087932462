import { startUpload } from './upload';
import Upload from '@/db/models/upload';
import axios from '@/axios';

export const sendMessage = async (message, newConversation = false) => {
    message.status = 'sending';

    let files = message.data.files ? message.data.files : [];

    const uploads = files.map(async (file) => {
        if (file.id) {
            // file is already uploaded
            return 'skipped';
        }

        const upload = await Upload.query().find(file.__client.upload_id);

        await startUpload(upload);

        file.id = upload.response.file.id;
        file.url = upload.response.file.url;

        return 'uploaded';
    });

    const results = await Promise.allSettled(uploads);

    message.data.files = files;
    await message.save();

    if (results.some((result) => result.status === 'rejected')) {
        message.status = 'failed';
        await message.save();

        console.error(results.filter((result) => result.status === 'rejected'));

        throw new Error('Failed to upload all files');
    }

    try {
        const response = await axios.post(newConversation ? `/api/conversations` : `/api/conversations/${message.conversationId}/messages`, {
            user: newConversation ? message.conversationId : undefined,
            content: message.data.content,
            files: message.data.files?.map((file) => ({ id: file.id })),
            parent_message: message.data.parent_message ? { id: message.data.parent_message.id } : undefined,
        });

        message.status = 'sent';
        await message.save();

        return response.data.data;
    } catch (error) {
        message.status = 'failed';
        await message.save();

        throw error;
    }
}

export const deleteUploads = async (message) => {
    const uploads = message.data.files.map(async (file) => {
        const upload = await Upload.query().find(file.__client.upload_id);

        if (!upload) {
            return;
        }

        await upload.delete();
    });

    await Promise.all(uploads);
}
