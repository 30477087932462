<script setup>
import { ref } from 'vue';

import { PostType } from '@/enums';
import { useReaction } from '@/utils/reaction';
import { getFriendlyTime, abbreviateNumber, formatNumberWithCommas } from '@/utils';
import { useSavePost } from '@/composables/post';

import Actions from '@/components/Post/Actions.vue';
import IconAnalytics from '@/components/Icons/Analytics.vue';
import IconComment from '@/components/Icons/Comment.vue';
import IconBookmark from '@/components/Icons/Bookmark.vue';
import IconEyeCircleOutline from '@/components/Icons/EyeCircleOutline.vue';
import IconThumbsDown from '@/components/Icons/ThumbsDown.vue';
import IconThumbsDownFilled from '@/components/Icons/ThumbsDownFilled.vue';
import IconThumbsUp from '@/components/Icons/ThumbsUp.vue';
import IconThumbsUpFilled from '@/components/Icons/ThumbsUpFilled.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import PostLink from '@/components/Link/PostLink.vue';

const props = defineProps({
    post: {
        required: true,
        type: Object,
    },
});

const viewsModalOpen = ref(false);
const insights = {};
const openInsightsModal = () => {
    viewsModalOpen.value = true;
    insights.views = props.post.total_views;
};

const closeInsightsModal = () => {
    viewsModalOpen.value = false;
};

defineEmits(['deleted']);

const reaction = useReaction(props.post.id, 'post', {
    total_likes: props.post.positive_reactions_count,
    user_reaction: props.post.user_reaction ? props.post.user_reaction.type : null,
});

const savePost = useSavePost(props.post);
</script>

<template>
    <div>
        <div class="flex items-center gap-1 font-medium">
            <div v-if="post.last_edited_at" class="text-primary font-semibold text-sm">
                Edited
            </div>
        </div>
        <div class="pt-2 mt-2 border-t border-white-foreground/10">
            <div class="flex items-center justify-between self-stretch text-white-foreground/60 font-medium">
                <PostLink
                    :post="post"
                    class="flex py-2 justify-center items-center gap-2 cursor-pointer">
                    <IconComment width="20" height="20" />
                    <span class="text-sm md:text-sm font-medium">{{ abbreviateNumber(post.comments_count) }}</span>
                </PostLink>
                <button aria-label="Upvote" type="button" class="flex py-2 justify-center items-center gap-2" @click="reaction.like">
                    <component
                        :is="reaction.liked ? IconThumbsUpFilled: IconThumbsUp"
                        :class="{'text-[#F24E1E]': reaction.liked}"
                        width="20" height="20" />
                    <span class="text-sm md:text-sm font-medium">{{ abbreviateNumber(reaction.total_likes) }}</span>
                </button>
                <button aria-label="Downvote" type="button" class="flex py-2 justify-center items-center gap-2" @click="reaction.dislike">
                    <component
                        :is="reaction.disliked ? IconThumbsDownFilled: IconThumbsDown"
                        :class="{'text-[#F24E1E]': reaction.disliked}"
                        width="20" height="20" />
                </button>
                <button aria-label="Views" type="button" class="flex py-2 justify-center items-center gap-2" @click="openInsightsModal">
                    <IconAnalytics width="20" height="20" />
                    <span class="text-sm md:text-sm font-medium">{{ abbreviateNumber(post.total_views) }}</span>
                </button>
                <div class="flex items-center gap-2">
                    <button
                        v-if="post.channel.user_membership?.status == 1"
                        :disabled="savePost.state.processing"
                        :class="{'text-primary': savePost.state.saved }"
                        type="button"
                        class="block disabled:opacity-50"
                        @click="savePost.save()">
                        <IconBookmark width="20" height="20" />
                    </button>
                    <Actions :post="post" @deleted="$emit('deleted')" />
                </div>
                <Modal :open="viewsModalOpen" @update:open="closeInsightsModal">
                    <div class="min-h-[150px]">
                        <ModalTitle show>
                            <h1 class="text-2xl font-semibold">Insights</h1>
                        </ModalTitle>
                        <div class="mt-4">
                            <div class="flex items-center gap-2">
                                <IconEyeCircleOutline class="size-5" />
                                <p class="text-sm text-white-foreground/80">This content has been viewed {{ formatNumberWithCommas(insights.views) }} times.</p>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    </div>
</template>
