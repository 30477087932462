<script setup>
import { collectionV2 } from "@/composables/resource";
import { computed, nextTick, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import Content from "@/views/_partials/Content.vue";
import EmptyList from "@/components/Renderer/EmptyList.vue";
import Header from "@/views/_partials/header/Header.vue";
import HeaderTitle from "@/views/_partials/header/HeaderTitle.vue";
import InfiniteScrollObserver from "@/components/Renderer/InfiniteScrollObserver.vue";
import IconSearch from "@/components/Icons/Search.vue";
import ProfileLink from "@/components/Link/ProfileLink.vue";
import Skeleton from "@/components/Renderer/Skeleton.vue";
import UserCard from "@/components/User/UserCard.vue";
import { useStore } from "@/store";
import { useToast } from "@/plugins/toast";
import { useAjaxForm } from "@/utils/form";

const route = useRoute();
const header = ref();

const store = useStore();
const unblockForm = useAjaxForm();

const blocked = collectionV2({
    url: "/api/blocked-users",
});

const collection = computed(() => {
    return blocked;
});

const redfreshList = () => {
    collection.value.fetch();
};

onMounted(() => {
    collection.value.fetch();
});
</script>

<template>
    <Content>
        <Header
            with-back-button
            ref="header"
            :search-collection="collection"
            search-placeholder="Search for a user"
        >
            <HeaderTitle> Blocked Users </HeaderTitle>

            <div class="grow"></div>
            <button
                @click="header.openSearch"
                type="button"
                aria-label="Open search"
            >
                <IconSearch class="size-7" />
            </button>
        </Header>

        <div class="px-4 mt-6">
            <div class="mt-3">
                <Skeleton :collection="collection" minimal></Skeleton>
                <EmptyList :collection="collection" />
                <ul class="flex flex-col gap-2" v-if="collection.data">
                    <li v-for="data in collection.data" :key="data.id">
                        <div
                            class="border flex flex-col lg:flex-row lg:justify-between items-start lg:items-center gap-x-3 border-white-foreground/10 rounded-md pr-5"
                        >
                            <ProfileLink
                                :profile="
                                    route.query.tab !== 'suggestions'
                                        ? data.profile
                                        : data.suggested_user.profile
                                "
                            >
                                <UserCard
                                    :user="
                                        route.query.tab !== 'suggestions'
                                            ? data
                                            : data.suggested_user
                                    "
                                    class="border-none"
                                />
                            </ProfileLink>

                            <AppButton
                                @click="
                                    $store
                                        .blockUser({
                                            user: data,
                                            unblock: true,
                                        })
                                        .then((result) => {
                                            if (!result.success) {
                                                return;
                                            }
                                            redfreshList();
                                        })
                                "
                                class="ml-auto max-md:mb-4"
                                type="button"
                                color="danger-border"
                                compact
                                capitalize
                            >
                                <span>Unblock</span>
                            </AppButton>
                        </div>
                    </li>
                </ul>
                <InfiniteScrollObserver :collection="collection" />
            </div>
        </div>
    </Content>
</template>
