import { UploadState } from '@/enums';
import BaseModel from './base';

const files = {};

/**
 * @property {string} serverId
 * @property {Object} request
 * @property {number} currentChunk
 * @property {number} totalChunks
 * @property {number} maxChunkSize
 * @property {number} totalFileSize
 * @property {number} totalUploadedSize
 * @property {number} currentProgressSize
 * @property {Number} state
 * @property {Object} response
 */
class Upload extends BaseModel {
    static getTableName() {
        return 'uploads';
    }

    static _columns() {
        return {
            serverId: { type: 'string' },
            currentChunk: { type: 'number', notNull: true, default: 0 },
            totalChunks: { type: 'number', notNull: true, default: 0 },
            maxChunkSize: { type: 'number', notNull: true, default: 0 },
            totalFileSize: { type: 'number', notNull: true, default: 0 },
            totalUploadedSize: { type: 'number', notNull: true, default: 0 },
            currentProgressSize: { type: 'number', notNull: true, default: 0 },
            state: { type: 'number', notNull: true, default: UploadState.PENDING },
            request: { type: 'object' },
            response: { type: 'object' },
        }
    }

    get progress () {
        if (this.totalFileSize === 0) {
            return 0;
        }

        const totalUploadedSize = Math.min(this._attributes.totalUploadedSize + this._attributes.currentProgressSize, this._attributes.totalFileSize);

        return Math.round((totalUploadedSize / this._attributes.totalFileSize) * 100);
    }

    /**
     * @returns {null|File}
     */
    get file () {
        return files[this._attributes.id] || null;
    }

    /**
     * This saves the file ref in-memory. Reference is lost if the browser is refreshed.
     * @param {File} file
     */
    saveFile (file) {
        files[this.id] = file;
    }
}

export default Upload;
