<script setup>
import { ref } from 'vue';

import IconLoader from '@/components/Icons/Loader.vue';
import Observer from '@/components/Observer.vue';

const emit = defineEmits(['fetching', 'fetched']);
const props = defineProps({
    collection: {
        type: Object,
        required: true,
    },
});

const fetching = ref(false);

const loadMore = () => {
    if (!props.collection.links.next || fetching.value) {
        return;
    }

    fetching.value = true;
    emit('fetching');
    props.collection.nextCursor().finally(() => {
        fetching.value = false;
        emit('fetched');
    });
}
</script>

<template>
    <Observer v-if="collection.fetched" @intersected="loadMore">
        <div v-if="fetching && collection.fetched && collection.links.next">
            <slot>
                <div class="w-full py-2 flex items-center justify-center">
                    <span class="sr-only">Loading more</span>
                    <IconLoader class="size-10 opacity-70" />
                </div>
            </slot>
        </div>
    </Observer>
</template>
