<script setup>
import autosize from 'autosize';
import { inject, nextTick, onMounted, reactive, ref, watch } from 'vue';

import { collectionV2 } from '@/composables/resource';
import { debounce } from '@/utils';
import { inputProps, inputEmits } from './utils';

import Input from '@/components/Form/v1/Input.vue';
import DropdownMenuRoot from '@/components/DropdownMenu/DropdownMenuRoot.vue';
import DropdownMenuContent from '@/components/DropdownMenu/DropdownMenuContent.vue';
import DropdownMenuItem from '@/components/DropdownMenu/DropdownMenuItem.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';

const emit = defineEmits(inputEmits());

const props = defineProps(inputProps({
    rows: {
        default: 2,
    },
    maxRows: {
        default: 15,
    },
    disableUsernameSuggestion: {
        type: Boolean,
        default: false,
    },
    submitOnEnter: {
        type: Boolean,
        default: false,
    },
}));

const textarea = ref(null);
const activeSuggestion = ref(null);
const suggestionDropdown = ref(null);
const textbox = ref(null);
const form = inject('form');
form?.set(props.name, props.value);

const updateValue = (value) => {
    form?.update(props.name, value);
    emit('update:value', value);
    nextTick(() => autosize.update(textarea.value));
}

const users = collectionV2({
    url: '/api/users',
});

const suggestions = reactive({
    username: {
        placeholder: 'Search username',
        searching: false,
        debouncedSearch: null,
        data: [],
        reset() {
            users.reset();
            this.data = [];
        },
        search(value) {
            if (!value || !value.trim()) {
                this.reset();

                return;
            }

            users.setParams({
                query: value.trim(),
                per_page: 10,
            });

            users.fetch()
                .then(() => {
                    this.data = users.data.map((user) => {
                        return {
                            title: user.name,
                            subtitle: '@' + user.profile.username,
                            image: user.profile.avatar?.url,
                            value: user.profile.username,
                        };
                    });
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        onOpened(defaultValue) {
            this.debouncedSearch = debounce((value) => {
                this.search(value);
            }, 600);

            if (defaultValue) {
                this.search(defaultValue);
            }
        },
        onInput(value) {
            if (value) {
                this.searching = true;
            }

            this.debouncedSearch(value);
        },
        onClosed() {
            this.reset();
            this.searchUsername = null;
        }
    },
});

const maxHeight = props.maxRows * 24;

const insert = (text, position = null) => {
    console.log('insert', text, position);
    const el = textarea.value;
    const start = position ? position[0] : el.selectionStart;
    const end = position ? position[1] : el.selectionEnd;
    const value = el.value;

    el.value = value.slice(0, start) + text + value.slice(end);
    const newPosition = start + text.length;

    updateValue(el.value);
    el.selectionStart = el.selectionEnd = newPosition;

    // setTimeout(() => {
    //     el.focus();
    //     autosize.update(textarea.value);
    // }, 100);

    return [newPosition, newPosition];
}

const filterSuggestion = (words) => {
    if (props.disableUsernameSuggestion) {
        return;
    }

    const match = words.match(/@$/);

    if (match) {
        return openSuggestion('username');
    }
}

const handleInput = (e) => {
    const wordsBeforeCursor = e.target.value.slice(0, e.target.selectionStart).split(' ').pop();
    filterSuggestion(wordsBeforeCursor);

    updateValue(e.target.value);
}

const handleSuggestionInput = (e) => {
    const suggestion = suggestions[activeSuggestion.value];

    if (suggestion.onInput) {
        suggestion.onInput(e.target.value);
    }
}

const handleSuggestionSelected = (e, data) => {
    e.preventDefault();
    const input = textbox.value.querySelector('.suggestion-input');
    input.value = data.value;

    insert(input.value);
    closeSuggestion();
}

const openSuggestion = (key, defaultValue) => {
    const suggestion = suggestions[key] ?? null;

    if (!suggestion) {
        throw new Error(`Suggestion key [${key}] not found`);
    }

    activeSuggestion.value = key;

    nextTick(() => {
        const input = textbox.value.querySelector('.suggestion-input');
        if (defaultValue) {
            input.value = defaultValue;
        }
        input.focus();
    });

    if (suggestion.onOpened) {
        suggestion.onOpened(defaultValue);
    }
}

const closeSuggestion = () => {
    const suggestion = suggestions[activeSuggestion.value];

    activeSuggestion.value = null;

    if (suggestion && suggestion.onClosed) {
        suggestion.onClosed();
    }

    nextTick(() => {
        textbox.value.querySelector('.textarea').focus();
    });
}

const onEnter = (e) => {
    if (!props.submitOnEnter) {
        return;
    }

    e.preventDefault();
    const form = e.target.form;
    form?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
}

onMounted(() => {
    textarea.value.value = props.value;
    nextTick(() => {
        autosize(textarea.value);
    });
});

defineExpose({
    focus(cursorPosition = null) {
        const el = textarea.value.querySelector('.textarea');

        if (cursorPosition) {
            el?.setSelectionRange(cursorPosition[0], cursorPosition[1]);
        }

        el?.focus();
    },
    input(value) {
        textarea.value.value = value;
        updateValue(e.target.value);
    },
    append(value) {
        const originalValue = textarea.value.value ?? '';
        const originalSelectionStart = textarea.value.selectionStart;
        const originalSelectionEnd = textarea.value.selectionEnd;
        this.input(originalValue + value);
        nextTick(() => {
            textarea.value.setSelectionRange(originalSelectionStart, originalSelectionEnd);
        });
    },
    insert(value, position = null) {
        return insert(value, position);
    }
});

watch(() => form?.get(props.name), (value) => {
    textarea.value.value = value || '';
});
</script>

<template>
    <div ref="textbox" class="textbox">
        <DropdownMenuRoot ref="suggestionDropdown" :open="activeSuggestion !== null" @update:open="closeSuggestion">
            <DropdownMenuTrigger as="div">
            </DropdownMenuTrigger>
            <DropdownMenuContent :side-offset="0" side="bottom" align="start">
                <form @submit.prevent>
                    <div class="px-2">
                        <Input class="suggestion-input" @input="handleSuggestionInput" :placeholder="suggestions[activeSuggestion]?.placeholder" />
                    </div>
                    <ul class="mt-2" v-if="activeSuggestion && suggestions[activeSuggestion].data.length > 0">
                        <li v-for="(data, i) in suggestions[activeSuggestion].data" :key="i">
                            <DropdownMenuItem as="button" type="button" @select="(e) => handleSuggestionSelected(e, data)">
                                <div class="">{{ data.title }}</div>
                                <div v-if="data.subtitle" class="opacity-70">{{ data.subtitle }}</div>
                            </DropdownMenuItem>
                        </li>
                    </ul>
                </form>
            </DropdownMenuContent>
        </DropdownMenuRoot>
        <textarea
            class="textarea"
            @input="handleInput"
            ref="textarea"
            :rows="rows"
            :placeholder="placeholder"
            :style="`max-height: ${maxHeight}px;`"
            :required="required"
            :disabled="disabled"
            :name="name"
            :id="id"
            @keydown.enter.exact="onEnter"
        ></textarea>
    </div>
</template>

<style lang="postcss">
.textbox {
    @apply w-full;
}
.textbox .textarea {
    @apply focus:outline-none block;
    background: unset;
    padding: 0;
    margin: 0;
    border: unset;
    box-shadow: unset;
    width: 100%;
    resize: none;

    &::placeholder {
        @apply opacity-50;
    }

    @focus {
        box-shadow: unset;
    }
}
.textbox .textarea:disabled {
    @apply cursor-not-allowed opacity-50;
}
</style>
