<script setup>
import feedbackImage from '@images/feedback.png';

import axios from '@/axios';

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';

import AppButton from '@/components/Button/Button.vue';
import Checkbox from '@/components/Form/v1/Checkbox.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';

const router = useRouter();
const store = useStore();

const hidden = ref(false);

const open = ref(true);

const close = () => {
    open.value = false;
    axios.patch('/api/me/popup-campaigns', {
        hidden: hidden.value,
        campaign_name: 'user_feedback',
    }).then(response => {
        store.state.user.popup_campaigns = response.data;
        store.saveUser();
    });
}

const takeSurvey = () => {
    close();
    window.open("https://forms.gle/VDCXZbwZ7fo3hABMA", "_blank");
};

onMounted(() => {
    if (!store.state.user.popup_campaigns.user_feedback.viewed_at) {
        axios.patch('/api/me/popup-campaigns', {
            viewed: true,
            campaign_name: 'user_feedback',
        }).then(response => {
            store.state.user.popup_campaigns = response.data;
            store.saveUser();
        });
    }
});
</script>

<template>
    <Modal :open="open" @update:open="close">
        <ModalTitle>
            We Value Your Feedback
        </ModalTitle>

        <div class="w-full h-[200px] mt-10">
            <img
                :src="feedbackImage"
                alt="Feedback"
                class="w-full h-full object-contain"
            >
        </div>

        <div class="mt-5">
            <p class="font-bold text-center my-3">
                We Value Your Feedback
            </p>
            <p>
                We’d love to hear your thoughts. Kindly share your thoughts on our recent features and, help us serve you better. It only takes 2 minutes.
            </p>
        </div>

        <div class="mt-5">
            <Checkbox id="hide-again" v-model="hidden" class="flex items-center gap-2">
                <label class="text-sm" for="hide-again">Don't show this again</label>
            </Checkbox>
        </div>

        <div class="w-full mt-10">
            <AppButton @click="takeSurvey" color="primary" full capitalize>
                Take the survey now
            </AppButton>
        </div>
    </Modal>
</template>
