const tables = [
    'draft',
    'pending_message',
    'upload',
];

export const models = () => {
    const files = import.meta.glob('./*.js', { eager: true });

    return Object.keys(files)
        .filter(file => tables.includes(file.replace(/(\.\/|\.js)/g, '')))
        .map(file => files[file].default);
};


export const getModelFromTable = (table) => {
    return models().find((model) => model.getTableName() === table);
};

if (import.meta.env.DEV && typeof window !== 'undefined') {
    // for debugging via console only
    window.models = models();
}
