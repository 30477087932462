import Mention from "@/components/Mention.vue";
import { h } from "vue";
import { RouterLink } from "vue-router";

export const uuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const toCamelCase = (str) => {
    return str.replace(/([-_])([a-z])/g, (_, __, letter) => letter.toUpperCase());
};

export const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const htmlEntities = (str) => {
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
}

export const getParser = (name) => {
    const parsers = getParsers();

    return parsers.find((parser) => parser.name == name);
}

export const getParsers = () => {
    const id = uuid();

    return [
        {
            id,
            name: 'bold',
            pattern: /\*(.+?)\*(?!\*)/g,
            replacement: `<bold-${id}>$1</bold-${id}>`,
            get allowNested() {
                return true;
            },
            render(tokens) {
                return h('b', {}, tokens);
            },
        },
        {
            id,
            name: 'italic',
            pattern: /\_(.+?)\_(?!\_)/g,
            replacement: `<italic-${id}>$1</italic-${id}>`,
            get allowNested() {
                return true;
            },
            render(tokens) {
                return h('i', {}, tokens);
            },
        },
        {
            id,
            name: 'line-break',
            pattern: /\n/g,
            replacement: `<line-break-${id}></line-break-${id}>`,
            get allowNested() {
                return false;
            },
            render() {
                return h('br');
            },
        },
        {
            id,
            name: 'mention',
            pattern: /\B(@[\w_-]+)\b/g,
            replacement: `<mention-${id}>$1</mention-${id}>`,
            validMentions: [],
            get allowNested() {
                return false;
            },
            render(mention) {
                return h(Mention, {
                    text: mention,
                    validMentions: this.validMentions,
                });
            },
        },
        {
            id,
            name: 'link',
            pattern: /(https?:\/\/[^\s<]+[a-zA-Z0-9/])/g,
            replacement: `<link-${id}>$1</link-${id}>`,
            get allowNested() {
                return false;
            },
            render(link) {
                const originalLink = link;

                if (!link.startsWith('http://') && !link.startsWith('https://')) {
                    link = 'https://' + link;
                }

                try {
                    const url = new URL(link);
                    const baseUrl = new URL(import.meta.env.VITE_APP_URL);
                } catch (error) {
                    console.error(error);
                    console.log(originalLink);

                    return h('a', {
                        href: originalLink,
                        target: '_blank',
                        rel: 'noopener noreferrer',
                    }, originalLink);
                }

                const url = new URL(link);
                const baseUrl = new URL(import.meta.env.VITE_APP_URL);

                if (url.hostname == baseUrl.hostname) {
                    return h(RouterLink, {
                        to: url.pathname,
                    }, () => url.hostname + (url.pathname == '/' ? '' : url.pathname));
                }

                return h('a', {
                    href: originalLink,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                }, originalLink);
            },
        },
    ];
};

export const getParsedTokens = (content, parsers, allowNested = true) => {
    const tokenRegex = new RegExp(parsers.map((parser) => `(<${parser.name}-${parser.id}>.*?</${parser.name}-${parser.id}>)`).join('|'), 'g');
    const tokens = content.split(tokenRegex);

    return tokens.map((token) => {
        if (!token) return null;

        const parser = parsers.find((parser) => token.startsWith(`<${parser.name}-${parser.id}>`) && token.endsWith(`</${parser.name}-${parser.id}>`));

        if (!parser) {
            return h('span', {}, token);
        }

        const text = token.replace(`<${parser.name}-${parser.id}>`, '').replace(`</${parser.name}-${parser.id}>`, '');

        if (!parser.allowNested || !allowNested) {
            return parser.render(text);
        }

        return parser.render(getParsedTokens(text, parsers.filter((p) => p.name != parser.name), false));
    }).filter(Boolean);
}

export const parseContent = ({
    content,
    validMentions = [],
    excludeParsers = [],
    onlyParsers = [],
}) => {
    content = content + '';

    const parsers = getParsers().filter((parser) => {
        if (excludeParsers.includes(parser.name)) return false;
        if (onlyParsers.length > 0 && !onlyParsers.includes(parser.name)) return false;

        return true;
    }).map((parser) => {
        if (parser.name == 'mention') {
            parser.validMentions = validMentions;
        }

        return parser;
    });

    parsers.forEach((parser) => {
        content = content.replace(parser.pattern, parser.replacement);
    });

    return getParsedTokens(content, parsers);
};
