<script setup>
import { DialogRoot, DialogPortal, DialogOverlay, DialogContent } from 'radix-vue';
import { ref } from 'vue';

const open = defineModel('open', {
    type: Boolean,
    default: false,
});

const deltaY = ref(null);
const opacity = ref(null);
const content = ref(null);

const handlePan = (e) => {
    deltaY.value = Math.min(Math.max(e.deltaY, 0), content.value.$el.clientHeight);
    opacity.value = 0.1 - (deltaY.value / 2000);

    if (e.isFinal) {
        if (e.deltaY > 30) {
            open.value = false;
            setTimeout(() => {
                deltaY.value = null;
                opacity.value = null;
            }, 100);
        } else {
            deltaY.value = null;
            opacity.value = null;
        }
    }
};
</script>

<template>
    <div>
        <DialogRoot :open="open" @update:open="open = $event">
            <DialogPortal>
                <DialogOverlay
                    :style="{ '--tw-bg-opacity': opacity }"
                    class="
                        bg-black bg-opacity-10 data-[state=open]:animate-overlayShow data-[state=closed]:animate-overlayHide
                        fixed inset-0 z-50
                    " />
                <DialogContent
                    @openAutoFocus="(e) => e.preventDefault()"
                    class="
                        fixed z-[100] max-md:bottom-0 max-md:data-[state=open]:animate-in max-md:data-[state=closed]:animate-out
                        max-md:data-[state=open]:slide-in-from-bottom-[100%] max-md:data-[state=closed]:slide-out-to-bottom-[100%]
                        md:top-[50%] md:left-[50%]
                        max-h-[80vh] md:max-h-[85vh] max-md:w-full md:max-w-[90vw] max-w-[450px] md:w-[450px]
                        md:translate-x-[-50%] md:translate-y-[-50%]
                        focus:outline-none bg-white text-white-foreground
                        rounded-t-3xl
                    "
                    :style="{ transform: deltaY ? `translateY(${deltaY}px)` : undefined }"
                    ref="content"
                    v-hammer:pan-v="handlePan"
                    >
                    <div class="md:hidden rounded-t-[inherit] w-full h-10 flex items-center justify-center">
                        <div class="h-1 w-10 bg-white-foreground rounded-full"></div>
                    </div>
                    <slot></slot>
                </DialogContent>
            </DialogPortal>
        </DialogRoot>
    </div>
</template>
