<script setup>
import anon from '@images/anonymous-user.jpg';
import { ref } from 'vue';

import { abbreviateNumber } from '@/utils';
import { useReaction } from '@/utils/reaction';
import { useSavePost, useSharePost } from '@/composables/post';

import Avatar from '@/components/Avatar.vue';

const player = ref(null);
const playState = ref(null);
let clickedTimeout = null;

const props = defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    post: {
        type: Object,
        required: true,
    },
});

const onClick = (e) => {
    e.stopPropagation();

    if (!props.active) {
        return;
    }

    clearTimeout(clickedTimeout);
    if (player.value.paused) {
        playState.value = 'play';
    } else {
        playState.value = 'pause';
    }
    player.value.togglePlay();

    clickedTimeout = setTimeout(() => {
        playState.value = null;
    }, 1000);
}

const reaction = useReaction(props.post.id, 'post', {
    total_likes: props.post.positive_reactions_count,
    user_reaction: props.post.user_reaction ? props.post.user_reaction.type : null,
});

const savePost = useSavePost(props.post);
const sharePost = useSharePost(props.post);
</script>
<template>
    <app-video-player ref="player" class="relative" @click="onClick" :play="active">
        <div
            v-if="playState"
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 bg-[#000000]/50 rounded-full"
        >
            <component :is="playState === 'pause' ? 'icon-pause' : 'icon-play'" class="size-12" />
        </div>

        <div class="absolute bottom-6 left-0 w-full" ref="infoContainer">
            <div class="flex items-end justify-between mb-1">
                <div class="px-2 md:px-4 min-w-0 block text-left">
                    <button class="flex gap-0.5 items-center" aria-label="View channel" type="button" @click.stop.prevent="$router.push(`/channels/${post.channel.handle}`)">
                        <component :is="post.channel.is_public ? 'icon-public-channel' : 'icon-private-channel'" class="size-4 shrink-0" />
                        <div class="text-sm font-semibold truncate">{{ post.channel.name }}</div>
                    </button>
                    <button class="mt-1 flex gap-1 items-center" aria-label="View author" type="button" @click.stop.prevent="$router.push(`/profiles/${post.user.profile.username}`)">
                        <Avatar size="sm" :fallback-from="post.user ? post.user.name : null" :src="post.user ? post.user.profile.avatar?.url : anon" />
                        <div class="truncate">
                            <span class="text-sm font-semibold">
                                {{ post.user ? post.user.name : 'Anonymous' }}
                            </span>
                            <span v-if="post.user" class="text-sm opacity-80">
                                @{{ post.user.profile.username }}
                            </span>
                        </div>
                    </button>
                    <button class="text-sm block text-left" aria-label="View post" type="button" @click.stop.prevent="$router.push(`/posts/${post.id}`)">
                        <app-line-clamp :lines="2" hide-read-more>
                            {{ post.title || post.content }}
                        </app-line-clamp>
                        <span v-if="post.files.length > 1" class="text-xs opacity-80">
                            Tap to watch all {{ post.files.length }} videos
                        </span>
                    </button>
                </div>

                <div class="flex flex-col justify-center items-center shrink-0 py-6">
                    <app-icon-button
                        @click.stop.prevent="() => reaction.like()"
                        :selected="reaction.liked"
                        aria-label="Upvote"
                        :text="abbreviateNumber(reaction.total_likes)"
                        class="relative"
                        color="raw-white"
                        icon="thumb-up"
                        lg
                        />
                    <app-icon-button
                        @click.stop.prevent="() => reaction.dislike()"
                        :selected="reaction.disliked"
                        aria-label="Downvote"
                        color="raw-white"
                        icon="thumb-down"
                        lg />
                    <app-icon-button
                        @click.stop.prevent="$router.push(`/posts/${post.id}`)"
                        aria-label="Comment"
                        :text="abbreviateNumber(post.comments_count)"
                        color="raw-white"
                        icon="comment-text-multiple"
                        lg />
                    <app-icon-button
                        @click.stop.prevent="savePost.save()"
                        :selected="savePost.state.saved"
                        aria-label="Save Post"
                        color="raw-white"
                        icon="bookmark"
                        lg />

                    <app-icon-button
                        @click.stop.prevent="sharePost.share()"
                        v-if="sharePost.state.supported"
                        aria-label="Share Post"
                        color="raw-white"
                        icon="share-variant"
                        lg />
                    <button
                        aria-label="View channel"
                        class="block mt-8"
                        type="button"
                        @click.stop.prevent="$router.push(`/channels/${post.channel.handle}`)"
                    >
                        <Avatar :rounded-full="false" :src="post.channel.image?.url || '/channel-placeholder.jpg'" />
                    </button>
                </div>
            </div>
        </div>
        <div class="px-2 md:px-4 absolute bottom-0 left-0 w-full">
            <app-media-control-seek />
        </div>
    </app-video-player>
</template>
