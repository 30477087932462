<script setup>
import { onBeforeMount, ref } from 'vue';

import Upload from '@/db/models/upload';

const props = defineProps({
    attachment: {
        type: Object,
        required: true,
    },
});

const upload = ref(null);
const uploadUrl = ref(null);

const ready = ref(true);

onBeforeMount(() => {
    if (!props.attachment.__client?.upload_id) {
        return;
    }

    ready.value = false;

    Upload.query().find(props.attachment.__client.upload_id).then((result) => {
        upload.value = result;

        if (upload.value?.file) {
            uploadUrl.value = URL.createObjectURL(upload.value.file);
        }
    }).finally(() => {
        ready.value = true;
    });
});
</script>

<template>
    <div class="size-full rounded-md shrink-0 border border-white-foreground/20 flex items-center justify-center relative" v-if="ready">
        <img v-if="attachment.type == 1" :src="uploadUrl || attachment.url" class="max-w-full max-h-full w-full h-auto object-contain" />
        <video v-else :src="uploadUrl || attachment.url" class="max-w-full max-h-full w-full h-auto object-contain" />

        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <icon-play class="size-6" />
        </div>
    </div>
</template>
