
import axios from 'axios';
import echo from '@/plugins/echo';

const http = axios.create();

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
http.defaults.withCredentials = true;
http.defaults.withXSRFToken = true;

http.interceptors.request.use((config) => {
    config.headers['X-Socket-Id'] = echo.socketId();

    return config;
});

export default http;
