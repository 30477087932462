<script setup>
import { computed, markRaw, onMounted, ref, provide, reactive } from "vue";
import { register } from 'swiper/element/bundle';

import Dialog from '@/components/Dialog/v1/Dialog.vue';
import DialogContent from '@/components/Dialog/v1/DialogContent.vue';
import DialogOverlay from '@/components/Dialog/v1/DialogOverlay.vue';
import DialogTitle from '@/components/Dialog/v1/DialogTitle.vue';
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import IconCancelX from '@/components/Icons/CancelX.vue';
import IconDownload from '@/components/Icons/Download.vue';
import IconDownloadMultiple from '@/components/Icons/DownloadMultiple.vue';
import IconMoreVertical from '@/components/Icons/MoreVertical.vue';
import ImageView from '@/views/files/_helpers/ImageView.vue';
import VideoView from '@/views/files/_helpers/VideoView.vue';
import PdfView from "@/views/files/_helpers/PdfView.vue";

register();

const opened = ref(false);
const activeFile = ref(null);
const imagePreloaded = ref(false);
const swiper = ref(null);
const activeIndex = ref(0);
const fullScreen = ref(false);
const props = defineProps({
    id: {
        required: true,
        type: String
    },
    files: {
        required: true,
        type: Array
    },
});

const gallery = reactive({
    opened,
    imagePreloaded,
    activeIndex,
    activeFile,
    fullScreen,
    toggleFullScreen: () => fullScreen.value = !fullScreen.value,
    download: (index) => {
        const file = props.files[index];
        const a = document.createElement('a');
        a.href = file.url;
        a.setAttribute('download', '');
        a.click();
    },
    downloadAll: () => {
        props.files.forEach((file) => {
            const a = document.createElement('a');
            a.href = file.url;
            a.setAttribute('download', '');
            a.click();
        });
    },
    open: (fileId) => {
        opened.value = true;
        activeFile.value = fileId;
        fullScreen.value = false;
        activeIndex.value = props.files.findIndex((file) => file.id === fileId);
    },
    close: () => {
        opened.value = false;
        activeFile.value = null;
        activeIndex.value = 0;
    }
});

const initialSlide = computed(() => {
    return props.files.findIndex((file) => file.id === activeFile.value);
});

const onSlideChange = (event) => {
    if (!event.target.swiper) {
        return;
    }

    activeIndex.value = event.target.swiper.activeIndex;
    activeFile.value = props.files[activeIndex.value].id;
}

const updateOpen = (e) => {
    opened.value = e;

    if (!e) {
        activeFile.value = null;
    }
}

const actions = computed(() => {
    return [
        {
            label: 'Save',
            icon: markRaw(IconDownload),
            onSelect: () => gallery.download(activeIndex.value)
        },
        {
            label: 'Save All Files',
            icon: markRaw(IconDownloadMultiple),
            onSelect: () => gallery.downloadAll(),
            hide: () => props.files.length === 1
        },
    ];
});

const onFullScreenTogglerTap = (e) => {
    const parentContainsGestureClass = e.target.closest('.__ignore-gallery-fullscreen');
    if (e.target.classList.contains('__ignore-gallery-fullscreen') || parentContainsGestureClass) {
        return;
    }

    fullScreen.value = !fullScreen.value;
}

onMounted(() => {
    // preloadImages(props.files);
});

defineExpose(gallery);
provide('gallery', gallery);
</script>

<template>
    <div>
        <Dialog :open="opened" @update:open="updateOpen" :id="'gallery-' + id">
            <DialogOverlay no-backdrop-blur />
            <DialogTitle visually-hidden>
                File Gallery
            </DialogTitle>
            <DialogContent
                class="
                    h-full w-full md:h-[600px] lg:h-[700px] md:w-[70%] lg:w-[800px]
                    bg-[#000000] text-[#ffffff] flex flex-col
                    data-[state=closed]:fade-out
                "
                disable-animation
            >
                <div class="h-full w-full relative">
                    <div class="
                        w-full flex items-center absolute top-0
                        left-0 h-[60px] px-4 py-2 z-50 transition-all duration-300
                    "
                        :class="{
                            'top-[-70px] opacity-0': fullScreen
                        }"
                    >
                        <button @click="gallery.close()"
                            type="button"
                            aria-label="Close gallery"
                            class="rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff] active:text-primary active:scale-105 transition-all duration-300"
                        >
                            <IconCancelX class="size-5" />
                        </button>
                        <div class="grow"></div>

                        <DropdownMenu content-align="end" :items="actions">
                            <DropdownMenuTrigger class="
                                rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff]
                                hover:text-primary hover:scale-105
                                active:text-primary active:scale-105
                                transition-all duration-300
                            ">
                                <IconMoreVertical class="size-6" />
                                <span class="sr-only">
                                    menu
                                </span>
                            </DropdownMenuTrigger>
                        </DropdownMenu>
                    </div>

                    <swiper-container
                        class="h-full w-full"
                        slides-per-view="1"
                        ref="swiper"
                        :initial-slide="initialSlide"
                        @swiperslidechange="onSlideChange"
                        no-swiping-class="__ignore-swipe-gestures"
                    >
                        <swiper-slide v-for="file in files" :key="file.id">
                            <div class="h-full w-full" v-hammer:tap="(e) => onFullScreenTogglerTap(e)">
                                <ImageView v-if="file.type === 1" :image="file" />
                                <VideoView v-else-if="file.type === 2" :video="file" />
                                <PdfView v-else-if="file.type === 3" :pdf="file" />
                            </div>
                        </swiper-slide>
                    </swiper-container>
                    <div
                        class="absolute bottom-[80px] left-0 right-0 w-fit mx-auto flex items-center justify-center transition-all duration-300 z-50"
                        :class="{
                            'opacity-0': fullScreen
                        }"
                    >
                        <div class="bg-[#1f1f1f] text-[#ffffff] text-sm rounded-full px-2 py-1 select-none">
                            {{ activeIndex + 1 }} / {{ files.length }}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    </div>
</template>
