<script setup>
import { computed } from 'vue';

import { getFriendlyTime, isSameDay, isSameMinute } from '@/utils';

import ChatMessage from '@/components/Secondary/Messaging/ChatMessage.vue';

const props = defineProps({
    messages: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const sortedMessages = computed(() => {
    return props.messages.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
    });
});

const messagesFromMe = computed(() => {
    return sortedMessages.value.filter(message => message.from_me);
});

const messagesToMe = computed(() => {
    return sortedMessages.value.filter(message => !message.from_me);
});

const lastMessageFromMe = computed(() => {
    return messagesFromMe.value[messagesFromMe.value.length - 1];
});

const lastMessageToMe = computed(() => {
    return messagesToMe.value[messagesToMe.value.length - 1];
});
</script>
<template>
    <div>
        <slot name="top"></slot>
        <ul class="flex flex-col -mx-4" v-if="sortedMessages.length > 0">
            <template v-for="(message, index) in sortedMessages" :key="index">
                <li v-if="index === 0 || !isSameDay(message.created_at, sortedMessages[index - 1].created_at)" class="w-fit mx-auto font-medium bg-gray text-muted rounded-full px-4 py-1 text-xs my-6">
                    {{ getFriendlyTime(message.created_at, { withTime: false, todayString: 'Today' }) }}
                </li>
                <li class="px-4" :data-message-id="message.id">
                    <ChatMessage
                        :first="index === 0"
                        :first-of-day="index === 0 || !isSameDay(message.created_at, sortedMessages[index - 1].created_at)"
                        :last-of-day="index === sortedMessages.length - 1 || !isSameDay(message.created_at, sortedMessages[index + 1].created_at)"
                        :first-of-minute="index === 0 || !isSameMinute(message.created_at, sortedMessages[index - 1].created_at)"
                        :last-of-minute="!sortedMessages[index + 1] || !isSameMinute(message.created_at, sortedMessages[index + 1].created_at)"
                        :last="message.from_me ? lastMessageFromMe.id == message.id : lastMessageToMe.id == message.id"
                        :message="message"
                    />
                </li>
            </template>
        </ul>
        <slot name="bottom"></slot>
    </div>
</template>
