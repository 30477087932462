<script setup>
import { useConversationCollection } from '@/composables/chat';

import { onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import ConversationList from '@/components/Secondary/Messaging/ConversationList.vue';
import StartChatModal from '@/components/Secondary/Messaging/StartChatModal.vue';

const conversations = useConversationCollection({ filters: { status: 'accepted' } });
const pendingConversations = useConversationCollection({ filters: { status: 'pending' } });
const route = useRoute();
const showStartChat = ref(false);
const showPendingConversations = ref(route.name === 'conversations' && route.query.filter === 'requests');

const togglePendingConversations = () => {
    showPendingConversations.value = !showPendingConversations.value;
};

onBeforeMount(() => {
    conversations.fetchOnce();
});

watch(() => showPendingConversations.value, (value) => {
    if (value) {
        pendingConversations.fetchOnce();
    }
}, { immediate: true });
</script>

<template>
    <div class="flex h-screen grow min-w-0 lg:border-r border-white-foreground/10">
        <head-title value="Chats" />
        <app-layout
            v-if="$store.state.deviceType !== 'mobile' || $route.name === 'conversations'"
            class="w-full h-screen md:w-[300px] lg:w-[480px] md:border-r border-white-foreground/10">
            <app-layout-header hide-border>
                <div class="flex items-center w-full h-full">
                    <h2 class="text-xl font-semibold">Chats</h2>
                    <div class="grow"></div>
                    <button @click="togglePendingConversations"
                        type="button"
                        :class="{ 'bg-primary/20': showPendingConversations }"
                        class="text-left bg-gray text-gray-foreground px-4 py-2 rounded-full flex items-center gap-2">
                        <icon-check v-if="showPendingConversations" class="size-5" />
                        <!-- <icon-square-rounded-badge v-else-if="pendingConversations.data.length > 0" class="size-5 text-primary" /> -->
                        <icon-square-shape-rounded-plus v-else class="size-5" />
                        <span class="text-sm">Requests</span>
                    </button>
                    <div class="ml-auto -mr-4 max-md:hidden">
                        <app-icon-button icon="message-plus" @click="showStartChat = true" />
                    </div>
                </div>
            </app-layout-header>

            <app-layout-body>
                <div v-if="showPendingConversations" class="px-4 py-4 text-center text-sm text-muted">
                    Chat requests from people outside your network
                </div>
                <ConversationList :conversations="showPendingConversations ? pendingConversations : conversations" />
                <InfiniteScrollObserver :collection="showPendingConversations ? pendingConversations : conversations" :key="showPendingConversations ? 'pending' : 'conversations'" />
            </app-layout-body>
            <StartChatModal :open="showStartChat" @update:open="showStartChat = $event" />

            <div class="fixed bottom-[80px] right-4 md:hidden">
                <app-icon-button selected icon="message-plus" @click="showStartChat = true" button-style="filled" />
            </div>
        </app-layout>
        <div class="grow min-w-0">
            <router-view v-slot="{ Component, route }">
                <component :is="Component" :key="route.params.id || undefined" />
            </router-view>
        </div>
    </div>
</template>
