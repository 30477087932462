import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export const renderer = (views) => {
    const router = useRouter();
    const route = useRoute();
    views = Array.isArray(views) ? views : [views];

    const state = reactive({
        activeView: null,
        views,
        setActiveView(key) {
            if (key === this.activeView?.key) {
                return;
            }

            const view = this.views.filter(view => view.key === key)[0] ?? null;

            if (!view) {
                throw Error(`View [${view}] does not exist`);
            }

            this.activeView = view;

            if (key !== this.views[0].key) {
                // if it is not the first view, add the view query param
                router.replace({
                    query: {
                        ...route.query,
                        view: key,
                    }
                });
            } else if (route.query.view) {
                // if it is the first view, remove the view query param
                router.replace({
                    query: {
                        ...route.query,
                        view: undefined,
                    }
                });
            }


            if ('onActive' in view) {
                this.activeView.onActive();
            }
        }
    });

    onMounted(() => {
        state.setActiveView(route.query.view || state.views[0].key);
    });

    return state;
};
