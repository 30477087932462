<script setup>
import { computed } from 'vue';
import { useRoute, RouterLink, RouterView } from 'vue-router';

import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import Search from '@/components/Search.vue';

const props = defineProps({
    pageData: {
        type: Object,
        required: false,
    }
});

const route = useRoute();

const searchTypes = [
    {
        name: 'Post',
        type: 'post'
    },
    {
        name: 'Channel',
        type: 'channel'
    },
    {
        name: 'Question',
        type: 'question'
    },
    {
        name: 'People',
        type: 'user',
    },
    {
        name: 'Information Hub',
        type: 'information-hub',
    },
]

const getType = computed(() => {
    return searchTypes.filter((searchType) => route.name === 'search.' + searchType.type)[0].type;
})
</script>

<style lang="postcss">
    .search-result [data-highlighted="true"] {
        @apply bg-primary/20;
    }
    body[data-dark-mode="true"] .search-result [data-highlighted="true"] {
        @apply bg-primary/40;
    }
</style>

<template>
    <Content class="search">
        <Header with-back-button>
            <HeaderTitle>
                Search
            </HeaderTitle>

            <Search :key="route.query" class="ml-auto" :placeholder="`Search on Corperland`" :type="getType" :default-value="route.query.query" />
        </Header>

        <div class="bg-white text-white-foreground py-3 px-4">
            <p>
                Search Results for <span class="font-semibold">{{ route.query.query }}</span>
            </p>
            <ul class="flex justify-start items-center gap-2 py-3 overflow-x-auto">
                <li v-for="searchType in searchTypes">
                    <RouterLink
                        class="text-sm
                        flex px-3 py-1 rounded-full font-medium transition-all
                        bg-transparent shadow-[inset_0_0_0_1px] shadow-black/20
                        hover:shadow-[inset_0_0_0_2px] hover:shadow-primary/40
                        data-[active=true]:bg-primary data-[active=true]:text-primary-foreground
                        data-[active=true]:shadow-[inset_0_0_0_1px] data-[active=true]:shadow-primary/20
                        "
                        :data-active="route.name === 'search.' + searchType.type"
                        :to="{
                            name: 'search.' + searchType.type,
                            query: {
                                query: route.query.query,
                            },
                            replace: true,
                        }"
                    >
                        {{ searchType.name }}
                    </RouterLink>
                </li>
            </ul>
        </div>

        <div class="search-result">
            <RouterView v-slot="{ Component }">
                <KeepAlive>
                    <Component :is="Component" :key="route.query" />
                </KeepAlive>
            </RouterView>
        </div>
    </Content>
</template>
