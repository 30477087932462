import { markRaw, onMounted, reactive, ref } from 'vue';
import { collectionV2 } from '@/composables/resource';
import { useStore } from '@/store';
import { mem_store } from '@/utils/cache';
import { usePostListener } from '@/composables/listeners';
import { useEmitter } from '@/plugins/emitter';

import UserConnectSuggestions from '@/components/User/UserConnectSuggestions.vue';

export const useMyFeed = () => {
    const store = useStore();
    const emitter = useEmitter();
    const key = (store.state.user?.id ?? '') + ':feed';
    const cache = mem_store('feed');

    const feed = cache.remember(key, () => {

        const posts = collectionV2({
            url: '/api/posts',
            params: {
                order_by: 'top',
            },
            transformCb: (data) => {
                return injectSlot(data);
            }
        });

        const suggestions = collectionV2({
            url: '/api/user-connect-suggestions',
        });

        const injectSlot = (data) => {
            const suggestionInjected = posts.data.find(post => post.__client_meta?.injected && post.type === 'suggestion');

            if (!suggestionInjected) {
                const start = 4;

                data.splice(start, 0, {
                    type: 'suggestion',
                    component: markRaw(UserConnectSuggestions),
                    props: {
                        collection: suggestions,
                    },
                    __client_meta: {
                        injected: true,
                    }
                });
            }

            return data;
        }

        const postListener = usePostListener({
            getPost: (id) => {
                return posts.data.filter((p) => p.type !== 'suggestion').find(post => post.id === id);
            },
            getPosts: () => {
                return posts.data.filter((p) => p.type !== 'suggestion');
            },
            postDeletedCb: (post) => {
                setTimeout(() => {
                    posts.delete(post.id);
                }, 5000);
            },
            postHiddenCb: (data) => {
                setTimeout(() => {
                    const post = posts.find(post => post.id === data.id);

                    if (post.user.id !== store.state.user.id) {
                        setTimeout(() => {
                            posts.delete(post.id);
                        }, 5000);
                    }
                }, 5000);
            },
        });

        const onPostPublished = (post) => {
            posts.unshift(post);
        }

        onMounted(() => {
            postListener.on();

            emitter.on('.post:published', onPostPublished);

            posts.fetchOnce();
            suggestions.fetchOnce();
        });

        return reactive({
            posts,
            suggestions,
        });
    });

    return feed;
}
