<script setup>
import { useStore } from '@/store';
import { computed } from 'vue';

const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    validMentions: {
        type: Array,
    },
});

const store = useStore();
const specialUsernames = ['everyone'];

const username = computed(() => {
    return props.text.split('@')[1];
});

const isSpecialUsername = computed(() => {
    return specialUsernames.includes(username.value);
});

const isValidMention = computed(() => {
    if (!props.validMentions || isSpecialUsername.value) {
        return true;
    }

    return props.validMentions.includes(username.value);
});

const getLink = computed(() => {
    if (isSpecialUsername.value) {
        return null;
    }

    return isValidMention.value ? `/profiles/${username.value}` : null;
});

const getClasses = computed(() => {
    if (!isValidMention.value) {
        return '';
    }

    const stack = ['px-1 rounded-md py-0.5'];

    if (isSpecialUsername.value || username.value === store.state.user?.profile?.username) {
        stack.push('mention-alt-color');
    } else {
        stack.push('mention-color');
    }

    return stack.join(' ');
});
</script>

<template>
    <component
        :is="getLink ? 'router-link' : 'span'"
        :to="getLink ? getLink : undefined"
        :class="getClasses">
        {{ text }}
    </component>
</template>
