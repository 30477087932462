<script setup>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { scrollToBottom } from '@/utils/el';
import { useConversationResource, useMessageManager } from '@/composables/chat';
import { uploads } from '@/services/upload';

import ChatBox from '@/components/Secondary/Messaging/ChatBox.vue';
import ChatGate from '@/components/Secondary/Messaging/ChatGate.vue';
import ChatTitle from '@/components/Secondary/Messaging/ChatTitle.vue';
import ChatIntro from '@/components/Secondary/Messaging/ChatIntro.vue';
import ChatMessages from '@/components/Secondary/Messaging/ChatMessages.vue';

const route = useRoute();
const conversation = useConversationResource(route.params.id);
const messageManager = useMessageManager(route.params.id);
const body = ref(null);
const ready = ref(false);
const parentMessage = ref(null);
const form = ref(null);

const loadState = computed(() => {
    if (!conversation.fetched) {
        return 'loading';
    }

    if (conversation.data) {
        return 'ready';
    }

    if (conversation.status === 404) {
        return 'missing';
    }

    return 'error';
});

provide('chatMessageResend', (id) => {
    messageManager.sendMessage(id);
});

provide('chatMessageReply', (message) => {
    parentMessage.value = message;
});

const cancelReply = () => {
    parentMessage.value = null;
}

const onMessageAdded = (message) => {
    // @TODO: check if the tab is active
    // if the tab is not active, then mark it when the tab is active
    if (!message.from_me) {
        messageManager.conversationSeen();
    }
}

onBeforeMount(() => {
    Promise.all([
        conversation.fetchOnce(),
        messageManager.initOnce(),
    ]).then(() => {
        ready.value = true;
        messageManager.messagesCollection.onItemAdded(onMessageAdded);

        nextTick(() => {
            if (route.name === 'conversation') {
                scrollToBottom(body.value.$el, false);
            }

            messageManager.conversationSeen();
        });
    });
});

onBeforeUnmount(() => {
    messageManager.messagesCollection.removeOnItemAdded(onMessageAdded);
});

const onSubmit = async (form) => {
    const data = form.getData();
    form.clearAll();
    parentMessage.value = null;

    const pendingMessage = await messageManager.createPendingMessage(data);

    await nextTick();

    if (route.name === 'conversation') {
        scrollToBottom(body.value.$el);
    }

    await messageManager.sendMessage(pendingMessage);

    return false;
}

watch(() => route.name, () => {
    if (route.name !== 'conversation') {
        return;
    }

    nextTick(() => {
        scrollToBottom(body.value.$el, false);
    });
});
</script>

<template>
    <div class="h-full flex min-w-0">
        <app-layout v-if="$route.name === 'conversation'" class="grow min-w-0">
            <app-layout-header>
                <app-top-nav>
                    <app-top-nav-back mobile-only />
                    <template v-if="loadState === 'ready'">
                        <ChatTitle :user="conversation.data.with_me ? $store.state.user : conversation.data.other_members[0].user" />
                        <div class="grow" />
                        <app-icon-button icon="information" @click="$router.push({ name: 'conversation.info' })" />
                    </template>
                    <app-top-nav-skeleton v-else-if="loadState === 'loading'" />
                </app-top-nav>
            </app-layout-header>
            <app-layout-body class="py-6 px-4 flex flex-col gap-6" ref="body">
                <template v-if="loadState === 'ready' && ready">
                    <ChatIntro :conversation="conversation.data" />
                    <ChatMessages :messages="messageManager.messages" />
                </template>
                <app-layout-skeleton v-else-if="loadState === 'loading'" />
                <app-error-default v-else-if="loadState === 'error'" />
                <app-error-not-found v-else-if="loadState === 'missing'" />
            </app-layout-body>
            <app-layout-footer v-if="loadState === 'ready'"
                :class="{
                    'border-t border-white-foreground/20': !!parentMessage,
                }">
                <ChatGate class="w-full" :conversation="conversation.data" @declined="$router.back()">
                    <div v-if="parentMessage" class="w-full mb-4 relative">
                        <div class="text-sm text-semibold">
                            {{ parentMessage.from_me ? 'Replying to yourself' : 'Replying to ' + parentMessage.member.user?.name }}
                        </div>
                        <div class="text-sm text-muted max-w-full truncate">
                            {{ parentMessage.content ? parentMessage.content : 'Attachment' }}
                        </div>

                        <div class="absolute top-0 right-0">
                            <app-icon-button
                                @click="cancelReply"
                                icon="cancel"
                                sm
                                aria-label="Cancel reply"
                            />
                        </div>
                    </div>

                    <app-form
                        :submit-callback="onSubmit"
                        draft-item-type="conversation_messages"
                        :draft-item-id="conversation.data.current_member.id"
                        ref="form"
                    >
                        <app-form-input type="hidden" name="parent_message.id" :value="parentMessage?.id" />
                        <ChatBox />
                    </app-form>
                </ChatGate>
            </app-layout-footer>
        </app-layout>

        <router-view v-slot="{ Component, route }">
            <div v-if="route.name !== 'conversation'" class="w-full lg:border-l border-white-foreground/20">
                <component :conversation="conversation.data" :is="Component" :key="route.params.id || undefined" />
            </div>
        </router-view>
    </div>
</template>
