import { onBeforeMount, onMounted, onUnmounted, ref } from "vue";

import axios from "@/axios";
import { collectionV2, resourceV2 } from "@/composables/resource";
import echo from "@/plugins/echo";
import { useStore } from "@/store";
import { mem_store } from "@/utils/cache";
import { deepMerge, deepCopy } from "@/utils";
import { useEmitter } from "@/plugins/emitter";
import { useRouter } from "vue-router";
import { useUtils as useUserUtils } from "@/composables/user";
import PendingMessage from "@/db/models/pending_message";
import { deleteUploads, sendMessage } from "@/services/messaging";

export const useConversationCollection = (params = {}) => {
    const store = useStore();
    const cache = mem_store('conversationCollection-' + store.state.user.id);
    const paramsKey = btoa(JSON.stringify(params));

    return cache.remember('conversations-' + paramsKey, ({ onEvicted }) => {
        let userEchoChannel;
        const emitter = useEmitter();

        const onConversationUpdated = (event) => {
            axios.get(`/api/conversations/${event.id}`)
                .then((response) => {
                    const fetchedConversation = response.data.data;
                    const status = params?.filters?.status;
                    const differentStatus = status && fetchedConversation.current_member.status !== status;
                    const conversation = conversations.find(event.id);

                    if (!conversation) {
                        if (differentStatus || fetchedConversation.current_member.deleted_at) {
                            return;
                        }
                        conversations.unshift(fetchedConversation);
                    } else {
                        if (differentStatus || fetchedConversation.current_member.deleted_at) {
                            conversations.delete(event.id);
                        } else {
                            deepMerge(conversation, fetchedConversation);
                        }
                    }
                });
        }

        const onConversationSeen = (event) => {
            const conversation = conversations.find(event.id);

            if (!conversation) {
                return;
            }

            conversation.total_unseen = 0;
        }

        const conversations = collectionV2({
            url: '/api/conversations',
            params: {
                per_page: 30,
                ...params
            },
            uniqueById: true,
        });

        onMounted(() => {
            if (!userEchoChannel) {
                userEchoChannel = echo.private(`users.${store.state.user.id}`);
                userEchoChannel.listen('.conversation:updated', onConversationUpdated);
                emitter.on('.conversation:updated', onConversationUpdated);
                emitter.on('.conversation:seen', onConversationSeen);
            }
        });

        onEvicted(() => {
            userEchoChannel?.stopListening('.conversation:updated', onConversationUpdated);
            emitter.off('.conversation:updated', onConversationUpdated);
        });

        return conversations;
    });
}

export const useConversationResource = (conversationId) => {
    const store = useStore();
    const cache = mem_store('conversationResource-' + store.state.user.id);

    return cache.remember(conversationId, ({ onEvicted }) => {
        const onConversationUpdated = (event) => {
            if (event.id !== conversationId) {
                return;
            }

            resource.fetch().then(() => {
                if (resource.data.current_member.deleted_at) {
                    cache.delete(conversationId);
                }
            });
        }

        const resource = resourceV2({
            url: `/api/conversations/${conversationId}`,
        });

        let echoChannel;
        const emitter = useEmitter();

        onMounted(() => {
            if (!echoChannel) {
                echoChannel = echo.private(`conversations.${conversationId}`);
                echoChannel.listen('.conversation:updated', onConversationUpdated);
                emitter.on('.conversation:updated', onConversationUpdated);
            }
        });

        onEvicted(() => {
            echoChannel?.stopListening('.conversation:updated', onConversationUpdated);
            emitter.off('.conversation:updated', onConversationUpdated);
        });

        return resource;
    });
}

export const useMessageCollection = (conversationId) => {
    const store = useStore();
    const cache = mem_store('conversationMessageCollection-' + store.state.user.id);

    return cache.remember(conversationId, ({ onEvicted }) => {
        const collection = collectionV2({
            url: `/api/conversations/${conversationId}/messages`,
            params: {
                per_page: 30,
            },
            uniqueById: true,
        });

        const onMessageCreated = (event) => {
            if (event.__client) {
                collection.unshift({
                    ...event,
                    __client: undefined,
                });
            } else {
                axios.get(`/api/conversations/${conversationId}/messages?message=${event.id}`)
                    .then((response) => {
                        const data = response.data.data[0]
                        collection.unshift(data);
                    });
            }
        }

        const onMessageDeleted = (event) => {
            collection.delete(event.id);
        }

        const onMessageDeletionCreated = (event) => {
            collection.delete(event.id);
        }

        const onConversationDeleted = (event) => {
            if (event.id !== conversationId) {
                return;
            }

            collection.fetch().then(() => {
                cache.delete(conversationId);
            });
        }

        const onMessageSeen = (event) => {
            setTimeout(() => {
                const message = collection.find(event.id);

                if (!message) {
                    return;
                }

                message.total_seen = event.total_seen;
            }, 5000);
        }

        let conversationChannel;
        let userChannel;
        const emitter = useEmitter();

        onBeforeMount(() => {
            if (!conversationChannel) {
                conversationChannel = echo.private(`conversations.${conversationId}`);
                userChannel = echo.private(`users.${store.state.user.id}`);

                conversationChannel.listen('.message:created', onMessageCreated);
                conversationChannel.listen('.message:deleted', onMessageDeleted);
                conversationChannel.listen('.message:seen', onMessageSeen);
                userChannel.listen('.message-deletion:created', onMessageDeletionCreated);
                userChannel.listen('.conversation:deleted', onConversationDeleted);

                emitter.on(`conversations.${conversationId}.message:deleted`, onMessageDeleted);
                emitter.on('.message-deletion:created', onMessageDeletionCreated);
                emitter.on('.conversation:deleted', onConversationDeleted);
            }
        });

        onEvicted(() => {
            conversationChannel?.stopListening('.message:created', onMessageCreated);
            emitter.off('.message:created', onMessageCreated);
        });

        return collection;
    });
}

export const useUtils = () => {
    const store = useStore();
    const router = useRouter();
    const userUtils = useUserUtils();

    return {
        getNewChatUser() {
            const user = localStorage?.getItem('newChatUser')

            if (!user) {
                return null;
            }

            return JSON.parse(user);
        },
        setNewChatUser(user) {
            localStorage?.setItem('newChatUser', JSON.stringify(user));
        },
        clearNewChatUser() {
            localStorage?.removeItem('newChatUser');
        },
        requiresChatRequest: (user) => {
            if (user.id === store.state.user.id) {
                return false;
            }

            const connection = userUtils.getConnection(user);

            return !connection || !connection.accepted;
        },
        openChat: (user) => {
            router.push({
                name: 'conversation.create',
                query: { id: user.id }
            });
        },
        getOtherUser: (conversation) => {
            if (conversation.with_me) {
                return store.state.user;
            }

            return conversation.other_members[0]?.user;
        },
        getMessagePronouns: (message) => {
            const name = message.from_me ? 'You' : message.member.user.first_name;
            const your = message.from_me ? message.member.user.first_name : 'your';
            const yours = message.from_me ? your + "'s" : your;

            return { name, your, yours };
        },
        getEventMessageContent: (message) => {
            const { name, yours } = useUtils().getMessagePronouns(message);

            if (message.content.type != 'conversation.accepted') {
                return `${name} accepted ${yours} request to chat`;
            }

            console.error('Unknown event message type', message.content.type);

            return '';
        }
    }
}

export const useMessageManager = (conversationId) => {
    const emitter = useEmitter();
    const messages = useMessageCollection(conversationId);
    const store = useStore();
    const cache = mem_store('messageManager-' + store.state.user.id);

    const state = cache.remember(conversationId, () => {
        return {
            mounted: false,
        }
    });

    const convertToServerMessage = (message) => {
        const parent_message = message.data.parent_message ? messages.find(message.data.parent_message.id) : null;

        return {
            id: message.id,
            type: 1,
            from_me: true,
            conversation_id: conversationId,
            created_at: message.createdAt,
            content: message.data.content,
            files: deepCopy(message.data.files),
            status: message.status,
            parent_message,
        }
    }

    return {
        async init() {
            await messages.fetchOnce();

            const pendingMessages = await PendingMessage.query().where({ conversationId, userId: store.state.user.id }).get();

            pendingMessages.forEach(async (pendingMessage) => {
                if (messages.find(pendingMessage.id)) {
                    return;
                }

                const message = convertToServerMessage(pendingMessage);

                messages.unshift(message);
            });
        },
        async initOnce() {
            if (state.mounted) {
                return;
            }

            await this.init();

            state.mounted = true;
        },
        get messages() {
            return messages.data;
        },
        get messagesCollection() {
            return messages;
        },
        get serverMessages() {
            return messages.data.filter((message) => !message.status || message.status === 'sent');
        },
        get lastServerMessage() {
            return this.serverMessages[this.serverMessages.length - 1];
        },
        async conversationSeen() {
            await axios.post(`/api/conversations/${conversationId}/seen`);

            emitter.emit('.conversation:seen', {
                id: conversationId,
            });

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.controller?.postMessage({
                    action: 'deleteNotification',
                    tag: conversationId,
                })
            }
        },
        async sendMessage(id) {
            const pendingMessage = typeof id === 'object' ? id : await PendingMessage.query().find(id);

            if (!pendingMessage) {
                throw new Error('Pending message not found');
            }

            let message = messages.find(pendingMessage.id);

            if (!message) {
                messages.unshift(convertToServerMessage(pendingMessage));
                message = messages.find(pendingMessage.id);
            }

            message.pendingMessage = pendingMessage;

            let serverMessage;

            try {
                serverMessage = await sendMessage(pendingMessage, null);
            } catch (error) {
                message.status = 'failed';

                return message;
            } finally {
                delete message.pendingMessage;
            }

            deepMerge(message, serverMessage);

            message.files = pendingMessage.data.files;
            message.status = 'sent';
            await deleteUploads(pendingMessage);
            await pendingMessage.delete();

            emitter.emit('.conversation:updated', {
                id: conversationId,
            });

            return message;
        },
        async createPendingMessage(data) {
            const pendingMessage = await PendingMessage.create({ data, userId: store.state.user.id, conversationId });
            messages.unshift(convertToServerMessage(pendingMessage));

            return pendingMessage;
        },
    }
}
