<script setup>
import { getFriendlyFileSize } from "@/utils";
import { computed } from "vue";

const props = defineProps({
    attachment: {
        type: Object,
        required: true,
    },
});

const isPdf = computed(() => {
    return props.attachment?.mime_type.endsWith('/pdf');
});

</script>

<template>
    <div class="h-auto w-40 rounded-md border-white-foreground/10 border flex gap-2 p-2 shrink-0">
        <component :is="isPdf ? 'icon-pdf-box' : 'icon-file-word'" class="size-6 shrink-0" :class="{
            'text-danger': isPdf,
            'text-info': !isPdf,
        }" />
        <div class="flex flex-col min-w-0">
            <span class="text-sm font-medium truncate">{{ attachment.original_name }}</span>
            <span class="text-xs text-muted">{{ getFriendlyFileSize(attachment.size) }}</span>
        </div>
    </div>
</template>
