<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useRegister } from '@/composables/actions';
import { getPlatoon, getReferralCode, getRegistrationSource } from '@/utils';
import { useToast } from '@/plugins/toast';

import AppButton from '@/components/Button/Button.vue';
import IconCancelBold from '@/components/Icons/CancelBold.vue';
import FormInput from '@/components/Form/v1/FormInput.vue';
import SocialButtons from '@/components/Auth/SocialButtons.vue';

const toast = useToast();
const route = useRoute();
const router = useRouter();
const register = useRegister({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    privacy_policy: false,
    platoon: null,
    referral_code: null,
    source: null,
});
const formEl = ref(null);
const topEl = ref(null);

const removePlatoon = () => {
    register.form.platoon = null;
    localStorage.removeItem('platoon');
    toast.info('Platoon removed. Your account will be created without a platoon.');
};

const removeReferralCode = () => {
    register.form.referral_code = null;
    localStorage.removeItem('referral_code');
    toast.info('Referral code removed. Your account will be created without a referral code.');
};

const showForm = () => {
    router.push({ query: { ...route.query, showForm: true }}).then(() => {
        setTimeout(() => {
            if (formEl.value) {
                formEl.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                formEl.value.querySelector('input[name="name"]')?.focus();
            }
        }, 100);
    });
};

const closeForm = () => {
    topEl.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    setTimeout(() => {
        router.replace({ query: { ...route.query, showForm: undefined }});
    }, 500);
};
onBeforeMount(() => {
    if (route.name === 'webinar.register') {
        localStorage.setItem('registration_source', 'webinar');
    }else{
        localStorage.setItem('registration_source', 'web');
    }
});

onMounted(() => {
    register.form.platoon = getPlatoon();
    register.form.referral_code = getReferralCode();
    register.form.source = getRegistrationSource();
});
</script>

<template>
    <div ref="topEl" class="mt-6 w-full">
        <head-title value="Webinar" />
        <template v-if="$route.name === 'webinar.register'">
            <div class="mx-auto max-w-[300px] max-h-[300px] overflow-hidden border-[20px] rounded-3xl">
                <img src="@images/webinar.jpeg" alt="Webinar" class="object-cover h-auto w-full">
            </div>
            <h1 class="text-center text-2xl font-semibold mt-4 capitalize">{{ $store.state.app.current_webinar }} webinar</h1>
            <div class="mt-4 mb-8 text-center">
                Learn how to navigate NYSC like a pro. Explore job opportunities during and after NYSC. Gain exclusive insights to prepare for life beyond school.
            </div>
        </template>
        <div v-if="register.form.platoon" class="my-2 flex gap-2 items-center">
            <div class="font-semibold">Platoon {{ register.form.platoon }}</div>
            <button aria-label="Remove platoon" type="button" @click="removePlatoon">
                <IconCancelBold class="size-4" />
            </button>
        </div>
        <div v-if="register.form.referral_code" class="my-2 flex gap-2 items-center">
            <div class="font-semibold">
                Referred by: <span class="text-primary">AMB {{ register.form.referral_code }}</span>
            </div>
            <button aria-label="Remove referral code" type="button" @click="removeReferralCode">
                <IconCancelBold class="size-4" />
            </button>
        </div>
        <SocialButtons text="Register with" />

        <template v-if="!$route.query.showForm">
            <div class="my-6 text-center text-sm text-gray-foreground/80">OR</div>

            <div class="flex gap-2 flex-col items-center mt-2">
                <button
                    class="
                        flex items-center w-full bg-[#fff] text-[#000] rounded-full
                        hover:bg-[#f5f5f5] active:bg-[#e5e5e5] h-[36px]
                        disabled:opacity-50 disabled:cursor-not-allowed
                    "
                    @click="showForm"
                >
                    <div class="flex gap-2 mx-auto min-w-1/2">
                        <img src="@images/email-icon.png" alt="Email" class="size-5">
                        <span class="text-sm">Register with Email Address</span>
                    </div>
                </button>
            </div>
        </template>

        <form ref="formEl" v-if="$route.query.showForm" @submit.prevent="register.execute()" class="mt-5">
            <FormInput :form="register.form" name="name" label="Full Name" />
            <FormInput :form="register.form" name="email" label="Email" type="email" />
            <FormInput :form="register.form" name="password" label="Password" type="password" />
            <FormInput :form="register.form" name="password_confirmation" label="Re-type Password" type="password" />

            <p class="mt-10 text-sm">
                By signing up, you confirm that you have read and agreed to Corperland's <a target="_blank" href="/agreements/privacy-policy" class="text-primary">Privacy Policy</a>
                and <a target="_blank" href="/agreements/eula" class="text-primary">End-User License Agreement</a>
            </p>

            <AppButton full color="primary" type="submit"
                :loading="register.form.processing" class="mt-8">
                Register
            </AppButton>

            <div class="mt-4 flex items-center justify-center">
                <app-text-button @click="closeForm">Cancel</app-text-button>
            </div>
        </form>

        <p class="mt-8 text-gray-foreground/60 text-center">
            Already have an account? <RouterLink class="text-primary font-semibold" :to="{ name: 'login' }">Login
            </RouterLink>
        </p>

        <template v-if="$route.name === 'webinar.register'">
            <div class="mt-8 text-center text-sm text-gray-foreground/80">
                The details of the webinar will be sent to your email and will be available on the app fews days before the webinar.
            </div>
        </template>
    </div>
</template>
