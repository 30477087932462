<script setup>
import { onBeforeMount, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useEmitter } from '@/plugins/emitter';

import Dialog from '@/components/Dialog/Dialog.vue';
import DialogBody from '@/components/Dialog/DialogBody.vue';
import DialogTitle from '@/components/Dialog/DialogTitle.vue';
import DialogDescription from '@/components/Dialog/DialogDescription.vue';
import DialogFooter from '@/components/Dialog/DialogFooter.vue';
import TextButton from '@/components/Button/TextButton.vue';

const route = useRoute();
const openDialog = ref(false);
let resolve = null;
let reject = null;

const type = ref('');
const title = ref('');
const description = ref('');
const confirmText = ref('');
const cancelText = ref('');
const darkMode = ref(false);
const emitter = useEmitter();

const open = (data) => {
    if (!data.title) {
        throw new Error('Title is required');
    }

    if (!data.resolve || !data.reject) {
        throw new Error('Resolve and reject are required');
    }

    title.value = data.title;

    if (data.description) {
        description.value = data.description;
    }

    if (data.confirmText) {
        confirmText.value = data.confirmText;
    }

    if (data.cancelText) {
        cancelText.value = data.cancelText;
    }

    darkMode.value = data.darkMode || false;

    type.value = data.type;
    openDialog.value = true;
    resolve = data.resolve;
    reject = data.reject;
}

const close = (result) => {
    openDialog.value = false;

    if (result) {
        if (resolve) {
            resolve(true);
        }
    } else {
        if (reject) {
            reject({
                cancel: true,
            });
        }
    }
}

function confirm({ title, description, darkMode, confirmText = 'Yes', cancelText = 'Cancel' }) {
    if (typeof arguments[0] === 'string') {
        title = arguments[0];
    }

    return new Promise((resolve, reject) => {
        open({
            type: 'confirm',
            title,
            description,
            darkMode,
            confirmText,
            cancelText,
            resolve,
            reject
        });
    });
}

function alert({ title, description, darkMode, okText = 'Okay' }) {
    if (typeof arguments[0] === 'string') {
        title = arguments[0];
    }

    return new Promise((resolve, reject) => {
        open({
            type: 'alert',
            title,
            description,
            darkMode,
            confirmText: okText,
            resolve,
            reject
        });
    });
}

watch(() => route.fullPath, () => {
    if (openDialog.value) {
        close();
    }
});

onBeforeMount(() => {
    window.confirm = confirm;
    window.alert = alert;

    emitter.on('close-alerts', close);
});

onUnmounted(() => {
    emitter.off('close-alerts', close);
});
</script>

<template>
    <Dialog :open="openDialog" basic-width non-dismissible z-index="10000000" @update:open="close()" :dark-mode="darkMode">
        <DialogBody>
            <DialogTitle>{{ title }}</DialogTitle>
            <DialogDescription v-if="description">{{ description }}</DialogDescription>
        </DialogBody>

        <DialogFooter class="px-6">
            <TextButton v-if="cancelText" @click="close(false)">{{ cancelText }}</TextButton>
            <TextButton @click="close(true)">{{ confirmText }}</TextButton>
        </DialogFooter>
    </Dialog>
</template>
