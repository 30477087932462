<script setup>
import { nextTick, ref } from 'vue';

import { downloadFile } from '@/utils';
import { useStore } from '@/store';

const emit = defineEmits(['close']);

const props = defineProps({
    files: {
        type: Array,
        default: () => [],
    },
    initialSlide: {
        type: Number,
        default: 0,
    },
    autoplay: {
        type: Boolean,
        default: false,
    },
});

const store = useStore();
const activeIndex = ref(0);
const deltaX = ref(null);
const deltaY = ref(null);
const opacity = ref(null);
const swiper = ref(null);

const onSwiperActiveIndexChange = (event) => {
    const index = event.target.swiper?.activeIndex;

    if (typeof index !== 'undefined') {
        activeIndex.value = index;
    }
}

const onPan = (e) => {
    // TODO: improve this. not wowrking with swipe right to change image
    // if (store.state.deviceType !== 'mobile') {
    //     return;
    // }

    // deltaX.value = e.deltaX;
    // deltaY.value = e.deltaY;

    // if (opacity.value === null) {
    //     opacity.value = 1;
    // } else {
    //     opacity.value = e.deltaY > 0 ? Math.max(opacity.value - 0.005, 0.2) : 1;
    // }

    // if (e.isFinal) {
    //     if (e.deltaY > 100) {
    //         nextTick(() => {

    //         emit('close');
    //         });
    //     } else {
    //         deltaX.value = null;
    //         deltaY.value = null;
    //         opacity.value = null;
    //     }
    // }
}
</script>

<style>
.video-player {
    width: 100%;
    height: 100%;
    position: relative;
}
.video-player video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
}
</style>

<template>
    <app-layout class="bg-white text-white-foreground relative" :style="{
        '--tw-bg-opacity': opacity,
        '--tw-text-opacity': opacity,
    }">
        <app-layout-header class="fixed md:absolute top-0 left-0 z-10" :style="{
            opacity: opacity,
        }">
            <div class="w-full flex items-center py-2">
                <div class="rounded-full -ml-2">
                    <app-icon-button icon="cancel" button-style="filled-tonal" @click="$emit('close')" aria-label="Close" />
                </div>

                <div v-if="files.length > 1" class="text-sm ml-4">{{ activeIndex + 1 }} of {{ files.length }}</div>
                <div class="grow"></div>
                <app-icon-button
                    icon="download"
                    button-style="filled-tonal"
                    @click="downloadFile(files[activeIndex].id)"
                    aria-label="Download"
                />
            </div>
        </app-layout-header>
        <app-layout-body>
            <swiper-container
                ref="swiper"
                class="h-full"
                :slides-per-view="1"
                @swiperactiveindexchange="onSwiperActiveIndexChange"
                :initial-slide="initialSlide"
                >
                <swiper-slide v-for="(file, index) in files" :key="file.url">
                    <div class="flex items-center justify-center h-full" v-hammer:pan="onPan">
                        <img v-if="file.type == 1" :src="file.url" class="object-contain select-none pointer-events-none w-auto h-auto max-h-full max-w-full" :style="{
                            transform: deltaX !== null && activeIndex === index ? `translate(${deltaX}px, ${deltaY}px)` : undefined,
                        }" />
                        <app-video-player v-else
                            :muted="false"
                            :src="file.url"
                            class="video-player"
                            :autoplay="autoplay"
                            :play="index !== activeIndex ? false : undefined"
                            no-styling
                            :poster="file.poster?.url"
                            :style="{
                            transform: deltaX !== null && activeIndex === index ? `translate(${deltaX}px, ${deltaY}px)` : undefined,
                        }">
                            <app-media-controls
                                class="absolute bottom-0 left-0 z-50 px-6 py-4
                                transition-all duration-300
                                __ignore-gallery-fullscreen
                                __ignore-swipe-gestures"
                            />
                            <app-media-network-loader />
                        </app-video-player>
                    </div>
                </swiper-slide>
            </swiper-container>
        </app-layout-body>
    </app-layout>
</template>
