import button from '@/components/Button/index';
import checkbox from '@/components/Checkbox/index';
import dialog from '@/components/Dialog/index';
import list from '@/components/List/index';
import radioButton from '@/components/RadioButton/index';
import icons from '@/icons';
import appSwitch from '@/components/Switch/index';
import dividers from '@/components/Dividers/index';
import bottomSheets from '@/components/BottomSheets/index';
import head from '@/components/Head/index';
import file from '@/components/File/index';
import AppLineClamp from '@/components/LineClamp.vue';
import AppAvatar from '@/components/Avatar.vue';
import pills from '@/components/Pills/index';
import AppBadge from '@/components/Badge.vue';
import layout from '@/components/Layout/index';
import form from '@/components/Form/index';
import error from '@/components/Secondary/Error/index';
import dropdownMenu from '@/components/DropdownMenu/index';
export default {
    ...button,
    ...checkbox,
    ...dialog,
    ...list,
    ...radioButton,
    ...icons,
    ...appSwitch,
    ...dividers,
    ...bottomSheets,
    ...head,
    ...file,
    ...pills,
    AppAvatar,
    AppBadge,
    ...layout,
    ...form,
    ...error,
    ...dropdownMenu,
    AppLineClamp,
}
