<script setup>
import axios from 'axios';
import { onMounted, onUnmounted, ref } from 'vue';

const show = ref(false);
const event = ref(null);
let intervalId = null;

// Fetch banner from API
const getBanner = async () => {
    try {
        const response = await axios.get(`/api/banner`);
        const data = response.data.data;

        if (!data) {
            show.value = false;
            return;
        }

        event.value = data;
        show.value = true;
    } catch (error) {
        console.error("Failed to fetch banner:", error);
    }
};

// Open event link
const visit = () => {
    if (!event.value?.link || event.value?.has_ended) return;
    window.open(event.value.link, "_blank");
};

onMounted(() => {
    getBanner();
    intervalId = setInterval(getBanner, 120000); // Fetch every 2 minutes
});

onUnmounted(() => {
    if (intervalId) {
        clearInterval(intervalId);
    }
});
</script>

<template>
    <div v-if="show" class="bg-info text-info-foreground mb-2 rounded-sm">
        <div class="w-full flex items-center justify-between gap-2 p-3">
            <div class="font-medium flex flex-col gap-y-1">
                <span>{{ event?.title }}</span>
                <span class="text-sm">{{ event?.full_date }}</span>
            </div>
            <div class="shrink-0" v-if="event.show_btn">
                <button type="button"
                    class="font-medium flex items-center justify-center gap-1 select-none border border-primary-foreground px-2 py-0.5 rounded-full disabled:opacity-60"
                    @click="visit">
                    {{ event?.btn_text ?? 'Join' }}
                </button>
            </div>
        </div>
    </div>
</template>
