<style lang="postcss">
.layout-body::-webkit-scrollbar {
    display: none;
}

.layout-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>

<template>
    <div class="layout-body grow overflow-y-auto w-full">
        <slot></slot>
    </div>
</template>
