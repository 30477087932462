import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import dayjs from 'dayjs';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPlatoon() {
    const platoon = localStorage.getItem('platoon');

    if (platoon && platoon >= 1 && platoon <= 20) {
        return platoon;
    }

    return null;
}

export function getReferralCode() {
    return localStorage.getItem('referral_code');
}
export function getRegistrationSource() {
    return localStorage.getItem('registration_source');
}

export function getDeviceType() {
    if (typeof window === 'undefined') {
        return 'desktop';
    }

    if (window.matchMedia('(max-width: 767.9px)').matches) {
        return 'mobile';
    }

    if (window.matchMedia('(min-width: 768px) and (max-width: 1023.9px)').matches) {
        return 'tablet';
    }

    return 'desktop';
}

export const getFriendlySeconds = (seconds) => {
    const secs = Math.floor(seconds);

    if (secs < 60) {
        return `${secs}s`;
    }

    if (secs < 3600) {
        return `${Math.floor(secs / 60)}m ${secs % 60}s`;
    }

    return `${Math.floor(secs / 3600)}h ${Math.floor((secs % 3600) / 60)}m ${secs % 60}s`;
}

export const getFriendlyTime = (date, { withTime = true, todayString, oldDateShort = false } = {}) => {
    const dayjsDate = dayjs(date);
    const now = dayjs();

    const time = withTime ? ' ' + dayjsDate.format('h:mm A') : '';

    if (dayjsDate.isToday()) {
        return todayString ? todayString : dayjsDate.format('h:mm A');
    } else if (dayjsDate.isYesterday()) {
        return 'Yesterday' + time;
    } else if (dayjsDate.isSame(now, 'week')) {
        return dayjsDate.format('ddd') + time;
    } else if (dayjsDate.isSame(now, 'year')) {
        return dayjsDate.format('MMMM D') + time;
    } else {
        return dayjsDate.format(oldDateShort ? 'MMM\' YY' : 'MMMM D, YYYY') + time;
    }
}

export const getFriendlyDuration = (duration) => {
    if (!duration || isNaN(duration)) {
        return '0:00';
    }

    const totalSeconds = Math.floor(duration);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
}

export const getEventMessageContent = (message, conversation) => {
    const you = message.member.id == conversation.id;

    if (message.content.type == 'conversation.accepted') {
        return you ? 'You accepted the request to chat' : `Your request to chat accepted`;
    }

    console.error('Unknown event message type', message.content.type);

    return '';
}

export const debounce = (fn, delay) => {
    let timeout;

    return (...args) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => fn(...args), delay);
    }
}

export const scrollElementIntoView = ({ el, top = true, extra = 0 }) => {
    const targetPosition = (top ? el.getBoundingClientRect().top : el.getBoundingClientRect().bottom) + window.scrollY;
    const scrollPosition = top ? targetPosition + extra : targetPosition - window.innerHeight + extra;

    window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
    });
}

export const scrollElementIntoViewIfNeeded = ({ el, top = true, extra = 0 }) => {
    const isInViewport = (elem) => {
        const rect = elem.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    if (!isInViewport(el)) {
        scrollElementIntoView({
            el, top, extra
        });
    }
}

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(deepCopy);
    }

    const copy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }
    return copy;
}

export const deepMerge = (target, ...sources) => {
    if (!sources.length) return target;

    const source = sources.shift();

    if (typeof target === 'object' && typeof source === 'object') {
        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                if (
                    typeof source[key] === 'object' &&
                    source[key] !== null &&
                    !Array.isArray(source[key])
                ) {
                    if (!target[key] || typeof target[key] !== 'object') {
                        target[key] = {};
                    }
                    deepMerge(target[key], source[key]);
                } else if (Array.isArray(source[key])) {
                    if (Array.isArray(target[key])) {
                        source[key].forEach((item, index) => {
                            target[key][index] = item;
                        });
                    } else {
                        target[key] = [...source[key]];
                    }
                } else {
                    target[key] = source[key];
                }
            }
        }
    }

    return deepMerge(target, ...sources);
}

export const isDirty = (original, current) => {
    if (original === current) return false;

    if (typeof original !== 'object' || typeof current !== 'object' || original === null || current === null) {
        return original !== current;
    }

    const originalKeys = Object.keys(original);
    const currentKeys = Object.keys(current);

    if (originalKeys.length !== currentKeys.length) {
        return true;
    }

    for (const key of originalKeys) {
        if (!currentKeys.includes(key) || isDirty(original[key], current[key])) {
            return true;
        }
    }

    return false;
}

export const pluralize = (word, count, pluralWord = null) => {
    if (count == 1) {
        return word;
    }

    return pluralWord ? pluralWord : word + 's';
}

export const shouldShowItem = (item) => {
    if ('hide' in item) {
        return typeof item.hide === 'function' ? !item.hide() : Boolean(!item.hide);
    }

    if ('show' in item) {
        return typeof item.show === 'function' ? item.show() : Boolean(item.show);
    }

    return true;
}

export const abbreviateNumber = (number) => {
    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1
    }).format(number);
}

export const formatNumberWithCommas = (number) => {
    return Intl.NumberFormat('en-US').format(number);
}

export const getFriendlyFileSize = (size) => {
    if (size >= 1024 * 1024 * 1024) {
        const s = size / 1024 / 1024 / 1024;
        return `${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(s)} GB`;
    }

    if (size >= 1024 * 1024) {
        const s = size / 1024 / 1024;
        return `${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(s)} MB`;
    }

    const s = size / 1024;
    return `${Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(s)} KB`;
}

export const appendQueryParams = (url, params) => {
    const currentUrl = new URL(url);

    for (const [key, value] of Object.entries(params)) {
        currentUrl.searchParams.set(key, value);
    }

    return currentUrl.toString();
}

export const percentage = (value, total) => {
    return total == 0 ? 0 : (value / total) * 100;
}

export const remainingTime = (date) => {
    const seconds = dayjs(date).diff(dayjs(), 'seconds');

    let unit = 'sec';
    let value = seconds;

    switch (true) {
        case seconds > (60 * 60 * 24):
            value = dayjs(date).diff(dayjs(), 'days', true);
            unit = 'day';
            break;
        case seconds > (60 * 60):
            value = dayjs(date).diff(dayjs(), 'hours', true);
            unit = 'hr';
            break;
        case seconds > 60:
            value = dayjs(date).diff(dayjs(), 'minutes', true);
            unit = 'min';
            break;
    }

    value = Math.ceil(value);

    return value + ' ' + pluralize(unit, value);
}

/**
 * Check if a variable is declared and not null
 * @param {*} variable
 * @returns {boolean}
 */
export const isset = (variable) => {
    return typeof variable !== 'undefined' && variable !== null;
}

export const unflatten = (obj) => {
    const output = {};

    Object.entries(obj).forEach(([key, value]) => {
        const parts = key.split('.'); // Split by dot notation
        let current = output;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const arrayMatch = part.match(/^(.+)\[(\d+)]$/); // Match array-like keys (e.g., files[0])

            if (arrayMatch) {
                const arrayKey = arrayMatch[1]; // Extract key (e.g., 'files')
                const index = Number(arrayMatch[2]); // Extract index

                if (!current[arrayKey]) {
                    current[arrayKey] = [];
                }
                current[arrayKey][index] = value; // Assign value at the correct index
            } else {
                if (i === parts.length - 1) {
                    current[part] = value; // Assign final value
                } else {
                    if (!current[part]) {
                        current[part] = {}; // Initialize nested object if it doesn't exist
                    }
                    current = current[part];
                }
            }
        }
    });

    return output;
}

export const deepGet = (obj, path, defaultValue = undefined) => {
    const pathArray = path.replace(/\[(\d+)\]/g, '.$1').split('.');

    return pathArray.reduce((acc, key) => {
        if (acc && acc.hasOwnProperty(key)) {
            return acc[key];
        }
        return defaultValue;
    }, obj);
}

export const deepUnset = (obj, path) => {
    const pathArray = path.replace(/\[(\d+)\]/g, '.$1').split('.');

    pathArray.reduce((acc, key, index) => {
        if (acc && acc.hasOwnProperty(key)) {
            if (index === pathArray.length - 1) {
                if (Array.isArray(acc)) {
                    acc.splice(key, 1);
                } else {
                    delete acc[key];
                }
            }
            return acc[key];
        }

        return undefined;
    }, obj);
}

export const isSameDay = (date1, date2) => {
    return dayjs(date1).isSame(dayjs(date2), 'day');
}

export const isSameMinute = (date1, date2) => {
    return dayjs(date1).isSame(dayjs(date2), 'minute');
}

export const downloadFile = (id) => {
    const a = document.createElement('a');
    a.href = `/files/${id}/download`;
    a.setAttribute('target', '_blank');
    a.click();
}

export const openInNewTab = (url) => {
    window.open(url, '_blank');
}
