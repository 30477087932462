<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';

import { elPositionFix } from '@/utils/fixed';
import { PostType } from '@/enums';
import { useAjaxForm } from '@/utils/form';
import { useEmitter } from '@/plugins/emitter';
import { scrollElementIntoView } from '@/utils';

import AnonymousAvatar from '@/components/User/AnonymousAvatar.vue';
import IconCancelLight from '@/components/Icons/CancelLight.vue';
import IconImages from '@/components/Icons/Images.vue';
import IconSubmit from '@/components/Icons/Submit.vue';
import FilePicker from '@/components/Form/v1/FilePicker.vue';
import FilePickerDisplay from '@/components/Form/v1/FilePickerDisplay.vue';
import MyAvatar from '@/components/User/MyAvatar.vue';
import TextBox from '@/components/TextBox.vue';

const container = ref();
const textBox = ref();
const picker = ref();
let fix = null;

const emit = defineEmits(['comment:created']);

const filesComponent = ref();
const form = useAjaxForm({
    content: '',
    files: [],
});

const props = defineProps({
    post: {
        required: true,
        type: Object,
    }
});

const replying = ref(null);
const emitter = useEmitter();
const submit = () => {
    const url = replying.value ? `/api/comments/${replying.value.commentId}/replies` : `/api/posts/${props.post.id}/comments`;

    form.post(url, {
        onSuccess(response) {
            const data = response.data.data;
            data._clientMeta = {
                isNew: true,
            };

            if (replying.value) {
                try {
                    emitter.emit('reply:created.' + replying.value.commentId, {
                        data,
                    });
                } catch (err) {
                    console.error(err);
                }

                closeReply();
            } else {
                reset();
                try {
                    emit('comment:created', data);
                } catch (err) {
                    console.error(err);
                }
            }
        }
    })
}

const reset = () => {
    form.clear();
    form.files = [];
    picker.value?.clear();
}

const placeholder = computed(() => {
    if (replying.value) {
        return 'Write a reply';
    }

    return props.post.type == PostType.QUESTION ? 'Write an Answer' : 'Write a comment'
});

const closeReply = () => {
    replying.value = null;
    reset();
}

const scrollToCommentTarget = () => {
    if (!replying.value) {
        return;
    }

    const data = replying.value;

    if (data.target) {
        setTimeout(() => {
            scrollElementIntoView({
                el: data.target,
                top: false,
                extra: container.value.offsetHeight,
            });
        }, 10);
    }
}

onMounted(() => {
    fix = elPositionFix({
        el: container.value,
        style: {
            bottom: '0px',
            zIndex: 50,
        }
    });
});

onUnmounted(() => {
    if (fix) {
        fix.disconnect();
    }
});

defineExpose({
    openReplyForm(data) {
        if (!replying.value || replying.value.for !== data.for) {
            reset();

            if (data.text || data.text === '') {
                textBox.value.input(data.text);
            }
        }
        replying.value = data;

        scrollToCommentTarget();

        textBox.value.focus();
    },
});
</script>

<template>
    <div class="w-full" ref="container">
        <form @submit.prevent="submit" class="w-full bg-white border-0 border-t border-white-foreground/20">
            <div v-if="replying" class="w-full px-2 py-3 bg-gray text-gray-foreground/80 flex items-center justify-between">
                <div @click="scrollToCommentTarget" class="text-sm grow">
                    Replying to @{{ replying.username }}
                </div>
                <button @click="closeReply" class="block" type="button">
                    <IconCancelLight class="size-5" />
                </button>
            </div>
            <div class="flex items-end gap-2 px-2 py-4 w-full">
                <div class="shrink-0">
                    <AnonymousAvatar size="sm" v-if="post.anonymously" />
                    <MyAvatar v-else size="sm" />
                </div>

                <div class="min-w-0 self-stretch w-full flex flex-col gap-2">
                    <TextBox v-model="form.content" ref="textBox" class="" :rows="1" :placeholder="placeholder" />

                    <FilePickerDisplay :picker="picker" />
                </div>
                <div class="shrink-0 flex">
                    <FilePicker
                        ref="picker"
                        v-model="form.files"
                        aria-label="add images or videos"
                        type="button"
                        multiple
                    >
                        <IconImages class="size-6" />
                    </FilePicker>
                    <button :disabled="form.processing || filesComponent?.uploading" v-show="form.content || (form.files && form.files.length > 0)"
                        class="text-primary ml-2" type="submit" aria-label="submit"
                        :class="{
                            'opacity-60': filesComponent?.uploading,
                            'animate-pulse': form.processing,
                        }">
                        <IconSubmit class="size-7" />
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
