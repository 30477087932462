<script setup>
import { computed, inject, ref } from 'vue';
import { SliderRoot, SliderTrack, SliderRange, SliderThumb } from 'radix-vue';

const range = defineModel('range', {
    type: Array,
    required: false,
});

defineProps({
    minRangeSteps: {
        type: Number,
        default: 1,
    },
    useRange: {
        type: Boolean,
        default: false,
    },
});

const player = inject('mediaPlayer');

let playAfterChange = null;
let wasPlayingBeforeChange = null;

const onValueChange = (value) => {
    clearTimeout(playAfterChange);

    if (wasPlayingBeforeChange === null) {
        wasPlayingBeforeChange = !player.paused;
        if (wasPlayingBeforeChange) {
            player.pause();
        }
    }

    player.currentTime = value[0];

    if (range.value) {
        range.value = value;
    }

    playAfterChange = setTimeout(() => {
        if (wasPlayingBeforeChange) {
            player.play();
        }
        playAfterChange = null;
        wasPlayingBeforeChange = null;
    }, 500);
}
</script>

<template>
    <SliderRoot
        v-if="player"
        class="relative flex items-center select-none touch-none w-full h-5 swiper-no-swiping"
        :model-value="range ? range : [player.currentTime]"
        @update:model-value="onValueChange"
        :max="player.duration"
        :step="1"
        :min-steps-between-thumbs="minRangeSteps"
    >
        <SliderTrack class="bg-primary-foreground relative grow rounded-full h-[3px] data-[disabled=true]:opacity-70 data-[disabled=true]:cursor-not-allowed">
            <SliderRange class="absolute bg-primary rounded-full h-full" />
        </SliderTrack>
        <template v-if="useRange">
            <SliderThumb v-for="i in [1, 2]" :key="i"
                class="block w-1 h-5 bg-[#ffffff] shadow-[0_2px_10px] shadow-primary rounded-[10px] hover:bg-violet3 focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-primary/50"
                aria-label="Seek video" />
        </template>
    </SliderRoot>
</template>
