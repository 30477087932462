<script setup>
import { inject, ref, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const uploader = inject('uploader');

const props = defineProps({
    image: {
        type: Object,
        required: true
    }
});

const cropperImage = ref(null);

const cropper = ref(null);

const route = useRoute();
const router = useRouter();
const action = computed(() => route.query.action);
const activeAction = ref(null);
const loading = ref(false);

const crop = () => {
    loading.value = true;
    cropper.value.crop();
    props.image.crop.data = cropper.value.getData();
    cropper.value.getCroppedCanvas().toBlob((blob) => {
        props.image.crop.url = URL.createObjectURL(blob);
        destroyCropper();
        loading.value = false;
        router.back();
    });
}

const destroyCropper = () => {
    cropper.value?.destroy();
    cropper.value = null;
    activeAction.value = null;
}

const setCropper = () => {
    if (uploader.activeFile.url !== props.image.url) {
        return;
    }

    activeAction.value = 'crop';

    if (cropper.value) {
        return;
    }

    cropper.value = new Cropper(cropperImage.value, {
        viewMode: 1,
        autoCrop: true,
        autoCropArea: 1,
        dragMode: 'move',
        checkCrossOrigin: false,
        data: props.image.crop.data,
    });
};

watch(action, (action) => {
    if (action === 'crop') {
        setCropper();
    } else if (activeAction.value === 'crop') {
        destroyCropper();
    }
});

onMounted(() => {
});
</script>

<template>
    <div class="w-full h-full flex flex-col" :class="{
        'swiper-no-swiping': action
    }">
        <div
            class="flex items-center justify-center w-full transition-all duration-300"
            :class="{
                'h-full': !action,
                'h-[calc(100%-60px)]': action
            }"
        >
            <img v-show="action === 'crop'" loading="lazy" draggable="false" ref="cropperImage"
                class="block h-full w-auto max-w-full object-contain select-none pointer-events-none"
                :src="image.url" />
            <img v-show="action !== 'crop'" loading="lazy" draggable="false"
                class="block h-full w-auto max-w-full object-contain select-none pointer-events-none"
                :src="image.crop.url || image.url" />
        </div>
        <div
            class="h-[60px] w-full flex items-center px-4 transition-all duration-300"
            :class="{
                'opacity-100 h-[60px]': action,
                'opacity-0 h-[0px] overflow-hidden': !action
            }"
        >
            <button class="font-semibold uppercase text-sm active:scale-110" @click="router.back()">Cancel</button>
            <div class="grow"></div>
            <button :disabled="loading" class="font-semibold uppercase text-sm disabled:opacity-70 active:scale-110" @click="crop">Crop</button>
        </div>
    </div>
</template>
