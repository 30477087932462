<script setup>
import { computed } from 'vue';

import { FileType, FileStatus } from '@/enums';
import { startUpload, createUpload } from '@/services/upload';
import { ref } from 'vue';

const model = defineModel();

const imageTypes = 'image/jpeg,image/png,image/webp,image/heic';
const videoTypes = 'video/mp4,video/avi,video/mpeg,video/quicktime,video/webm';
const documentTypes = 'application/pdf';

const props = defineProps({
    acceptImage: {
        required: false,
        type: Boolean,
        default: true,
    },
    acceptVideo: {
        required: false,
        type: Boolean,
        default: true,
    },
    acceptPdf: {
        required: false,
        type: Boolean,
        default: true,
    },
    multiple: {
        required: false,
        type: Boolean,
        default: false,
    },
    max: {
        required: false,
        type: Number,
        default: 10,
    },
    isPrivate: {
        required: false,
        default: false,
        type: Boolean,
    },
    as: {
        required: false,
        default: 'button',
    }
});

const getModelValue = () => {
    if (!props.multiple) {
        return ref([model.value].filter(Boolean)).value;
    }

    return model.value;
}

const getAccept = computed(() => {
    if (!props.acceptImage && !props.acceptVideo && !props.acceptPdf) {
        return imageTypes + ',' + videoTypes + ',' + documentTypes;
    }

    let types = [];

    if (props.acceptImage) {
        types.push(imageTypes);
    }
    if (props.acceptVideo) {
        types.push(videoTypes);
    }
    if (props.acceptPdf) {
        types.push(documentTypes);
    }

    return types.join(',');
});

const getMax = computed(() => {
    if (!props.multiple) {
        return Math.max(0, 1 - getModelValue().length);
    }

    return Math.max(0, props.max - getModelValue().length);
});

const fileChanged = async (e) => {
    if (e.target.files.length === 0) {
        return;
    }

    const files = [];

    for (const file of e.target.files) {
        files.push({
            id: null,
            url: URL.createObjectURL(file),
            size: file.size,
            original_name: file.name,
            mime_type: file.type,
            type: FileType.fromMimeType(file.type),
            is_public: !props.isPrivate,
            status: FileStatus.PENDING,
            __client: {
                upload_id: (await createUpload(file, {
                    is_private: props.isPrivate,
                    crop: null,
                    clip: null,
                    original_name: file.name,
                })).id,
            }
        });
    }

    if (props.multiple) {
        model.value.push(...files);
    } else {
        model.value = files[0];
    }

    const uploads = files.map(async (attachment) => {
        const upload = await startUpload(attachment.__client.upload_id);

        if (!props.multiple) {
            model.value.id = upload.response.file.id;
            model.value.url = upload.response.file.url;
            model.value.status = FileStatus.UPLOADED;
        } else {
            const ref = model.value.find((a) => a.__client.upload_id === attachment.__client.upload_id);
            ref.id = upload.response.file.id;
            ref.url = upload.response.file.url;
            ref.status = FileStatus.UPLOADED;
        }

        upload.delete();
    });

    await Promise.allSettled(uploads);
}

const open = () => {
    const picker = document.createElement('input');
    picker.type = 'file';
    picker.multiple = props.multiple;
    picker.accept = getAccept.value;
    picker.onchange = fileChanged;
    picker.click();
}

const removeFile = (index) => {
    if (!props.multiple) {
        model.value = null;
        return;
    }

    model.value = model.value.filter((_, i) => i != index);
}

defineExpose({
    getDisplayFiles: computed(() => getModelValue()),
    getMax,
    open,
    removeFile,
    clear: () => {
        if (props.multiple) {
            model.value = [];
        } else {
            model.value = null;
        }
    },
});
</script>

<template>
    <component :is="as" @click="open">
        <slot></slot>
        <input :max="getMax" aria-hidden="true" v-on:change="fileChanged" hidden :multiple="multiple" ref="fileInput" type="file" :accept="getAccept">
    </component>
</template>
