import { Connection } from "jsstore";
import { models } from './models';

const worker = new Worker('/worker-build/worker.js');
const connection = new Connection(worker);

let initialized = false;

export const getConnection = async () => {
    if (initialized) {
        return connection;
    }

    const created = await connection.initDb({
        name: 'app',
        tables: models().map((model) => model.getMigrations()),
    });

    if (created) {
        // TODO: Seeders if any
    }

    // database already exists

    initialized = true;

    return connection;
}
