<script setup>
import { onBeforeMount, ref, watch } from 'vue';

import { collectionV2 } from '@/composables/resource';
import { useUtils } from '@/composables/chat';

import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';
import StartChatUserItem from './StartChatUserItem.vue';

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['update:open']);

const users = collectionV2({
    url: '/api/users',
    params: {
        order: {
            name: 'asc',
        }
    }
});

const selectedUser = ref(null);
const utils = useUtils();
let searchTimeout;

const search = (query) => {
    if (!query) {
        users.resetSearch();
        return;
    }

    users.search(query);
}

const submitSearch = (query) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => search(query), !query ? 0 : 500);
}

const startConversation = () => {
    if (!selectedUser.value) {
        return;
    }

    emit('update:open', false);
    utils.openChat(selectedUser.value);
}

watch(() => props.open, (value) => {
    if (value) {
        users.fetchOnce();
    } else {
        selectedUser.value = null;
        users.resetSearch();
    }
}, { immediate: true });

onBeforeMount(() => {
    selectedUser.value = null;
});
</script>

<template>
    <app-dialog :open="open" full-screen :close-on-back-navigation="true" @update:open="emit('update:open', $event)">
        <app-layout-header>
            <app-top-nav>
                <app-top-nav-back cancel-icon click-off @click.prevent="emit('update:open', false)" />
                <app-top-nav-title>New Chat</app-top-nav-title>
            </app-top-nav>
            <app-form @submit.prevent="submitSearch">
                <app-form-control>
                    <app-form-input @update:value="submitSearch" name="search" placeholder="Search" type="search" />
                </app-form-control>
            </app-form>
        </app-layout-header>
        <app-layout-body v-if="open">
            <app-dialog-description class="sr-only">
                Start a new conversation with a Corperland user.
            </app-dialog-description>

            <div class="flex flex-col gap-2 mt-4">
                <template v-if="users.searched">
                    <div class="text-muted text-sm px-4 font-medium">
                        Search results
                    </div>
                    <app-list v-if="users.searchData.length > 0" target-as="button">
                        <start-chat-user-item v-for="user in users.searchData" :key="user.id" :user="user" v-model:selected-user="selectedUser" />
                    </app-list>
                    <div v-else class="text-muted text-sm px-4 font-medium">
                        No results found
                    </div>
                </template>
                <template v-else>
                    <app-list target-as="button">
                        <start-chat-user-item :user="$store.state.user" v-model:selected-user="selectedUser" />
                    </app-list>

                    <Skeleton :collection="users" />
                    <template v-if="users.fetched && users.data.length > 0">
                        <div class="text-muted text-sm px-4 font-medium">
                            Other people
                        </div>
                        <app-list target-as="button">
                            <start-chat-user-item v-for="user in users.data" :key="user.id" :user="user" v-model:selected-user="selectedUser" />
                        </app-list>
                        <InfiniteScrollObserver :collection="users" />
                    </template>
                </template>
            </div>
        </app-layout-body>
        <app-layout-footer>
            <div class="w-full px-4 py-2">
                <app-button full color="primary" :disabled="!selectedUser" @click="startConversation">
                    Start Conversation
                </app-button>
            </div>
        </app-layout-footer>
    </app-dialog>
</template>
