<script setup>
import { RouterLink } from 'vue-router';

import DropdownMenuRoot from '@/components/DropdownMenu/DropdownMenuRoot.vue';
import DropdownMenuPortal from '@/components/DropdownMenu/DropdownMenuPortal.vue';
import DropdownMenuContent from '@/components/DropdownMenu/DropdownMenuContent.vue';
import DropdownMenuItem from '@/components/DropdownMenu/DropdownMenuItem.vue';
import DropdownMenuSeparator from '@/components/DropdownMenu/DropdownMenuSeparator.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';

import { computed } from 'vue';


const open = defineModel('open', {
    type: Boolean,
    default: false,
});

const props = defineProps({
    sideOffset: {
        type: Number,
        required: false,
    },
    items: {
        required: false,
        type: Object,
    },
    contentAlign: {
        required: false,
        type: String
    },
    contentSide: String,
    defaultOpen: {
        type: Boolean,
        required: false,
    },
});

const itemSelected = (e, item) => {
    if (typeof item.onSelect === 'function') {
        item.onSelect(e);
    }
}

const shouldShow = (item) => {
    if ('hide' in item) {
        return typeof item.hide === 'function' ? !item.hide() : Boolean(!item.hide);
    }

    if ('show' in item) {
        return typeof item.show === 'function' ? item.show() : Boolean(item.show);
    }

    return true;
}

const visibleItems = computed(() => {
    return props.items.filter((item) => shouldShow(item));
});

</script>

<template>
    <DropdownMenuRoot :default-open="defaultOpen" @update:open="open = $event">
        <DropdownMenuTrigger as-child>
            <slot :visibleItems="visibleItems" />
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
            <DropdownMenuContent
                :align="contentAlign"
                :side-offset="sideOffset"
                :side="contentSide"
            >
                <ul v-if="items.length > 0">
                    <li v-for="item, index in visibleItems" :key="index">
                        <DropdownMenuSeparator v-if="index !== 0" />
                        <DropdownMenuItem
                            :class="{
                                'text-danger': item.color === 'danger',
                                'text-primary': item.color === 'primary',
                            }"
                            :type="!item.as ? 'button' : undefined"
                            :disabled="item.disabled"
                            :as="'to' in item ? RouterLink : (item.as ?? 'button')"
                            v-bind="item.attrs"
                            :to="'to' in item ? item.to : undefined"
                            v-on:select="(e) => itemSelected(e, item)">
                            <component v-if="item.icon" class="size-5" :is="item.icon.startsWith('icon-') ? item.icon : `icon-${item.icon}`" :style="{ color: item.iconColor }" />
                            <span>{{  item.label }}</span>
                        </DropdownMenuItem>
                    </li>
                </ul>
            </DropdownMenuContent>
        </DropdownMenuPortal>
    </DropdownMenuRoot>
</template>
