<script setup>
import { collectionV2 } from '@/composables/resource';
import { watch, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const model = defineModel();

const filters = [
    {
        key: undefined,
        label: 'Top',
        headerTitle: 'Top Activities',
    },
    {
        key: 'new',
        label: 'New',
        headerTitle: 'Latest Activities',
        posts: collectionV2({
            url: '/api/posts',
            params: {
                view: 1,
            }
        }),
    },
    {
        key: 'questions',
        label: 'Questions',
        headerTitle: 'Unanswered Questions',
        posts: collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    type: 2,
                    having_no_comments: true,
                }
            }
        }),
    },
    {
        key: 'saved',
        label: 'Saved',
        headerTitle: 'Saved By You',
        posts: collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    saved: true,
                }
            }
        }),
    },
    {
        key: 'pins',
        label: 'Pins',
        headerTitle: 'Pinned to Channels',
        posts: collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    pins: true,
                }
            }
        }),
    },
];

const applyFilter = (key) => {
    if (!key) {
        model.value = null;

        return;
    }

    const filter = filters.find((filter) => filter.key === key);
    filter.posts.fetch();

    model.value = {
        posts: filter.posts,
        headerTitle: filter.headerTitle,
    };
}

onBeforeMount(() => {
    applyFilter(route.query.tab);
});

watch(() => route.query.tab, (key) => {
    applyFilter(key)
});
</script>

<template>
    <app-pill-group>
        <app-pill
            @click="$router.replace({ query: { ...$route.query, tab: filter.key } })"
            v-for="filter in filters"
            :key="filter.key"
            :data-active="$route.query.tab === filter.key"
        >
            <span>{{ filter.label }}</span>
        </app-pill>
    </app-pill-group>
</template>
