import { Query } from '../query';
import { deepCopy } from '@/utils';

/**
 * @typedef {Object} BaseModel
 * @property {number} id
 * @property {number} createdAt
 * @property {number} updatedAt
 */
class BaseModel {
    constructor(attributes = {}) {
        this._attributes = attributes;

        return new Proxy(this, {
            set(target, prop, value) {
                if (target.constructor._getColumnNames().includes(prop)) {
                    target._attributes[prop] = value;

                    return true;
                }

                target[prop] = value;

                return true;
            },
            get(target, prop) {
                if (target.constructor._getColumnNames().includes(prop)) {
                    return target._attributes[prop];
                }

                return target[prop];
            },
        });
    }

    getRawAttributes() {
        return deepCopy(this._attributes);
    }

    getAttributes() {
        return this._attributes;
    }

    getAttribute(name) {
        return this._attributes[name];
    }

    async exists() {
        if (!this._attributes.id) {
            return false;
        }

        const result = await this.newQuery().first();

        return !!result;
    }

    newQuery() {
        if (this._attributes.id) {
            return (new Query(this.constructor.getTableName())).where('id', this._attributes.id);
        }

        return this.constructor.query();
    }

    _getDefaults(key) {
        const parseDefault = (value) => {
            if (value === 'CURRENT_TIMESTAMP') {
                return new Date();
            }

            if (value === 'UUID') {
                return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            }

            return value;
        }

        if (key) {
            return parseDefault(this.constructor._getColumns()[key].default);
        }

        const columns = this.constructor._getColumnNames();

        return columns.reduce((acc, key) => {
            acc[key] = parseDefault(this.constructor._getColumns()[key].default);

            return acc;
        }, {});
    }

    async save() {
        if (await this.exists()) {
            const updatedAt = this._getDefaults('updatedAt');
            const updated = await this.newQuery().update({
                ...this.getRawAttributes(),
                updatedAt,
            });

            if (updated) {
                this._attributes = {
                    ...this._attributes,
                    updatedAt,
                };
            }

            return updated;
        }

        const created = await this.newQuery().create({
            ...this._getDefaults(),
            ...this.getRawAttributes(),
        });

        if (created) {
            this._attributes = created._attributes;

            return true;
        }

        return false;
    }

    async delete() {
        const deleted = await this.newQuery().delete();

        if (deleted) {
            this._attributes = {};
        }
    }

    static getTableName() {
        return null;
    }

    static getMigrations() {
        return {
            name: this.getTableName(),
            columns: this._getColumns(),
        };
    }

    static getPrimaryKey() {
        return 'id';
    }

    static query() {
        return new Query(this.getTableName(), (data) => {
            const model = new this(data);

            return model;
        });
    }

    static async create(data) {
        const model = new this({
            ...data,
        });

        await model.save();

        return model;
    }

    static async updateOrCreate(params, data) {
        const model = await this.query().where(params).first();

        if (model) {
            return await this.query().where('id', model.id).update({
                ...deepCopy(data),
                updatedAt: new Date(),
            });
        }

        const newModel = new this({
            ...params,
            ...data,
        });

        await newModel.save();

        return newModel;
    }

    static async firstOrCreate(params, newData = {}) {
        const model = await this.query().where(params).first();

        if (model) {
            return model;
        }

        const newModel = new this({
            ...deepCopy(params),
            ...deepCopy(newData),
        });

        await newModel.save();

        return newModel;
    }

    static _getColumns() {
        return {
            id: { primaryKey: true, autoIncrement: true },
            uuid: { type: 'string', notNull: true, default: 'UUID' },
            createdAt: { type: 'datetime', notNull: true, default: 'CURRENT_TIMESTAMP' },
            updatedAt: { type: 'datetime', notNull: true, default: 'CURRENT_TIMESTAMP' },
            ...this._columns(),
        };
    }

    static _getColumnNames() {
        return Object.keys(this._getColumns());
    }
}

export default BaseModel;
