import { reactive } from 'vue';
import { UploadState } from '@/enums';

import axios from '@/axios';
import rawAxios from 'axios';

import Upload from '@/db/models/upload';
import echo from '@/plugins/echo';

const CHUNK_SIZE = 5 * 1024 * 1024;
const MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024;
export const uploads = reactive({});

const onProgress = (upload) => {
    uploads[upload.id] = {
        progress: upload.progress,
        ...upload.getAttributes(),
    };
}

export const createUpload = async (file, request) => {
    const upload = new Upload();
    upload.request = request;
    upload.totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    upload.currentChunk = 0;
    upload.maxChunkSize = CHUNK_SIZE;
    upload.totalFileSize = file.size;
    upload.totalUploadedSize = 0;
    upload.request = request;
    upload.state = UploadState.PENDING;

    await upload.save();

    upload.saveFile(file);

    return upload;
}

/**
 * @param {Upload} upload
 */
export const startUpload = (upload) => {
    return new Promise(async (resolve, reject) => {
        if (typeof upload !== 'object') {
            upload = await Upload.query().find(upload);
        }

        const channel = echo.private(`uploads`);

        // listen for complete event
        channel.listen('.upload:updated', (event) => {
            if (event.id !== upload.serverId) {
                return;
            }

            axios.get(`/api/uploads/${upload.serverId}`).then((response) => {
                upload.state = response.data.data.state;
                upload.response = response.data.data;
                upload.save();

                onProgress(upload);

                if (upload.state === UploadState.COMPLETED) {
                    return resolve(upload);
                }

                if (upload.state !== UploadState.PROCESSING) {
                    return reject(new Error('Upload failed'));
                }
            }).catch((err) => {
                upload.state = UploadState.FAILED;
                upload.save();

                onProgress(upload);

                return reject(err);
            });
        });

        if (upload.state === UploadState.COMPLETED) {
            return resolve(upload);
        }

        if (!upload.file && upload.state !== UploadState.PROCESSING) {
            upload.state = UploadState.FAILED;
            await upload.save();

            onProgress(upload);
            return reject(new Error('File not found'));
        }

        if (upload.file.size > MAX_FILE_SIZE) {
            upload.state = UploadState.FAILED;
            await upload.save();

            onProgress(upload);
            return reject(new Error('File too large'));
        }

        if (!upload.serverId) {
            try {
                const response = await axios.post('/api/uploads', {
                    ...(upload.request ?? {}),
                    total: upload.totalChunks,
                    original_name: upload.file.name,
                    mime_type: upload.file.type,
                    file: upload.totalChunks === 1 ? upload.file : undefined,
                }, {
                    onUploadProgress: (progressEvent) => {
                        if (upload.totalChunks > 1) {
                            return;
                        }

                        upload.currentProgressSize = progressEvent.loaded;
                        upload.state = UploadState.IN_PROGRESS;
                        onProgress(upload);
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });

                if (upload.totalChunks === 1) {
                    upload.totalUploadedSize = upload.file.size;
                }
                upload.state = response.data.data.state;
                upload.serverId = response.data.data.id;
                await upload.save();

                onProgress(upload);
            } catch (err) {
                upload.state = UploadState.FAILED;
                await upload.save();

                onProgress(upload);
                return reject(err);
            }
        }

        if (upload.totalChunks > 1 && (upload.state === UploadState.PENDING || upload.state === UploadState.FAILED)) {
            upload.state = UploadState.IN_PROGRESS;

            onProgress(upload);
            await upload.save();

            while (upload.totalChunks > upload.currentChunk) {
                const newChunk = upload.currentChunk + 1;
                const chunk = upload.file.slice(upload.currentChunk * upload.maxChunkSize, newChunk * upload.maxChunkSize);

                try {

                    const response = await axios.post(`/api/uploads/${upload.serverId}/signed-url`, {
                        part_number: newChunk,
                    });

                    await rawAxios.put(response.data.url, chunk, {
                        onUploadProgress: progressEvent => {
                            upload.currentProgressSize = progressEvent.loaded;
                            onProgress(upload);
                        }
                    });

                    upload.totalUploadedSize = upload.totalUploadedSize + chunk.size;
                    upload.currentChunk = newChunk;
                    await upload.save();

                    onProgress(upload);
                } catch (err) {
                    upload.state = UploadState.FAILED;
                    await upload.save();

                    onProgress(upload);
                    return reject(err);
                }
            }

            await upload.save();
        }

        if (upload.state !== UploadState.PROCESSING) {
            upload.state = UploadState.PROCESSING;
            await upload.save();

            onProgress(upload);
            try {
                const response = await axios.post(`/api/uploads/${upload.serverId}/complete`);
                upload.response = response.data.data;
                upload.state = response.data.data.state;
                await upload.save();

                onProgress(upload);
            } catch (err) {
                upload.state = UploadState.FAILED;
                await upload.save();

                onProgress(upload);
                return reject(err);
            }
        }

        if (upload.state === UploadState.COMPLETED) {
            return resolve(upload);
        }

        if (upload.state !== UploadState.PROCESSING) {
            return reject(new Error('Upload failed'));
        }
    });
}
