<script setup>
import { computed, ref, watch } from 'vue';

import { useStore } from '@/store';
import { useEmitter } from '@/plugins/emitter';
import axios from '@/axios';
import { useRouter } from 'vue-router';
import { useToast } from '@/plugins/toast';

const props = defineProps({
    conversation: {
        type: Object,
        required: true,
    },
});

const store = useStore();
const emitter = useEmitter();
const router = useRouter();
const toast = useToast();

const user = computed(() => {
    if (!props.conversation) {
        return null;
    }

    return props.conversation.with_me ? store.state.user : props.conversation.other_members[0].user;
});

const muted = ref(false);
const disableReadReceipts = ref(false);
let saveMutedTimeout = null;
let saveDisableReadReceiptsTimeout = null;

const toggleMuteConversation = () => {
    muted.value = !muted.value;

    clearTimeout(saveMutedTimeout);

    saveMutedTimeout = setTimeout(() => {
        axios.post(`/api/conversations/${props.conversation.id}/mute`, {
            value: muted.value ? 1 : 0,
        }).then(() => {
            try {
                emitter.emit('.conversation:updated', props.conversation);
            } catch (error) {
                console.error(error);
            }
        }).catch((error) => {
            muted.value = !muted.value;
            console.error(error);
            toast.handleAxiosError(error);
        });
    }, 1000);
}

const toggleReadReceipts = () => {
    disableReadReceipts.value = !disableReadReceipts.value;

    clearTimeout(saveDisableReadReceiptsTimeout);

    saveDisableReadReceiptsTimeout = setTimeout(() => {
        axios.post(`/api/conversations/${props.conversation.id}/disable-read-receipts`, {
            value: disableReadReceipts.value ? 1 : 0,
        }).then(() => {
            try {
                emitter.emit('.conversation:updated', props.conversation);
            } catch (error) {
                console.error(error);
            }
        }).catch((error) => {
            disableReadReceipts.value = !disableReadReceipts.value;
            console.error(error);
            toast.handleAxiosError(error);
        });
    }, 1000);
}

const goToProfile = (options = {}) => {
    return router.push({
        ...options,
        name: 'profiles.show',
        params: { username: user.value.profile.username },
    });
}

const blockUser = () => {
    confirm({
        title: `Block ${user.value.name}?`,
        description: 'Blocking this user will prevent them from connecting with or messaging you on corperland.',
    }).then(() => {
        axios.post('/api/blocked-users', {
            user: user.value.id,
        }).then(() => {
            emitter.emit('.conversation:updated', props.conversation);
            toast.success('User blocked');
        });
    });
}

const unblockUser = () => {
    confirm({
        title: `Unblock ${user.value.name}?`,
        description: 'Unblocking this user will allow them to connect with or message you on corperland.',
    }).then(() => {
        axios.delete(`/api/blocked-users/${user.value.id}`).then(() => {
            emitter.emit('.conversation:updated', props.conversation);
            toast.success('User unblocked');
        });
    });
}

const deleteConversation = () => {
    confirm({
        title: 'Delete conversation?',
        description: 'Deleting this conversation will remove it from your chats. All messages will be deleted for you.',
    }).then(() => {
        axios.post(`/api/conversations/${props.conversation.id}/delete`).then(() => {
            emitter.emit('.conversation:deleted', props.conversation);
            emitter.emit('.conversation:updated', props.conversation);
            router.replace({ name: 'conversations' });
        }).catch((error) => {
            console.error(error);
            toast.handleAxiosError(error);
        });
    });
}

watch(() => props.conversation?.current_member?.disable_read_receipts, (value) => {
    disableReadReceipts.value = value;
}, { immediate: true });

watch(() => props.conversation?.current_member?.muted, (value) => {
    muted.value = value;
}, { immediate: true });
</script>

<template>
    <app-layout>
        <app-layout-header>
            <app-top-nav>
                <app-top-nav-back :cancel-icon="$store.state.deviceType !== 'mobile'" />
            </app-top-nav>
        </app-layout-header>
        <app-layout-body>
            <template v-if="conversation">
                <div class="flex flex-col items-center pb-14">
                    <app-avatar @click="goToProfile" size="4xl" :src="user.profile.avatar?.url" :fallback-from="user.first_name" />
                    <button
                        aria-label="View Profile"
                        class="block mt-4"
                        type="button" @click="goToProfile">
                        <div class="font-bold text-2xl leading-tight">{{ user.name }}</div>
                        <div class="text-lg text-muted leading-tight">@{{ user.profile.username }}</div>
                    </button>
                </div>

                <div class="mt-4">
                    <div class="text-sm text-muted px-4 py-2">
                        Actions
                    </div>
                    <app-list>
                        <app-list-item @click="toggleMuteConversation" align-center>
                            <app-list-leading>
                                <icon-bell-off class="size-6" />
                            </app-list-leading>
                            <app-list-content headline="Mute notifications" />
                            <app-list-trailing switch :checked="muted" />
                        </app-list-item>
                        <app-list-item @click="toggleReadReceipts" align-center>
                            <app-list-leading>
                                <icon-read class="size-6" />
                            </app-list-leading>
                            <app-list-content headline="Disable read receipts" />
                            <app-list-trailing switch :checked="disableReadReceipts" />
                        </app-list-item>
                    </app-list>
                </div>

                <div class="mt-4">
                    <div class="text-sm text-muted px-4 py-2">
                        Privacy
                    </div>
                    <app-list>
                        <app-list-item v-if="!conversation.with_me" @click="user.block_received_from_me ? unblockUser() : blockUser()">
                            <app-list-leading>
                                <icon-ban class="size-6" />
                            </app-list-leading>
                            <app-list-content :headline="user.block_received_from_me ? 'Unblock user' : 'Block user'" />
                        </app-list-item>
                        <app-list-item class="text-danger" @click="deleteConversation">
                            <app-list-leading>
                                <icon-trash-can class="size-6" />
                            </app-list-leading>
                            <app-list-content headline="Delete conversation" />
                        </app-list-item>
                    </app-list>
                </div>
            </template>
        </app-layout-body>
    </app-layout>
</template>
