
import BaseModel from './base';

/**
 * @typedef {Object} Draft
 * @property {String} itemType
 * @property {String} itemId
 * @property {String|null} userId
 * @property {String} content
 */
class Draft extends BaseModel {
    static getTableName() {
        return 'drafts';
    }

    static _columns() {
        return {
            itemType: { type: 'string', notNull: true },
            itemId: { type: 'string' },
            userId: { type: 'string' },
            content: { type: 'object', notNull: true },
        }
    }
}

export default Draft;
