export class Query {
    constructor (table, dataMapper, pkName = 'id') {
        this.table = table;
        this.filter = {};
        this.dataMapper = dataMapper;
        this.pkName = pkName;

        return this;
    }

    async _connection() {
        const connection = await import('./index').then(module => module.getConnection());

        return connection;
    }

    _mapData(data) {
        if (!data) {
            return null;
        }

        return this.dataMapper ? this.dataMapper(data) : data;
    }

    _getFilters() {
        return Object.keys(this.filter).length > 0 ? this.filter : undefined;
    }

    where(...args) {
        if (args.length === 1 && typeof args[0] === 'object') {
            Object.assign(this.filter, args[0]);
        } else if (args.length === 2) {
            this.filter[args[0]] = args[1];
        } else {
            throw new Error('Invalid arguments');
        }

        return this;
    }

    async create(data) {
        const connection = await this._connection();

        const results = await connection.insert({
            into: this.table,
            values: [data],
            return: true,
        });

        return this._mapData(results[0]);
    }

    async createMany(data) {
        const connection = await this._connection();

        return connection.insert({
            into: this.table,
            values: data,
        });
    }

    async first() {
        const connection = await this._connection();

        const results = await connection.select({
            from: this.table,
            where: this._getFilters(),
            limit: 1,
        });

        if (results.length === 0) {
            return null;
        }

        return this._mapData(results[0]);
    }

    async find(pk) {
        return this.where(this.pkName, pk).first();
    }

    async get() {
        const connection = await this._connection();

        const results = await connection.select({
            from: this.table,
            where: this._getFilters(),
        });

        return results.map((result) => this._mapData(result));
    }

    async update(data) {
        const connection = await this._connection();

        return await connection.update({
            in: this.table,
            where: this._getFilters(),
            set: data,
        });
    }

    async delete() {
        const connection = await this._connection();

        return await connection.remove({
            from: this.table,
            where: this._getFilters(),
        });
    }

    async count() {
        const connection = await this._connection();

        return await connection.count({
            from: this.table,
            where: this._getFilters(),
        });
    }
}
