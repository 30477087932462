<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, ref } from 'vue';

import { resourceV2 } from '@/composables/resource';
import { useUtils } from '@/composables/chat';
import { deleteUploads, sendMessage } from '@/services/messaging';
import { useToast } from '@/plugins/toast';
import { useStore } from '@/store';
import { useEmitter } from '@/plugins/emitter';
import PendingMessage from '@/db/models/pending_message';

import ChatBox from '@/components/Secondary/Messaging/ChatBox.vue';
import ChatIntro from '@/components/Secondary/Messaging/ChatIntro.vue';
import ChatTitle from '@/components/Secondary/Messaging/ChatTitle.vue';

const route = useRoute();
const router = useRouter();
const toast = useToast();
const store = useStore();
const emitter = useEmitter();
const sending = ref(false);
const loadState = ref(null);

const user = resourceV2({
    url: `/api/users/${route.query.id}`,
});
const chatUtils = useUtils();

const onSubmit = async (form) => {
    const data = form.getData();
    const message = await PendingMessage.firstOrCreate({
        userId: store.state.user.id,
        conversationId: route.query.id,
    }, { data });

    sending.value = true;

    sendMessage(message, true).then(async (conversation) => {
        router.replace(`/conversations/${conversation.id}`);
        form.clearAll();
        emitter.emit('.conversation:updated', conversation);

        deleteUploads(message).then(() => {
            message.delete();
        });
    }).catch((error) => {
        console.error(error);
        if (error.response?.status == 400) {
            toast.error(error.response.data.message);
            return;
        }

        toast.error('Failed to send message');
    }).finally(() => {
        sending.value = false;
    });

    return false;
}

onBeforeMount(() => {
    if (route.query.id) {
        loadState.value = 'fetching';

        user.fetch().then(() => {
            if (user.data.conversation_with_me) {
                router.replace(`/conversations/${user.data.conversation_with_me.id}`);
            } else {
                loadState.value = 'ready';
            }
        }).catch((error) => {
            loadState.value = 'failed';
            console.error(error);
        });
    } else {
        loadState.value = 'failed';
    }
});
</script>

<template>
    <app-layout>
        <app-layout-header>
            <app-top-nav>
                <app-top-nav-back cancel-icon />
                <ChatTitle v-if="user.fetched && user.data" :user="user.data" new-chat />
                <app-top-nav-skeleton v-else-if="user.fetching" />
            </app-top-nav>
        </app-layout-header>
        <app-layout-body class="py-6 px-4 flex flex-col gap-6">
            <template v-if="loadState === 'ready'">
                <ChatIntro :user="user.data" />

                <div class="text-center text-sm md:max-w-[400px] mx-auto p-4 text-muted">
                    <p v-if="chatUtils.requiresChatRequest(user.data)">
                        {{ user.data.name }} is outside your connections.
                        They will need to accept your chat request.
                    </p>
                    <p v-else>
                        You're connected with {{ user.data.name }}. Feel free to start a conversation with them.
                    </p>
                </div>
            </template>
            <app-layout-skeleton v-else-if="loadState === 'fetching'" />
            <app-error-not-found v-else-if="user.notFound" />
            <app-error-default v-else />
        </app-layout-body>
        <app-layout-footer v-if="loadState === 'ready'">
            <app-form
                :submit-callback="onSubmit"
                draft-item-type="conversations.create"
                :draft-item-id="route.query.id"
            >
                <ChatBox :sending="sending" />
            </app-form>
        </app-layout-footer>
    </app-layout>
</template>
