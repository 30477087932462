<script setup>
import { computed, onBeforeMount, provide, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { register } from 'swiper/element/bundle';
import { DialogRoot, DialogPortal, DialogContent, DialogOverlay, DialogTitle, DialogDescription } from 'radix-vue';
import { collectionV2 } from '@/composables/resource';
import { mem_store } from '@/utils/cache';

import { useStore } from '@/store';

import VideoPost from '@/views/feed/VideoPost.vue';

register();

const route = useRoute();
const router = useRouter();
const store = useStore();
const miniSwiper = ref();
const swiper = ref();

const cache = mem_store('feed-videos');
const key = store.state.user?.id || 'guest';

const {
    posts,
    galleryMuted,
    activeIndex,
    galleryReady,
    initialMediaMuted,
} = cache.remember(key, () => {
    const posts = collectionV2({
        url: '/api/posts',
        params: {
            filters: {
                has_file_types: '2',
                load_file_types: '2',
                with_channel_image: true,
            },
            per_page: 10,
            order_by: 'top',
        },
    });

    const initialMediaMuted = ref(store.state.mediaMuted);
    const galleryReady = ref(false);
    const activeIndex = ref(0);
    const galleryMuted = ref(false);

    return {
        posts,
        galleryMuted,
        activeIndex,
        galleryReady,
        initialMediaMuted,
    };
});



const onGalleryActiveIndexChange = (event) => {
    if (!swiper.value?.swiper) {
        return;
    }

    if (swiper.value.swiper.el !== event.target.swiper.el) {
        return;
    }

    const sw = event.target.swiper;

    if (sw.isEnd && posts.links.next) {
        posts.nextCursor();
    }

    activeIndex.value = sw.activeIndex;

    miniSwiper.value.swiper.slideTo(sw.activeIndex);
}

const openGallery = (index) => {
    activeIndex.value = index;

    router.push({
        query: {
            ...route.query,
            videos: true,
        },
    });
}

const closeGallery = () => {
    router.replace({
        query: {
            ...route.query,
            videos: undefined,
        },
    });
}

const galleryOpen = computed(() => {
    return 'videos' in route.query && galleryReady.value;
});

watch(() => galleryOpen.value, (value) => {
    if (value) {
        initialMediaMuted.value = store.state.mediaMuted;
        store.commit('mediaMuted', true);
    } else {
        store.commit('mediaMuted', initialMediaMuted.value);
    }
});

onBeforeMount(() => {
    if (galleryReady.value) {
        return;
    }

    if ('videos' in route.query) {
        router.replace({
            query: {
                ...route.query,
                videos: undefined,
            },
        });
    }

    galleryReady.value = true;

    posts.fetch();
});

provide('galleryMuted', galleryMuted);
</script>

<template>
    <div class="bg-white text-white-foreground py-4 px-4 rounded-md border border-white-foreground/10" v-if="posts.data.length">
        <div class="mb-2 flex items-center gap-1">
            <icon-library-video class="size-5" />
            <h2 class="text-sm font-medium">Videos</h2>
        </div>

        <div class="py-4">
            <swiper-container
                ref="miniSwiper"
                class="w-full"
                space-between="10"
                slides-per-view="auto"
                :free-mode="true"
                :initial-slide="activeIndex"
            >
                <swiper-slide class="w-[150px] h-[250px]" v-for="post, index in posts.data" :key="post.id">
                    <button @click="openGallery(index)" class="block text-left size-full border border-white-foreground/10 rounded-2xl">
                        <app-video-player :src="post.files[0].url" fit="cover" loop :end="5" :play="!galleryOpen" />
                    </button>
                </swiper-slide>
            </swiper-container>
        </div>

        <DialogRoot :open="galleryOpen" @update:open="closeGallery">
            <DialogPortal>
                <DialogOverlay class="
                    bg-black/20 animate-in duration-300 data-[state=closed]:animate-out
                    data-[state=open]:fade-in data-[state=closed]:fade-out
                    fixed inset-0 z-[100]
                " />
                <DialogContent
                    @openAutoFocus="(e) => e.preventDefault()"
                    class="
                        fixed focus:outline-none z-[100] flex flex-col bg-[#000000] text-[#f2f2f2]
                        max-md:top-0 max-md:right-0 md:top-1/2 md:left-1/2
                        md:-translate-x-1/2 md:-translate-y-1/2
                        h-screen md:h-[800px] md:max-h-[80vh]
                        w-screen md:w-[450px]
                    "
                >
                    <DialogTitle class="sr-only">Videos</DialogTitle>
                    <DialogDescription class="sr-only">Top videos from channels you are a member of</DialogDescription>
                    <div class="z-10 absolute top-0 left-0 w-full h-[60px] flex items-center px-2">
                        <app-icon-button icon="arrow-back" color="raw-white" @click="closeGallery" />

                        <div class="ml-1 font-semibold">Videos</div>
                        <app-icon-button
                            class="ml-auto"
                            :icon="galleryMuted ? 'volume-off' : 'volume-high'"
                            color="raw-white"
                            @click="galleryMuted = !galleryMuted"
                        />
                    </div>
                    <swiper-container
                        ref="swiper"
                        slides-per-view="1"
                        class="size-full"
                        @swiperactiveindexchange="onGalleryActiveIndexChange"
                        direction="vertical"
                        :initial-slide="activeIndex"
                    >
                        <swiper-slide v-for="post, index in posts.data" :key="post.id">
                            <VideoPost :post="post" :active="activeIndex === index" />
                        </swiper-slide>
                    </swiper-container>
                </DialogContent>
            </DialogPortal>
        </DialogRoot>
    </div>
</template>
