<script setup>
import { computed, inject, watch } from "vue";

import { useWindowScroll } from "@/composables/window-scroll";

import Badge from '@/components/Badge.vue';
import IconCaretDown from '@/components/Icons/CaretDown.vue';

const feed = inject('feed');
const windowScroll = useWindowScroll();

const scrollToBottom = () => {
    let element = null;

    if (feed && feed.newPosts.length > 0) {
        const earliestPost = feed.newPosts[feed.newPosts.length - 1];
        element = document.querySelector(`[data-post-observer="${earliestPost}"]`);
    }

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    } else {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    }
};

const showScrollButton = computed(() => {
    if (feed && feed.newPosts.length > 0) {
        return true;
    }

    return windowScroll.bottom > 100 && windowScroll.direction === 'down';
});

watch(() => showScrollButton.value, (value) => {
    // clearTimeout(removeScrollButtonTimeout);
    // removeScrollButtonTimeout = setTimeout(() => {
    //     removeScrollButton.value = !value;
    // }, 1000);
});
</script>

<style scoped>
.scroll-to-bottom-enter-from,
.scroll-to-bottom-leave-to {
    opacity: 0;
}

.scroll-to-bottom-enter-active,
.scroll-to-bottom-leave-active {
    transition: opacity 0.35s;
    transition-delay: 1s;
}
</style>

<template>
    <div>
        <Transition name="scroll-to-bottom" :duration="1350">
            <button
                v-show="showScrollButton"
                aria-label="Scroll to bottom"
                @click.prevent="scrollToBottom"
                :disabled="!showScrollButton"
                v-fixed="{ left: '47%', bottom: '14px', to: 'view-content' }"
                class="
                    size-10 rounded-full bg-gray border border-gray-foreground/10 shadow-sm
                    text-gray-foreground/80 flex items-center justify-center z-50
                "
            >
                <Badge v-if="feed" color="primary" :count="feed.newPosts.length" size="sm" class="absolute -top-2 -right-2" />
                <IconCaretDown class="size-10" />
            </button>
        </Transition>
    </div>
</template>
