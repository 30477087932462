<script setup>
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { useSettings } from '@/settings';

import BlockUser from '@/views/dialogs/BlockUser.vue';
import ReportContent from '@/views/dialogs/ReportContent.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import NotificationPermissionPrompt from '@/components/Permissions/NotificationPermissionPrompt.vue';

const props = defineProps({
    pageData: {
        type: Object,
        required: true,
    }
});

const routeBind = (route) => {
    return {
        ...route.params,
        pageData: props.pageData[route.path] ?? undefined,
    };
};

const settings = useSettings();
const router = useRouter();

const setDarkMode = () => document.documentElement.dataset.darkMode = settings.darkMode.toString();

onBeforeMount(() => {
    setDarkMode();
    settings.onUpdateDarkMode(() => {
        setDarkMode();
    });
});
</script>

<style lang="postcss">
body {
    @apply bg-white text-white-foreground;
}
</style>
<template>
    <div>
        <router-view v-slot="{ Component, route }">
            <Component :is="Component" :route-bind="routeBind(route)" />
            <CustomAlert />

            <NotificationPermissionPrompt />
            <LoadingScreen :open="$store.state.loading" />
            <ReportContent />
            <BlockUser />
        </router-view>
    </div>
</template>
