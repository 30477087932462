<script setup>
import { ref, reactive, provide, watch, computed } from 'vue';

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    muted: {
        type: Boolean,
        default: true,
    },
    loop: {
        type: Boolean,
        default: false,
    },
    autoplay: {
        type: Boolean,
        default: true,
    },
    start: {
        type: Number,
        default: null,
    },
    end: {
        type: Number,
        default: null,
    },
    fit: {
        type: String,
        default: 'contain',
    },
    play: {
        default: 'unset',
        type: [Boolean, String],
    },
    noStyling: {
        type: Boolean,
        default: false,
    },
    poster: {
        type: String,
        default: null,
    },
});

const player = ref(null);

const state = reactive({
    buffered: null,
    currentTime: null,
    duration: null,
    ended: null,
    error: null,
    muted: null,
    networkState: null,
    paused: true,
    readyState: null,
    volume: null,
});

const mediaPlayer = {
    get readyState() {
        return state.readyState;
    },
    get networkState() {
        return state.networkState;
    },
    get buffered() {
        return state.buffered;
    },
    get error() {
        return state.error;
    },
    get muted() {
        return state.muted;
    },
    set muted(value) {
        if (!player.value) {
            return;
        }

        player.value.muted = value;
    },
    get volume() {
        return state.volume;
    },
    set volume(value) {
        player.value.volume = value;
    },
    get currentTime() {
        return state.currentTime;
    },
    set currentTime(value) {
        player.value.currentTime = value;
    },
    get duration() {
        return state.duration;
    },
    get paused() {
        return state.paused;
    },
    play: () => {
        if (!player.value) {
            return;
        }

        const clipSet = props.start !== undefined || props.end !== undefined;
        const start = props.start || 0;
        const end = props.end || player.value.duration;

        if (clipSet && end > start && player.value.currentTime >= end && player.value.currentTime !== start) {
            player.value.currentTime = start;
        }

        player.value.play();
    },
    toggleMute() {
        player.value.muted = !player.value.muted;
    },
    togglePlay() {
        if (this.paused) {
            return this.play();
        }

        return this.pause();
    },
    pause: () => {
        if (player.value) {
            player.value.pause();
        }
    },
    togglePlay() {
        if (this.paused) {
            this.play();
        } else {
            this.pause();
        }
    },
    get videoElement() {
        return player.value;
    },
}

defineExpose(mediaPlayer);

provide('mediaPlayer', mediaPlayer);

const onVolumeChange = (e) => {
    state.muted = e.target.muted;
    state.volume = e.target.volume;
};

const onPlay = (e) => {
    state.paused = e.target.paused;
}

const onPause = (e) => {
    state.paused = e.target.paused;
}

const onTimeUpdate = (e) => {
    const clipSet = props.start !== undefined || props.end !== undefined;
    const start = props.start || 0;
    const end = props.end || e.target.duration;

    if (clipSet && end > start && e.target.currentTime >= end && !e.target.paused) {
        e.target.pause();
        e.target.currentTime = props.loop ? start : end;

        if (props.loop) {
            e.target.play();
        }
    }

    state.currentTime = e.target.currentTime;
}

const onLoadedMetadata = (e) => {
    state.duration = e.target.duration;
    updateLoadState(e);
}

const updateLoadState = (e) => {
    state.buffered = e.target.buffered;
    state.error = e.target.error;
    state.networkState = e.target.networkState;
    state.readyState = e.target.readyState;
}

const getFitStyle = computed(() => {
    if (props.fit === 'contain') {
        return 'object-contain';
    }

    if (props.fit === 'cover') {
        return 'object-cover';
    }

    return 'object-' + props.fit;
});

watch(() => props.src, (newSrc) => {
    if (player.value) {
        player.value.src = newSrc;
        player.value.load();
    }
});

watch(() => props.muted, (newMuted) => {
    mediaPlayer.muted = newMuted;
});

watch(() => props.play, (play) => {
    if (play === 'unset') {
        return;
    }

    if (play === true) {
        if (mediaPlayer.readyState < 1) {
            let totalAttempts = 0;
            let interval = setInterval(() => {
                totalAttempts++;
                if (totalAttempts > (10 * 60)) {
                    // stop trying after 60 seconds
                    clearInterval(interval);
                    return;
                }

                if (mediaPlayer.readyState < 1) {
                    return;
                }
                mediaPlayer.play();
                clearInterval(interval);
            }, 100);
        } else {
            mediaPlayer.play();
        }
    } else if (play === false) {
        mediaPlayer.pause();
    }
}, { immediate: true });

</script>

<template>
    <div :class="{
        'h-full w-full rounded-[inherit]': !noStyling,
    }">
        <video
            :class="{
                'block h-full w-full object-contain pointer-events-none rounded-[inherit]': !noStyling,
            }"
            :poster="poster"
            ref="player"
            :muted="muted"
            :loop="loop"
            playsinline
            :autoplay="autoplay"
            @volumechange="onVolumeChange"
            @play="onPlay"
            @pause="onPause"
            @loadedmetadata="onLoadedMetadata"
            @timeupdate="onTimeUpdate"
            @error="updateLoadState"
            @loadeddata="updateLoadState"
            @loadstart="updateLoadState"
            @progress="updateLoadState"
            @stalled="updateLoadState"
            @waiting="updateLoadState"
        >
            <source :src="src">
        </video>
        <slot></slot>
    </div>
</template>
