<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    lines: {
        type: [Number, String],
        default: 5,
    },
    hideReadMore: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: 'text-primary',
    }
});

const clampElement = ref(null);
const clampable = ref(false);
const clamped = ref(true);

const readMoreColor = computed(() => {
    const map = {
        'primary': 'text-primary',
        'primary-foreground': 'text-primary-foreground',
        'muted': 'text-muted',
        'danger': 'text-danger',
        'white': 'text-white',
        'white-foreground': 'text-white-foreground',
        'gray': 'text-gray',
        'gray-foreground': 'text-gray-foreground',
    }

    return map[props.color] || 'text-primary';
});

const clampStyle = computed(() => {
    if (!clamped.value) {
        return {};
    }

    return {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': props.lines,
    };
});

onMounted(() => {
    clampable.value = clampElement.value.scrollHeight > clampElement.value.clientHeight;
})

</script>

<template>
    <div>
        <div :class="{
            'cursor-pointer' : clampable && clamped,
        }" ref="clampElement" :style="clampStyle" @click="() => {
            if (clampable && clamped) {
                clamped = false;
            }
        }">
            <slot></slot>
        </div>
        <button v-if="clampable && !hideReadMore" class="block w-full text-left" type="button" @click.prevent.stop="clamped = !clamped">
            <span class="font-medium text-sm" :class="readMoreColor">
                {{ clamped ? 'Read more' : 'Read less' }}
            </span>
        </button>
    </div>
</template>
