<script setup>
import { ref } from 'vue';
import CropperCanvas from '@cropper/element-canvas';
import CropperImage from '@cropper/element-image';
import CropperHandle from '@cropper/element-handle';
import CropperSelection from '@cropper/element-selection';


CropperCanvas.$define();
CropperImage.$define();
CropperHandle.$define();
CropperSelection.$define();

const emit = defineEmits(['done']);

const props = defineProps({
    file: {
        type: Object,
        required: true,
    },
});

const data = ref(null);
const cropperSelection = ref();

const actions = ref([
    {
        icon: 'cancel',
        label: 'Cancel',
        onClick: () => {
            data.value = null;
            emit('done', data.value);
        },
    },
    {
        icon: 'crop',
        label: 'Crop',
        selected: true,
        onClick: () => {
            emit('done', data.value);
        },
    },
    {
        icon: 'check',
        label: 'Done',
        onClick: () => {
            emit('done', data.value);
        },
        disabled: data.value === null,
    },
]);

const onCropperSelectionChange = (event) => {
    //
}
</script>

<template>
    <app-layout>
        <app-layout-header>
            <app-top-nav>
                <app-top-nav-title>Crop Image</app-top-nav-title>
            </app-top-nav>
        </app-layout-header>
        <app-layout-body>
            <div class="flex items-center justify-center h-full">
                <cropper-canvas class="size-full" background>
                    <cropper-image :src="file.url" rotatable scalable skewable translatable></cropper-image>
                    <cropper-selection
                        ref="cropperSelection"
                        initial-coverage="0.5"
                        movable
                        resizable
                        outlined
                        @change="onCropperSelectionChange"
                    >
                        <cropper-grid
                        role="grid"
                        covered
                        />
                        <cropper-crosshair centered />
                        <cropper-handle
                        action="move"
                        theme-color="rgba(255, 255, 255, 0.35)"
                        />
                        <cropper-handle action="n-resize" />
                        <cropper-handle action="e-resize" />
                        <cropper-handle action="s-resize" />
                        <cropper-handle action="w-resize" />
                        <cropper-handle action="ne-resize" />
                        <cropper-handle action="nw-resize" />
                        <cropper-handle action="se-resize" />
                        <cropper-handle action="sw-resize" />
                    </cropper-selection>
                </cropper-canvas>
                <!-- <img :src="file.url" class="block h-full w-auto object-contain select-none pointer-events-none" /> -->
            </div>
        </app-layout-body>
        <app-layout-footer>
            <div class="flex items-center justify-between w-full gap-2">
                <div v-for="action in actions" :key="action.label" class="flex flex-col items-center justify-center">
                    <app-icon-button
                        @click="action.onClick"
                        :icon="action.icon"
                        :aria-label="action.label"
                        :disabled="action.disabled"
                        :selected="action.selected"
                    />
                </div>
            </div>
        </app-layout-footer>
    </app-layout>
</template>

