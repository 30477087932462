<script setup>
import { computed, inject } from 'vue';

const player = inject('mediaPlayer');

const props = defineProps({
    size: {
        type: String,
        default: 'md',
    }
});

const sizeClass = computed(() => {
    if (props.size === 'xl') {
        return 'size-12';
    }

    if (props.size === 'lg') {
        return 'size-8';
    }

    return 'size-6';
});
</script>

<template>
    <button @click.stop.prevent="player.togglePlay()" aria-label="Toggle play/pause">
        <slot :paused="player.paused">
            <component :is="player.paused ? 'icon-play' : 'icon-pause'" :class="[sizeClass]" aria-hidden="true" />
        </slot>
    </button>
</template>
