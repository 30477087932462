<script setup>

import { collectionV2, resourceV2 } from '@/composables/resource';

import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import { onBeforeMount } from 'vue';

const props = defineProps({
    category: {
        required: true,
        type: String,
    }
});

const category = resourceV2({
    url: `/api/information-hub-categories/${props.category}`,
});

const posts = collectionV2({
    url: '/api/information-hub-posts',
    params: {
        filters: {
            'category.slug': props.category,
        }
    }
});

onBeforeMount(() => {
    category.fetch().then(() => {
        posts.fetch();
    });
});

</script>

<template>
    <Content>
        <head-title v-if="category.data" :value="`${category.data.name} | Information Hub`" />
        <Header with-back-button>
            <HeaderTitle>Information Hub</HeaderTitle>
        </Header>
        <div v-if="category.data" class="p-4">
            <div class="text-lg font-medium">{{ category.data.name }}</div>
            <p v-if="category.data.help_text" class="mt-2 text-sm">
                {{ category.data.help_text }}
            </p>
        </div>

        <div v-if="!posts.fetched && posts.fetching" class="flex flex-col gap-4">
            <div v-for="i in 5" :key="i" class="h-14 w-full bg-gray animate-pulse"></div>
        </div>

        <app-list v-else-if="posts.data.length > 0">
                <app-list-item
                    align-center bordered
                    v-for="post in posts.data"
                    :key="post.id"
                    target-as="router-link"
                    :target-bind="{ to: { name: 'information-hub.article', params: { id: post.id } } }">
                    <app-list-content
                        :headline="post.title"
                        headline-max-lines="3"
                    />
                    <app-list-trailing>
                        <icon-chevron-right class="size-5" />
                    </app-list-trailing>
                </app-list-item>
            <InfiniteScrollObserver :collection="posts" />
        </app-list>

        <router-view v-slot="{ Component, route }">
            <component :is="Component" v-bind="route.params" />
        </router-view>
    </Content>
</template>
