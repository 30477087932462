<script setup>
import { computed, inject, ref } from 'vue';
import { FileType, FileStatus } from '@/enums';
import { createUpload } from '@/services/upload';

import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import MediaEditor from '@/components/Secondary/File/MediaEditor.vue';
import MediaPreviewDialog from '@/components/Secondary/File/MediaPreviewDialog.vue';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: 'Upload Files',
    },
    accept: {
        type: Array,
        default: () => ['audio', 'image', 'video', 'document'],
    },
    multiple: {
        type: Boolean,
        default: true,
    },
    max: {
        type: Number,
        default: 10,
    },
    buttonClass: {
        type: String,
        required: false,
    },
    isPrivate: {
        type: Boolean,
        default: false,
    },
});

const acceptedTypes = {
    'audio': 'audio/mp3,audio/wav,audio/m4a',
    'image': 'image/jpeg,image/png,image/webp,image/heic',
    'video': 'video/mp4,video/avi,video/mpeg,video/quicktime,video/webm,video/mkv,video/flv,video/3gp',
    'document': 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const types = computed(() => {
    return [
        {
            key: 'media',
            accept: acceptedTypes['image'] + ',' + acceptedTypes['video'],
            label: 'Media',
            icon: 'icon-perm-media',
            iconColor: 'skyblue',
            visible: props.accept.includes('image') || props.accept.includes('video'),
            onSelect() {
                openSelector(this.accept)
                    .then(files => {
                        selectedFiles.value = files;
                        onSave();
                    });
            },
        },
        {
            key: 'document',
            accept: acceptedTypes['document'],
            label: 'Document',
            icon: 'icon-document',
            iconColor: 'green',
            visible: props.accept.includes('document'),
            onSelect() {
                openSelector(this.accept)
                    .then(files => {
                        selectedFiles.value = files;
                        onSave();
                    });
            },
        },
        // @TODO: Add audio support. support needs to be added to the UI that displays the audio file.
        // {
        //     key: 'audio',
        //     accept: acceptedTypes['audio'],
        //     label: 'Audio',
        //     icon: 'icon-audio',
        //     iconColor: 'orange',
        //     visible: props.accept.includes('audio'),
        //     onSelect() {
        //         openSelector(this.accept)
        //             .then(files => {
        //                 selectedFiles.value = files;
        //                 onSave();
        //             });
        //     },
        // },
    ];
});

const mediaType = computed(() => {
    return types.value.find(type => type.key === 'media');
});

const form = inject('form');
const selectedFiles = ref([]);
const showMediaEditor = ref(false);

const maxExceeded = computed(() => {
    if (!props.multiple) {
        return false;
    }

    if (!form) {
        return false;
    }

    const currentValue = form.get(props.name);

    if (!currentValue) {
        return false;
    }

    return currentValue.length >= props.max;
});

const disableAddButton = computed(() => {
    if (!props.multiple) {
        return true;
    }

    if (!form) {
        return false;
    }

    const currentValue = form.get(props.name);

    return ((currentValue?.length || 0) + selectedFiles.value.length) >= props.max;
});

const openSelector = (accept = null) => {
    return new Promise((resolve, reject) => {
        const picker = document.createElement('input');
        picker.type = 'file';
        picker.multiple = props.multiple;
        picker.accept = accept;
        picker.onchange = (e) => {
            if (e.target.files.length === 0) {
                reject('No files selected');
                return;
            }

            const files = [];

            for (const file of e.target.files) {
                files.push({
                    url: URL.createObjectURL(file),
                    original_url: URL.createObjectURL(file),
                    value: file,
                    crop: null,
                    clip: null,
                });
            }

            picker.remove();

            resolve(files);
        };

        picker.click();
    });

}

const onSave = () => {
    return new Promise(async (resolve) => {
        const attachments = [];

        for (const file of selectedFiles.value) {
            const attachment = {
                id: null,
                url: file.url,
                size: file.value.size,
                original_name: file.value.name,
                mime_type: file.value.type,
                type: FileType.fromMimeType(file.value.type),
                is_public: !props.isPrivate,
                status: FileStatus.PENDING,
                __client: {
                    upload_id: null,
                    original_url: file.original_url,
                }
            };

            const upload = await createUpload(file.value, {
                is_private: props.isPrivate,
                crop: file.crop,
                clip: file.clip,
                original_name: attachment.original_name,
            });

            attachment.__client.upload_id = upload.id;

            attachments.push(attachment);
        }

        if (!props.multiple) {
            form.update(props.name, attachments[0]);
        } else {
            form.push(props.name, ...attachments);
        }

        resolve();
    });
}

const addMedia = () => {
    return new Promise(async (resolve) => {
        await openSelector(mediaType.value.accept)
            .then((files) => {
                selectedFiles.value.push(...files);
                resolve();
            });
    });
}
</script>

<template>
    <div>
        <button v-if="types.length === 1" class="flex items-center justify-center"
            :aria-label="label"
            type="button"
            :disabled="maxExceeded"
            @click="types[0].onSelect()">
            <slot></slot>
        </button>
        <DropdownMenu v-else :items="types" content-align="end" :side-offset="4">
            <DropdownMenuTrigger
                class="flex items-center justify-center"
                :aria-label="label"
                type="button"
                :disabled="maxExceeded">
                <slot></slot>
            </DropdownMenuTrigger>
        </DropdownMenu>
        <MediaPreviewDialog :open="showMediaEditor" @update:open="showMediaEditor = $event">
            <MediaEditor
                :saveCallback="onSave"
                :addCallback="addMedia"
                @close="showMediaEditor = false"
                :files="selectedFiles"
                v-model:files="selectedFiles"
                :disable-add-button="disableAddButton"
                :hide-delete-button="!props.multiple"
                :hide-add-button="!props.multiple"
                :hide-thumbs="!props.multiple"
                edit-mode
            />
        </MediaPreviewDialog>
    </div>
</template>
