<script setup>
import { computed } from 'vue';

import ConversationListItem from '@/components/Secondary/Messaging/ConversationListItem.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

const props = defineProps({
    conversations: {
        required: true,
        type: Object,
    },
});

const sorted = computed(() => {
    return props.conversations.data.sort((a, b) => {
        return new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at);
    });
});
</script>

<template>
    <div>
        <Skeleton :collection="conversations" />
        <app-list v-if="conversations.fetched && conversations.data.length > 0">
            <ConversationListItem v-for="conversation in sorted" :key="conversation.id" :conversation="conversation" />
        </app-list>
        <InfiniteScrollObserver :collection="conversations" />
    </div>
</template>
