<script setup>
import { getFriendlyFileSize } from "@/utils";
import { computed } from "vue";

import { UploadState } from '@/enums';
import { openInNewTab } from '@/utils';
import { uploads } from '@/services/upload';
import { mime2ext } from '@/utils/file';

const props = defineProps({
    document: {
        type: Object,
        required: true,
    },
});

const documentType = computed(() => {
    if (props.document.extension) {
        return props.document.extension;
    }

    return mime2ext(props.document.mime_type);
});

const upload = computed(() => {
    if (!props.document.__client?.upload_id) {
        return null;
    }

    return uploads[props.document.__client?.upload_id];
});
</script>

<template>
    <button
        class="text-left min-h-16 w-full rounded-md border-white-foreground/10 border flex items-center gap-2 p-2 py-2"
        @click="openInNewTab(document.url)"
        aria-label="open document"
        >
        <component :is="documentType === 'pdf' ? 'icon-pdf-box' : 'icon-file-word'" class="size-10 shrink-0" :class="{
            'text-danger': documentType === 'pdf',
            'text-info': documentType !== 'pdf',
        }" />
        <div class="flex flex-col min-w-0">
            <span class="text-sm truncate">{{ document.original_name }}</span>
            <span class="text-xs text-muted uppercase">{{ documentType }} • {{ getFriendlyFileSize(document.size) }}</span>
            <div v-if="upload" class="text-xs text-muted">
                <span v-if="upload.state == UploadState.IN_PROGRESS">{{ upload.progress }}%</span>
                <span v-else-if="upload.state == UploadState.PROCESSING" class="animate-pulse">Processing...</span>
                <span class="text-danger" v-else-if="upload.state == UploadState.FAILED">Upload Failed</span>
            </div>
        </div>
    </button>
</template>
