import { computed, reactive } from "vue";

import { UploadState } from "@/enums";
import { uploads } from "@/services/upload";

export const useAttachmentProgress = (attachments) => {
    const ids = computed(() => {
        if (Array.isArray(attachments)) {
            return attachments.map(attachment => {
                if (typeof attachment === 'string' || typeof attachment === 'number') {
                    return attachment;
                }

                return attachment.__client?.upload_id;
            }).filter(Boolean);
        }

        if (typeof attachments === 'string' || typeof attachments === 'number') {
            return [attachments];
        }

        return [attachments.__client?.upload_id].filter(Boolean);
    });

    const attachmentUploads = computed(() => {
        return ids.value.map(id => uploads[id]).filter(Boolean);
    });

    const failedUploads = computed(() => {
        return attachmentUploads.value.filter(upload => upload.state == UploadState.FAILED);
    });

    const pendingUploads = computed(() => {
        return attachmentUploads.value.filter(upload => upload.state == UploadState.PENDING);
    });

    const inProgressUploads = computed(() => {
        return attachmentUploads.value.filter(upload => upload.state == UploadState.IN_PROGRESS);
    });

    const processingUploads = computed(() => {
        return attachmentUploads.value.filter(upload => upload.state == UploadState.PROCESSING);
    });

    const completedUploads = computed(() => {
        return attachmentUploads.value.filter(upload => upload.state == UploadState.COMPLETED);
    });

    const abortedUploads = computed(() => {
        return attachmentUploads.value.filter(upload => upload.state == UploadState.ABORTED);
    });

    const progress = computed(() => {
        if (attachmentUploads.value.length === 0) {
            return 0;
        }

        return attachmentUploads.value.reduce((acc, upload) => {
            return acc + upload.progress;
        }, 0) / attachmentUploads.value.length;
    });

    const state = computed(() => {
        if (abortedUploads.value.length > 0) {
            return UploadState.ABORTED;
        }

        if (failedUploads.value.length > 0) {
            return UploadState.FAILED;
        }

        if (pendingUploads.value.length > 0) {
            return UploadState.PENDING;
        }

        if (inProgressUploads.value.length > 0) {
            return UploadState.IN_PROGRESS;
        }

        if (processingUploads.value.length > 0) {
            return UploadState.PROCESSING;
        }

        return UploadState.COMPLETED;
    })

    return reactive({
        state,
        uploads: attachmentUploads,
        progress,
    })
}
