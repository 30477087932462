<script setup>
import { ref, onMounted, computed } from 'vue';
import { RouterLink } from 'vue-router';
import { markRaw } from 'vue';

import { useStore } from '@/store';
import { useJoinedChannels } from '@/composables/channel';
import { useToast } from '@/plugins/toast';

import Avatar from '@/components/Avatar.vue';
import Badge from '@/components/Badge.vue';
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';
import IconBell from '@/components/Icons/Bell.vue';
import IconBellOutline from '@/components/Icons/BellOutline.vue';
import IconHashtagBox from '@/components/Icons/HashtagBox.vue';
import IconHashtagBoxOutline from '@/components/Icons/HashtagBoxOutline.vue';
import IconHomeVariant from '@/components/Icons/HomeVariant.vue';
import IconHomeVariantOutline from '@/components/Icons/HomeVariantOutline.vue';
import IconLogout from '@/components/Icons/Logout.vue';
import IconPencilPlusOutline from '@/components/Icons/PencilPlusOutline.vue';
import IconStoreFront from '@/components/Icons/StoreFront.vue';
import IconStoreFrontOutline from '@/components/Icons/StoreFrontOutline.vue';
import IconMessageText from '@/components/Icons/MessageText.vue';
import IconMessageTextOutline from '@/components/Icons/MessageTextOutline.vue';
import IconMoreCircleOutline from '@/components/Icons/MoreCircleOutline.vue';
import IconSettingsOutline from '@/components/Icons/SettingsOutline.vue';
import IconListStatus from '@/components/Icons/ListStatus.vue';
import IconAboutCircleOutline from '@/components/Icons/AboutCircleOutline.vue';
import IconShieldLockOutline from '@/components/Icons/ShieldLockOutline.vue';
import IconUsersGroupOutline from '@/components/Icons/UsersGroup.vue';
import ProfileLink from '@/components/Link/ProfileLink.vue';
import IconShare from '@/components/Icons/Share.vue';

const store = useStore();
const toast = useToast();
const isDarkMode = ref(false);
const joinedChannels = useJoinedChannels();

const moreItems = ref([
    {
        to: { name: 'connections.index' },
        icon: markRaw(IconUsersGroupOutline),
        label: 'Connections',
    },
    {
        to: { name: 'settings.index' },
        icon: markRaw(IconSettingsOutline),
        label: 'Settings',
    },
    {
        to: { name: 'guidelines' },
        icon: markRaw(IconListStatus),
        label: 'Community Guidelines',
    },
    {
        to: { name: 'about' },
        icon: markRaw(IconAboutCircleOutline),
        label: 'About Corperland',
    },
    {
        to: { name: 'agreements.privacy-policy' },
        icon: markRaw(IconShieldLockOutline),
        label: 'Privacy Policy',
    },
    {
        icon: markRaw(IconShare),
        label: store.state.user.is_ambassador ? 'Referral Link' : 'Share/Invite Friends',
        onSelect: () => {
            const referralCode = store.state.user.referral_code || '';
            const ambassadorReferralLink = referralCode ? `${import.meta.env.VITE_APP_URL}/amb/${referralCode}` : import.meta.env.VITE_APP_URL;
            const linkToCopy = store.state.user.is_ambassador ? ambassadorReferralLink : import.meta.env.VITE_APP_URL;

            navigator.clipboard.writeText(linkToCopy).then(() => {
                const message = store.state.user.is_ambassador
                    ? 'Referral link copied.'
                    : 'Share link copied.';
                toast.info(message);
            }).catch((error) => console.error('Failed to copy link:', error));
        }
    },
    {
        to: { name: 'logout' },
        icon: markRaw(IconLogout),
        label: 'Logout',
    },
]);

const checkDarkMode = () => {
  isDarkMode.value = document.documentElement.getAttribute('data-dark-mode') === 'true';
};

const unseenPostsCount = computed(() => {
    if (!joinedChannels.fetched) {
        return 0;
    }

    return joinedChannels.data.filter(channel => channel.user_membership?.total_unseen_posts).length;
});

onMounted(() => {
  checkDarkMode();
  const observer = new MutationObserver(() => {
    checkDarkMode();
  });
  observer.observe(document.documentElement, { attributes: true, attributeFilter: ['data-dark-mode'] });
});

const fixedConfig = computed(() => {

    if (store.state.deviceType === 'mobile') {
        return {
            bottom: 0,
            width: '100%',
            to: 'view-content',
        }
    }

    return {
        top: 0,
    }
});
</script>

<style lang="postcss">
.nav {
    height: 60px;
    @apply w-full px-2;

    @screen md {
        width: 60px;
        @apply h-screen border border-primary;
    }

    @screen xl {
        width: 250px;
        @apply px-10;
    }
}

.nav+* {
    @apply max-sm:pb-16;
}

.nav {
    @apply bg-white text-white-foreground shrink-0 border-white-foreground/10;

    @media (max-width: 767.9px) {
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
    }

    @screen md {
        @apply flex items-center flex-col;
    }

    @screen xl {
        @apply items-start border-l border-t;
    }

    .nav__items {
        @apply flex items-center justify-between gap-4 h-full;
        @screen md {
            @apply flex-col justify-start items-start h-auto gap-8 mt-16;
        }
        @screen xl {
            @apply w-full;
        }
    }

    .nav__item {
        @apply grow md:w-full;
    }

    .nav__menu {
        @apply flex flex-col items-center text-white-foreground text-left w-full;
        @screen md {
            @apply flex-row gap-4;
        }
    }

    .nav__menu[data-active=true] .nav__icon, .nav__menu[data-active=true] .nav__text {
        @apply text-primary font-bold;
    }

    .nav__icon {
        width: 28px;
        height: 28px;
        @apply transition-colors duration-1000;
        @screen md {
            width: 32px;
            height: 32px;
        }
    }

    .nav__text {
        @apply text-2xs transition-colors duration-1000 font-medium;
        @apply sr-only;

        @screen xl {
            @apply not-sr-only text-base leading-none;
        }
    }
}
</style>

<template>
    <div>
        <div class="md:w-[60px] xl:w-[250px]"></div>
        <nav class="nav z-50" v-fixed="fixedConfig">
            <RouterLink v-if="$store.state.deviceType !== 'mobile'" class="block mt-16" :to="{ name: 'feed' }">
                <img class="xl:hidden" src="@images/static/icon-32x32.svg" alt="site icon" />
                <img v-if="isDarkMode" class="hidden xl:block" src="@images/static/logo-dark.svg" alt="site logo" />
                <img v-if="!isDarkMode" class="hidden xl:block" src="@images/static/logo.svg" alt="site logo" />
            </RouterLink>
            <ul class="nav__items">
                <li class="nav__item">
                    <RouterLink :to="{ name: 'feed' }" class="nav__menu" :data-active="$route.name === 'feed'">
                        <component :is="$route.name === 'feed' ? IconHomeVariant : IconHomeVariantOutline" class="nav__icon" />
                        <span class="nav__text">Feed</span>
                    </RouterLink>
                </li>
                <li class="nav__item">
                    <RouterLink to="/channels" class="nav__menu" :data-active="$route.path === '/channels'">
                        <div class="relative">
                            <component :is="$route.path === '/channels' ? IconHashtagBox : IconHashtagBoxOutline" class="nav__icon" />
                            <Badge class="absolute top-0 -right-1" :count="unseenPostsCount" />
                        </div>
                        <span class="nav__text">Channels</span>
                    </RouterLink>
                </li>
                <li class="nav__item">
                    <RouterLink to="/information-hub" class="nav__menu" :data-active="$route.path.startsWith('/information-hub')">
                        <component :is="$route.path.startsWith('/information-hub') ? 'icon-light-bulb' : 'icon-light-bulb-outline'" class="nav__icon" />
                        <span class="nav__text">Information Hub</span>
                    </RouterLink>
                </li>
                <li class="nav__item">
                    <RouterLink to="/marketplace" class="nav__menu" :data-active="$route.path === '/marketplace'">
                        <component :is="$route.path === '/marketplace' ? IconStoreFront : IconStoreFrontOutline" class="nav__icon" />
                        <span class="nav__text">Marketplace</span>
                    </RouterLink>
                </li>
                <li class="nav__item order-last" v-if="$store.state.deviceType !== 'mobile'">
                    <button type="button" @click="$router.push({ name: 'posts.create' })" class="nav__menu" :data-active="$route.name === 'posts.create'">
                        <IconPencilPlusOutline class="nav__icon" />
                        <span class="nav__text">Post</span>
                    </button>
                </li>
                <li class="nav__item">
                    <RouterLink to="/conversations" class="nav__menu" :data-active="$route.path.startsWith('/conversations')">
                        <div class="relative">
                            <component :is="$route.path.startsWith('/conversations') ? IconMessageText : IconMessageTextOutline" class="nav__icon" />
                            <Badge class="absolute -top-2 -right-2" v-if="$store.state.unreadChats" :count="$store.state.unreadChats" />
                        </div>
                        <span class="nav__text">Chats</span>
                        <span class="sr-only">{{ $store.state.user.unread_conversations_count ? 'You have unread chats' : 'You have no unread chats' }}</span>
                    </RouterLink>
                </li>
                <li class="nav__item">
                    <RouterLink to="/notifications" class="nav__menu" :data-active="$route.path === '/notifications'">
                        <div class="relative">
                            <component :is="$route.path === '/notifications' ? IconBell : IconBellOutline" class="nav__icon relative" />
                            <Badge v-if="$store.state.user.unread_notifications_count" class="absolute top-0 right-0" />
                        </div>
                        <span class="nav__text">Notifications</span>
                        <span class="sr-only">{{ $store.state.user.unread_notifications_count ? 'You have unread notifications' : 'You have no unread notifications' }}</span>
                    </RouterLink>
                </li>
                <li v-if="$store.state.user.profile" class="nav__item order-last hidden md:flex">
                    <ProfileLink :profile="$store.state.user.profile" class="nav__menu">
                        <Avatar class="nav__icon" :src="$store.state.user.profile.avatar?.url" :fallback-from="$store.state.user.name" />
                        <span class="nav__text">Profile</span>
                    </ProfileLink>
                </li>
                <li class="grow nav__item hidden md:flex order-last"></li>
                <li class="nav__item hidden md:flex order-last">
                    <DropdownMenu :items="moreItems" content-align="start" content-side="top" :side-offset="1">
                        <DropdownMenuTrigger class="nav__menu">
                            <IconMoreCircleOutline class="nav__icon" />
                            <span class="nav__text">More</span>
                        </DropdownMenuTrigger>
                    </DropdownMenu>
                </li>
            </ul>
        </nav>
    </div>
</template>
