<script setup>
import { inject } from 'vue';

import VideoPostPlayer from '@/views/feed/VideoPostPlayer.vue';

const props = defineProps({
    post: Object,
    active: {
        type: Boolean,
        default: false,
    },
});

const galleryMuted = inject('galleryMuted');
</script>

<template>
    <div class="size-full bg-[#000000] text-[#f2f2f2] relative">
        <VideoPostPlayer
            :src="post.files[0].url"
            :autoplay="false"
            :loop="true"
            :muted="galleryMuted"
            :active="active"
            :post="post"
        />
    </div>
</template>
