<script setup>
import { computed, ref } from "vue";
import axios from "@/axios";
import { useNotificationPermission } from "@/composables/permissions/notification";
import { useToast } from "@/plugins/toast";
import Content from "@/views/_partials/Content.vue";
import Header from "@/views/_partials/header/Header.vue";
import HeaderTitle from "@/views/_partials/header/HeaderTitle.vue";
import { useJoinedChannels } from "@/composables/channel";
import Avatar from "@/components/Avatar.vue";

const joinedChannels = useJoinedChannels();
</script>

<template>
    <Content class="bg-white text-white-foreground">
        <Header with-back-button>
            <HeaderTitle> Channels Settings </HeaderTitle>
        </Header>
        <div class="p-5">
            <p class="font-bold opacity-70 mb-8">
                Joined Channels
                <span class="text-sm text-gray-500">
                    ({{ joinedChannels.data?.length || 0 }})
                </span>
            </p>
            <ul class="flex flex-col gap-4" v-if="joinedChannels.data">
                <li
                    class="shadow-sm rounded-md border border-white-foreground/10"
                    v-for="channel in joinedChannels.data"
                    :key="channel.id"
                >
                    <div class="px-3 py-3 flex flex-row items-center gap-2">
                        <Avatar
                            :rounded-full="false"
                            :src="
                                channel.image?.url || '/channel-placeholder.jpg'
                            "
                        />
                        <div>
                            <p class="font-medium leading-none">
                                {{ channel.name }}
                            </p>
                        </div>
                        <div class="ml-auto relative mt-2">
                            <app-icon-button
                                @click="
                                    $router.push({
                                        name: 'channels.notification-settings',
                                        params: { handle: channel.handle }, // Use channel's handle instead of route param
                                    })
                                "
                                icon="notification-settings"
                                button-style="outlined"
                            />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </Content>
</template>
