<script setup>
import anon from '@images/anonymous-user.jpg';

import { computed, nextTick, onMounted, ref } from 'vue';

import { elPositionFix } from '@/utils/fixed';
import { useAjaxForm } from '@/utils/form';
import { PostType } from '@/enums';
import { useEmitter } from '@/plugins/emitter';
import { useStore } from '@/store';
import { getFriendlyTime, pluralize } from '@/utils';
import { registerFileUploader } from '@/views/files/_helpers';

import Avatar from '@/components/Avatar.vue';
import AppButton from '@/components/Button/Button.vue';
import ChannelIcon from '@/components/Channel/ChannelIcon.vue';
import FilePicker from '@/components/Form/v1/FilePicker.vue';
import FilePickerDisplay from '@/components/Form/v1/FilePickerDisplay.vue';
import Uploader from '@/views/files/Uploader.vue';
import IconCaretRightFill from '@/components/Icons/CaretRightFill.vue';
import IconImages from '@/components/Icons/Images.vue';
import IconInfo from '@/components/Icons/InformationVariantCircle.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import PostTextFields from '@/components/Post/PostTextFields.vue';
import PostHeader from '@/components/Post/PostHeader.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';

registerFileUploader({ queryMode: true });

const emit = defineEmits(['done']);
const props = defineProps({
    post: {
        required: true,
        type: Object,
    }
});
const store = useStore();

const emitter = useEmitter();

const form = useAjaxForm({
    title: props.post.title,
    content: props.post.content,
    files: props.post.files,
});

const bottombar = ref();
const picker = ref();
let bottombarFix = null;

form.onSuccess((res) => {
    try {
    emitter.emit('.post:updated', res.data.data);
    emit('done');
    } catch (err) {
        console.error(err);
    }
});

const updatePost = () => {
    form.patch(`/api/posts/${props.post.id}`);
}

const editRemaningInfo = computed(() => {
    if (!props.post) {
        return null;
    }

    if (!props.post.edits_expires_at) {
        return `Editing will be disabled at ${store.state.app.posts.edit_window_hours} hours from first edit`
    }

    const exp = getFriendlyTime(props.post.edits_expires_at);

    return `Editing will be disabled at ${exp}`;
});

const titleRequired = computed(() => {
    return props.post.type == PostType.QUESTION;
});

const contentRequired = computed(() => {
    if (props.post.type == PostType.QUESTION) {
        return false;
    }

    return true;
});

const maxContentLength = computed(() => {
    if (props.post.type == PostType.POLL) {
        return 800;
    }

    return 4000;
});

const contentPlaceholder = computed(() => {
    if (props.post.type == PostType.QUESTION) {
        return 'Explain your question in detail';
    }

    if (props.post.type == PostType.POLL) {
        return 'Ask your question...';
    }

    return 'Engage with the community';
});

onMounted(() => {
    nextTick(() => {
        bottombarFix = elPositionFix({
            el: bottombar.value,
            style: {
                position: 'absolute',
                zIndex: 50,
                bottom: 0,
            }
        });
    });
});
</script>

<template>
    <Modal without-padding>
        <form @submit.prevent="updatePost" class="min-h-[400px] mx-auto bg-white text-white-foreground">
            <div class="px-4 md:px-6 mt-8">
                <ModalTitle class="text-lg font-semibold" show>
                    Edit {{ post.type == PostType.QUESTION ? 'Question' : 'Post' }} ({{ post.edits_remaining }} {{ pluralize('Edit', post.edits_remaining)}} Remaining)
                </ModalTitle>
                <div class="text-info font-medium">
                    <IconInfo class="inline-block size-[18px]" />
                    <span class="text-sm ml-1">
                        {{ editRemaningInfo }}
                    </span>
                </div>
                <div class="mt-4 flex items-center w-full">
                    <Avatar class="shrink-0" v-if="post.anonymously" :src="anon" />
                    <UserAvatar v-else class="shrink-0" :user="post.user" />

                    <IconCaretRightFill class="size-5 opacity-70 ml-1 shrink-0" />

                    <div class="min-w-0 flex items-center gap-0.5 text-white-foreground/80 ml-1 rounded-full border border-white-foreground/20 py-1 px-2">
                        <ChannelIcon :channel="post.channel" class="size-4" />
                        <div class="text-sm font-semibold leading-none flex items-center mt-[1px] min-w-0 truncate">{{ post.channel.name }}</div>
                    </div>
                </div>

                <div class="mt-4">
                    <PostTextFields
                        :max-content-length="maxContentLength"
                        :form="form"
                        :show-title-field="post.type == PostType.QUESTION"
                        title-placeholder="Lead your question with 'What', 'When', 'How', etc."
                        :title-required="titleRequired"
                        :content-required="contentRequired"
                        :content-placeholder="contentPlaceholder"
                    />
                </div>

                <FilePickerDisplay class="pb-4" :picker="picker" />
            </div>

            <div ref="bottombar">
                <div class="w-full mt-4 flex items-center gap-2 py-3 border-t border-white-foreground/20 px-4 md:px-6">
                    <FilePicker
                        ref="picker"
                        v-model="form.files"
                        aria-label="add images or videos"
                        type="button"
                        multiple
                        class="text-white-foreground/90"
                    >
                        <IconImages class="size-6" />
                    </FilePicker>
                    <div class="grow"></div>
                    <AppButton :loading="form.processing" :disabled="form.processing || form.isClean()" color="primary" compact capitalize>
                        Update
                    </AppButton>
                </div>
            </div>
        </form>
        <Uploader v-if="$route.query.modal === 'file-uploader'" />
    </Modal>
</template>
